import { WorkspaceEventType } from "common/constants";
import { WorkspaceModel } from "models/workspace";
import { TFunction } from "i18next";

import { getRelativeTimeWithPreposition } from "./getRelativeTime";

export const WorkspaceActivityText = {
  [WorkspaceEventType.CreateWorkspace]: "workspace.activities.create",
  [WorkspaceEventType.RenameWorkspace]: "workspace.activities.rename",
  [WorkspaceEventType.UploadFile]: "workspace.activities.uploadFile",
  [WorkspaceEventType.MapData]: "workspace.activities.mapData",
  [WorkspaceEventType.RemapData]: "workspace.activities.remapData",
  [WorkspaceEventType.ValidateData]: "workspace.activities.validateData",
  [WorkspaceEventType.SubmitData]: "workspace.activities.submitData",
  [WorkspaceEventType.GenerateReport]: "workspace.activities.generateReport",
  [WorkspaceEventType.Error]: "workspace.activities.dashboardError",
};

export function getLastWsActivity(ws: WorkspaceModel, t: TFunction) {
  const status = ws.eventType as WorkspaceEventType;

  if (!WorkspaceActivityText[status]) {
    return "N/A";
  }

  const activityText = WorkspaceActivityText[status];
  const time = getRelativeTimeWithPreposition(ws.updatedAt);
  return t(activityText, {
    time,
    username: ws.updatedBy,
    dashboardname: "RevPulse",
  });
}
