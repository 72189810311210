import { ExportStatus, ReportFile } from "models/report";
import Table from "components/Table";
import { Column } from "components/Table/models";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { IconButton, Typography, Stack } from "@mui/material";
import moment from "moment";
import { DownloadOutlined } from "@mui/icons-material";

const creationDateFormat = "MMM DD, YYYY - hh:mmA";

const getColumns = (t: TFunction): Column<ReportFile>[] => [
  {
    header: t("Dashboard.ExcelExport.ExportHistoryTable.Columns.FileName"),
    render: (data) => (
      <Typography color="primary">{data.blobFileName}</Typography>
    ),
  },
  {
    header: t("Dashboard.ExcelExport.ExportHistoryTable.Columns.CreationDate"),
    render: (data) => (
      <Typography>
        {moment.utc(data.createdAt).local().format(creationDateFormat)}
      </Typography>
    ),
  },
  {
    header: t("Dashboard.ExcelExport.ExportHistoryTable.Columns.TimeFrame"),
    render: () => "N/A",
  },
  {
    header: t("Dashboard.ExcelExport.ExportHistoryTable.Columns.DateRange"),
    render: () => "N/A",
  },
  {
    header: "",
    render: (data) => {
      if (data.status === ExportStatus.Completed) {
        return (
          <Stack width="100%" direction="row" justifyContent="flex-end">
            <IconButton
              onClick={() => {
                window.open(data.downloadUrl, "_blank")?.focus();
              }}
              sx={{ marginRight: -2, padding: 1 }}
            >
              <DownloadOutlined />
            </IconButton>
          </Stack>
        );
      }
      return null;
    },
  },
];

interface ExportHistoryTableProps {
  rows: ReportFile[];
}

export default function ExportHistoryTable({ rows }: ExportHistoryTableProps) {
  const { t } = useTranslation();

  return (
    <Table
      rowKey="id"
      data={rows}
      columns={getColumns(t)}
      alternateRowColors={false}
      headerSx={{
        height: "56px",
      }}
    />
  );
}
