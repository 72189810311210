import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import useFetch from "utils/hook/useFetch";
import i18n from "common/i18n";
import AppBar from "components/AppBar";
import PatternCard from "components/Card/PatternCard";
import AdminMenu from "components/Menu/AdminMenu";
import Modal from "components/Modal";
import DataGrid from "components/Table/DataGrid";
import { ApiErrorModel } from "models/apiErrorModel";
import {
  Collection,
  DEFAULT_PAYLOAD,
  FilterCollectionPayload,
} from "models/collection";
import { TeamModel } from "models/team";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { archiveTeam, deleteTeam, getTeams } from "services/superAdminService";
import { getRelativeTime } from "utils/getRelativeTime";
import { useAlert } from "utils/hook/useAlert";
import { useToast } from "utils/hook/useNotification";
import { useSelector } from "common/store";
import { ROUTE_PATHS } from "common/constants";
import PageNotFound from "components/Router/PageNotFound";
import { administrationClient } from "common/client/administrationClient";
import { useDispatch } from "react-redux";
import { setState } from "slices/teamSlice";

import ContextMenu from "./components/ContextMenu";
import ModalContentCreateTeam from "./components/ModalContentCreateTeam";
import ModalContentRenameTeam from "./components/ModalContentRenameTeam";

export default function TeamListPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const { showSuccess, showError } = useToast();
  const { isSystemAdmin } = useSelector((state) => state.team);

  const [dataPayload, setDataPayload] = useState<FilterCollectionPayload>({
    ...DEFAULT_PAYLOAD,
    orderBy: "CreatedAt",
    order: "desc",
  });
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<TeamModel>();

  const { isLoading, data, error, execute } = useFetch<
    FilterCollectionPayload,
    Collection<TeamModel>,
    ApiErrorModel
  >({ fn: getTeams });

  const handleOnDeleteOptionClick = (team: TeamModel) => {
    showAlert({
      title: t("Teams.Modals.DeleteTeam.Title"),
      message: t("Teams.Modals.DeleteTeam.Description"),
      confirmLabel: t("Teams.Modals.DeleteTeam.Action"),
      cancelLabel: "Cancel",
      onConfirm: () => {
        deleteTeam(team.id || "")
          .then(() => {
            showSuccess({
              message: t("Teams.Modals.DeleteTeam.DeleteSuccessMessage", {
                team: team.name || "",
              }),
            });
            execute(dataPayload);
          })
          .catch(() => {
            showError({
              message: t("Teams.Modals.DeleteTeam.DeleteErrorMessage"),
            });
          });
      },
    });
  };

  const handleOnArchiveOptionClick = (team: TeamModel) => {
    showAlert({
      title: t("Teams.Modals.ArchiveTeam.Title"),
      message: t("Teams.Modals.ArchiveTeam.Description"),
      confirmLabel: t("Teams.Modals.ArchiveTeam.Action"),
      cancelLabel: "Cancel",
      onConfirm: () => {
        archiveTeam(team.id || "")
          .then(() => {
            showSuccess({
              message: (
                <>
                  {t("Teams.Modals.ArchiveTeam.ArchiveSuccessMessage", {
                    team: team.name,
                  })}
                  <u>{t("Common.GrowthEdgeCustomerCare")}</u>.
                </>
              ),
            });
            execute(dataPayload);
          })
          .catch(() => {
            showError({
              message: t("Teams.Modals.ArchiveTeam.ArchiveErrorMessage"),
            });
          });
      },
    });
  };

  const handleOnOptionClick = (optionId: string, team: TeamModel) => {
    if (optionId === "archive") handleOnArchiveOptionClick(team);
    if (optionId === "delete") handleOnDeleteOptionClick(team);
    setSelectedRow(team);
    if (optionId === "rename") setIsRenameModalOpen(true);
  };

  const columns: GridColDef[] = [
    {
      field: "OrgName",
      valueGetter: (_, row) => row.name,
      headerName: i18n.t("Teams.DataGrid.ColHeader_Name"),
      minWidth: 200,
      flex: 2,
      renderCell: (params) => (
        <Stack direction="row" alignItems="center">
          <Avatar variant="square" sx={{ marginRight: 3 }} />
          <Link
            to={`/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}`}
            component={RouterLink}
            underline="none"
          >
            {params.value}
          </Link>
        </Stack>
      ),
    },
    {
      field: "CompanyName",
      valueGetter: (_, row) => row.orgName,
      headerName: i18n.t("Teams.DataGrid.ColHeader_OrgName"),
      minWidth: 200,
      flex: 1,
    },
    {
      field: "admins",
      sortable: false,
      valueGetter: (value) => (value as string[]).join(", "),
      headerName: i18n.t("Teams.DataGrid.ColHeader_Admins"),
      minWidth: 150,
      flex: 1,
    },
    {
      field: "UpdatedAt",
      valueGetter: (_, row) => getRelativeTime(row.updatedAt),
      headerName: i18n.t("Teams.DataGrid.ColHeader_UpdatedAt"),
      minWidth: 150,
      flex: 1,
    },
    {
      field: "CreatedAt",
      valueGetter: (_, row) => getRelativeTime(row.createdAt),
      headerName: i18n.t("Teams.DataGrid.ColHeader_CreatedAt"),
      minWidth: 150,
      flex: 1,
    },
    {
      field: "action",
      headerName: "",
      width: 20,
      align: "right",
      sortable: false,
      renderCell: (params) => (
        <ContextMenu
          onOptionSelect={(id) => handleOnOptionClick(id, params.row)}
        />
      ),
    },
  ];

  function handleOnRequestData(payload: FilterCollectionPayload): void {
    execute(payload);
    setDataPayload(payload);
  }

  useEffect(() => {
    execute(dataPayload);
  }, []);
  if (error && error.status === 403) return <PageNotFound />;
  return (
    <>
      <Stack sx={{ minHeight: "100vh", backgroundColor: "var(--bg-color)" }}>
        <AppBar>
          <AdminMenu />
        </AppBar>
        <Box sx={{ padding: 3 }}>
          <PatternCard
            size="large"
            title={t("Teams.Card.Title")}
            actionTitle={isSystemAdmin ? t("Teams.Card.Action") : undefined}
            onActionClick={() => setIsCreateModalOpen(true)}
          >
            <DataGrid
              columns={columns}
              data={data}
              isLoading={isLoading}
              payload={dataPayload}
              hasSearchBox={true}
              hasFilterButton={false}
              rowSelection={false}
              onRequestData={handleOnRequestData}
            />
          </PatternCard>
        </Box>
      </Stack>
      <Modal
        minWidth={620}
        open={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
      >
        <ModalContentCreateTeam
          onCancel={() => setIsCreateModalOpen(false)}
          onCompleted={async () => {
            setIsCreateModalOpen(false);
            execute(dataPayload);
            const response = await administrationClient.get("profile/teams");
            const teams = response.data.data;
            dispatch(setState({ teams }));
          }}
        />
      </Modal>
      <Modal
        minWidth={600}
        open={isRenameModalOpen}
        onClose={() => setIsRenameModalOpen(false)}
      >
        <ModalContentRenameTeam
          team={selectedRow}
          onCancel={() => setIsRenameModalOpen(false)}
          onCompleted={() => {
            setIsRenameModalOpen(false);
            execute(dataPayload);
          }}
        />
      </Modal>
    </>
  );
}
