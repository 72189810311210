import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  FormGroup,
  FormLabel,
  TextField,
  Typography,
} from "@mui/material";
import { ROUTES } from "common/constants";
import LoadingButton from "components/Button/LoadingButton";
import { ApiErrorModel } from "models/apiErrorModel";
import { WorkspaceModel } from "models/workspace";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { createWorkspace } from "services/workspaceService";
import useFetch from "utils/hook/useFetch";
import { useToast } from "utils/hook/useNotification";

import ImportLayout from "../Import/components/ImportLayout";

export default function NewWorkspace() {
  const params = useParams();
  const { showError } = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [name, setName] = useState("");

  const fetching = useFetch<{ name: string }, WorkspaceModel, ApiErrorModel>({
    fn: (payload: { name: string }) => createWorkspace(payload),
  });

  async function handleOnSubmitButtonClick(): Promise<void> {
    fetching.execute({ name });
  }

  useEffect(() => {
    if (fetching.isLoading) return;

    if (fetching.error) {
      showError({ message: t("Workspace.Actions.Create.FailedMessage") });
    } else if (fetching.data) {
      navigate(ROUTES.WORKSPACE_CONFIRM(params.id!, fetching.data.id));
    }
  }, [fetching.isLoading]);

  return (
    <ImportLayout>
      <Container>
        <Card
          sx={{
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          elevation={0}
        >
          <CardHeader
            title={
              <Typography variant="h3">
                {t("CreateWorkspace.Header")}
              </Typography>
            }
          />
          <CardContent>
            <FormGroup>
              <FormLabel htmlFor="workspace-name" sx={{ marginBottom: "24px" }}>
                {t("CreateWorkspace.Body")}
              </FormLabel>
              <TextField
                onChange={(e) => setName(e.target.value)}
                id="workspace-name"
                name="workspaceName"
                required
                variant="outlined"
                label={t("CreateWorkspace.InputLabel")}
                placeholder={t("CreateWorkspace.Placeholder")}
                disabled={fetching.isLoading}
                sx={{ minWidth: "400px" }}
              />
            </FormGroup>
          </CardContent>
          <CardActions>
            <LoadingButton
              variant="contained"
              disabled={!name}
              loading={fetching.isLoading}
              onClick={handleOnSubmitButtonClick}
            >
              {fetching.isLoading
                ? t("Common.Submitting")
                : t("Common.Continue")}
            </LoadingButton>
            <Button
              disabled={fetching.isLoading}
              onClick={() => navigate(ROUTES.WORKSPACES(params.id!))}
            >
              {t("Cancel")}
            </Button>
          </CardActions>
        </Card>
      </Container>
    </ImportLayout>
  );
}
