import { EventType } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { administrationClient } from "common/client/administrationClient";
import { identityClient } from "common/client/identityClient";
import { loginRequest } from "common/config/msalConfig";
import { ROUTE_PATHS } from "common/constants";
import React, { ReactNode, createContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setState } from "slices/teamSlice";

interface AuthUserModel {
  firstName: string;
  lastName: string;
  email: string;
}

interface AuthUserContextType {
  authUser: AuthUserModel | null;
  fetchAuthUser: () => Promise<AuthUserModel>;
  loginBasic: (username: string, password: string) => Promise<void>;
  loginMsal: () => Promise<void>;
  logout: () => void;
}

const oldAccessTokenKey = "userToken";

function getOldAccessToken() {
  const oldAccessToken = localStorage.getItem(oldAccessTokenKey);
  return oldAccessToken;
}

function getMsalAccessToken() {
  const keys = Object.keys(window.localStorage);
  const storageKey = keys.find((x) => x.includes("accesstoken"));
  const storageValue = window.localStorage.getItem(storageKey || "");
  const storageObject = JSON.parse(storageValue || "{}");
  const accessToken = storageObject?.secret;
  return accessToken;
}

export function getAccessToken() {
  const oldAccessToken = getOldAccessToken();
  if (oldAccessToken) return oldAccessToken;

  return getMsalAccessToken();
}

export function getBearerToken() {
  const token = getAccessToken();
  return token ? `Bearer ${token}` : "";
}

const mockAuthUserModel: AuthUserModel = {
  email: "",
  firstName: "",
  lastName: "",
};

const defaultContext: AuthUserContextType = {
  authUser: null,
  fetchAuthUser: () => Promise.resolve(mockAuthUserModel),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loginBasic: (username, password) => Promise.resolve(),
  loginMsal: () => Promise.resolve(),
  logout: () => {},
};

const AuthUserContext = createContext<AuthUserContextType>(defaultContext);

const AuthUserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const dispatch = useDispatch();

  const { instance: msalInstance } = useMsal();

  const [authUser, setAuthUser] = useState<AuthUserModel | null>(null);

  const loginMsal = async () => {
    localStorage.clear();
    try {
      await msalInstance.loginRedirect(loginRequest);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const loginBasic = async (username: string, password: string) => {
    localStorage.clear();
    const { data: response } = await identityClient.post(
      "identityusers/login",
      { userName: username, password }
    );
    localStorage.setItem(oldAccessTokenKey, response.data);
    setAuthUser(mockAuthUserModel);
    window.location.href = `/${ROUTE_PATHS.LOGIN_SUCCESS}`;
  };
  const cleanUpAndLogin = () => {
    localStorage.clear();
    window.location.href = "/login";
  };
  const logout = () => {
    setAuthUser(null);
    cleanUpAndLogin();
  };

  const fetchAuthUser = async () => {
    if (!getAccessToken()) cleanUpAndLogin();
    try {
      const [accessResponse, teamsResponse] = await Promise.all([
        administrationClient.get("profile/teams-access"),
        administrationClient.get("profile/teams"),
      ]);
      dispatch(
        setState({
          isSystemAdmin: accessResponse.data.data.isSuperAdmin,
          isFirstLogin: accessResponse.data.data.isFirstLogin,
          teams: teamsResponse.data.data,
        })
      );
    } catch {
      dispatch(
        setState({
          isSystemAdmin: false,
          isFirstLogin: true,
          teams: [],
        })
      );
    }

    setAuthUser(mockAuthUserModel);

    return mockAuthUserModel;
  };

  useEffect(() => {
    msalInstance.addEventCallback((event) => {
      // Handling callback after login success with MSAL
      if (
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.LOGIN_SUCCESS
      ) {
        fetchAuthUser();
        // setAuthUser(mockAuthUserModel);
        // window.location.href = `/${ROUTE_PATHS.LOGIN_SUCCESS}`;
      }
    });
  }, []);

  useEffect(() => {
    msalInstance.addEventCallback((event) => {
      // Handling callback after login success with MSAL
      if (
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.LOGIN_SUCCESS
      ) {
        setAuthUser(mockAuthUserModel);
        // window.location.href = `/${ROUTE_PATHS.LOGIN_SUCCESS}`;
      }
    });
  }, []);

  const contextValue = {
    authUser,
    fetchAuthUser,
    loginBasic,
    loginMsal,
    logout,
  };

  return (
    <AuthUserContext.Provider value={contextValue}>
      {children}
    </AuthUserContext.Provider>
  );
};

export { AuthUserContext, AuthUserProvider };
