import { Autocomplete, TextField, Chip, SxProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { EMAIL_REGEX } from "common/constants";

interface MultipleEmailInputProps {
  values: string[];
  onValueChanges: (values: string[]) => void;
  hasInvalidEmails: boolean;
  setHasInvalidEmail: (value: boolean) => void;
  helperText: string;
  setHighlightEmails?: (value: string[]) => void;
  highlightEmails?: string[];
  sx?: SxProps;
}

export default function MultipleEmailInput({
  values,
  onValueChanges,
  hasInvalidEmails,
  setHasInvalidEmail,
  helperText,
  setHighlightEmails,
  highlightEmails,
  sx,
}: MultipleEmailInputProps) {
  const { t } = useTranslation();

  const checkInvalidEmail = (option: string) => {
    const isValid = EMAIL_REGEX.test(option);

    if (isValid && highlightEmails?.some((e) => e === option)) {
      return false;
    }
    return isValid;
  };

  const generateHelperText = () => {
    if (hasInvalidEmails) {
      if (highlightEmails && highlightEmails?.length > 0) {
        return t("InviteExistsInTeam");
      }
      return t("InvalidEmailError");
    }
    return helperText;
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      options={[] as string[]}
      value={values}
      autoSelect
      onChange={(_, newValues) => {
        const emailList = newValues.flatMap((email) => {
          if (
            email.includes(",") ||
            email.includes(";") ||
            email.includes(" ")
          ) {
            const emails = email.split(/[ ,;]+/);
            return [...new Set(emails)].filter((e) => !newValues.includes(e));
          }
          return email;
        });
        const trimedEmails = emailList.filter((email) => email.trim() !== "");
        onValueChanges(trimedEmails);
        setHighlightEmails?.([]);
        setHasInvalidEmail(
          trimedEmails.some((email) => !checkInvalidEmail(email))
        );
      }}
      renderTags={(tags: readonly string[], getTagProps) =>
        tags.map((option: string, index: number) => {
          const { key, ...tagProps } = getTagProps({ index });
          const isValid = checkInvalidEmail(option);
          return (
            <Chip
              variant="outlined"
              label={option}
              key={key}
              color={isValid ? undefined : "error"}
              {...tagProps}
            />
          );
        })
      }
      renderInput={(params) => (
        <TextField
          {...params}
          error={hasInvalidEmails}
          variant="outlined"
          label={t("EmailsInputLabel")}
          placeholder={t("EmailInputPlaceholder")}
          helperText={generateHelperText()}
        />
      )}
      sx={sx}
    />
  );
}
