import { SingleValueTooltip } from "components/ChartTooltip";
import AreaChart from "components/Charts/AreaChart";
import VerticalAxisTick from "components/Charts/VerticalAxisTick";
import { ChartDatum } from "components/Charts/model";
import { OverviewChartModel } from "models/report";
import { formatCurrencyValue, getPeriodAxisLabel } from "utils/format";

import {
  WrapperContainer,
  chartMinHeight,
} from "../../components/CommonComponents";

interface Props {
  error?: string;
  isLoading: boolean;
  displayData: OverviewChartModel[];
  hideLegend?: boolean;
  legends: ChartDatum<OverviewChartModel>[];
}

export default function RevenueAreaChart(props: Props) {
  return (
    <WrapperContainer
      error={props.error}
      isLoading={props.isLoading}
      isNoData={props.displayData.length === 0}
    >
      <AreaChart
        data={props.displayData}
        areas={props.legends}
        xAxisKey="xValue"
        width="100%"
        height={chartMinHeight}
        margin={{ top: 0, bottom: 16, left: 16, right: 16 }}
        xAxisProps={{
          interval: 0,
          tick: ({ payload, ...remains }) => (
            <VerticalAxisTick
              value={getPeriodAxisLabel(
                payload.value,
                props.displayData.map((x) => x.xValue)
              )}
              {...remains}
            />
          ),
        }}
        yAxisProps={{
          tickFormatter: (value) => formatCurrencyValue(value),
        }}
        renderTooltip={(payload) =>
          SingleValueTooltip("xValue", payload, (value) =>
            formatCurrencyValue((value as number) || 0, true)
          )
        }
        hideLegend={props.hideLegend}
      />
    </WrapperContainer>
  );
}
