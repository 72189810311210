import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
} from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ByValue, CohortReport, RelativeTo } from "models/report";
import lodashGroupBy from "lodash/groupBy";
import { ReportMeasurement, RevenueType } from "common/constants";
import {
  formatPeriodText,
  formatNumericValue,
  formatCurrencyValue,
  formatPercentageValue,
} from "utils/format";
import lodashOrderBy from "lodash/orderBy";
import { getLastItemOfEachGroup } from "utils/report";

import {
  getTenureTotal,
  getBgColor,
  getTextColor,
  getColumns,
  getTenureKey,
  getTenureHeader,
  getRows,
  getRetentionTotal,
} from "./utils";
import { calendarPeriodMapping, measurementKeyMapping } from "./constants";

interface CohortHeatMapProps {
  data: CohortReport[];
  axis: "cohort-tenure" | "calendar-period";
  revenueType: RevenueType;
  measurement: ReportMeasurement;
  show: "measure" | "count" | "volume";
  by: ByValue;
  relativeTo: RelativeTo;
}

export default function CohortHeatMap({
  data,
  axis,
  revenueType,
  measurement,
  show,
  by,
  relativeTo,
}: CohortHeatMapProps) {
  const { t } = useTranslation();

  const headerKey = useMemo(() => {
    if (axis === "calendar-period") {
      return calendarPeriodMapping[revenueType];
    }
    return getTenureKey(measurement, revenueType);
  }, [axis, measurement, revenueType]);

  const valueKey = useMemo(() => {
    if (by !== "base") {
      return "retentionValue";
    }
    if (show === "count") {
      return "CUSTOMER_COUNT";
    }
    return measurementKeyMapping[measurement];
  }, [show, measurement, by]);

  const dataGroupedByCohortMonth = useMemo(() => {
    const groupKey =
      measurement === ReportMeasurement.ARR ||
      measurement === ReportMeasurement.MRR
        ? "ARRSTARTDATE"
        : "CARRSTARTDATE";
    return lodashGroupBy(data, (item) => item[groupKey as keyof CohortReport]);
  }, [data, measurement]);

  const columns = useMemo(() => {
    return getColumns(dataGroupedByCohortMonth, headerKey);
  }, [data, headerKey]);

  const lastPeriodOfEachGroup = useMemo(() => {
    const sorted = lodashOrderBy(data, ({ Month }) => Month);
    return getLastItemOfEachGroup(
      sorted,
      calendarPeriodMapping[revenueType] as keyof CohortReport
    );
  }, [data, revenueType]);

  const rows = useMemo(() => {
    return getRows(
      dataGroupedByCohortMonth,
      headerKey,
      lastPeriodOfEachGroup,
      columns,
      revenueType,
      by,
      relativeTo
    );
  }, [
    dataGroupedByCohortMonth,
    headerKey,
    lastPeriodOfEachGroup,
    columns,
    revenueType,
    by,
    relativeTo,
  ]);

  const maxValueLength = useMemo(() => {
    return Math.max(
      ...rows
        .flatMap((item) => item.data)
        .map((item) => {
          const value = item[valueKey as keyof CohortReport] as number;
          return show === "count"
            ? formatNumericValue(value).length
            : formatCurrencyValue(value, true).length;
        })
    );
  }, [rows, valueKey, show]);

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                position: "sticky",
                left: 0,
                zIndex: 3,
                textWrap: "nowrap",
                backgroundColor: "var(--white)",
              }}
            >
              {t("CohortHeatMap.PeriodCol")}
            </TableCell>
            {columns.map((col) => (
              <TableCell
                data-testid="CohortHeatMap__HeaderCell"
                key={col}
                sx={{
                  textWrap: "nowrap",
                }}
              >
                {getTenureHeader(col, columns.length)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(({ cohort, data: filteredValues }) => {
            return (
              <TableRow data-testid="CohortHeatMap__DataRow" key={cohort}>
                <TableCell
                  variant="head"
                  sx={{
                    position: "sticky",
                    left: 0,
                    zIndex: 3,
                    textWrap: "nowrap",
                    backgroundColor: "var(--white)",
                  }}
                >
                  {typeof cohort === "string"
                    ? formatPeriodText(cohort)
                    : cohort}
                </TableCell>
                {columns.map((tenure, index) => {
                  const currentTenure = filteredValues.find(
                    (i) => i[headerKey as keyof CohortReport] === tenure
                  );
                  let value = currentTenure?.[
                    valueKey as keyof CohortReport
                  ] as number | string;

                  const shouldDisplayNA =
                    value === null &&
                    !!currentTenure &&
                    Object.hasOwn(currentTenure, valueKey);

                  if (shouldDisplayNA && by !== "base") {
                    value = "Common.NotAvailable";
                  }

                  let previousValue: number | string | undefined;

                  if (index > 0) {
                    previousValue = filteredValues.find(
                      (i) =>
                        i[headerKey as keyof CohortReport] ===
                        columns[index - 1]
                    )?.[valueKey as keyof CohortReport] as number;
                  }

                  let backgroundColor: string | undefined;
                  let color: string | undefined;

                  if (
                    typeof value === "number" &&
                    typeof previousValue === "number"
                  ) {
                    backgroundColor = getBgColor(value, previousValue);
                    color = getTextColor(value, previousValue);
                  }

                  return (
                    <TableCell
                      key={`tenure-${index}`}
                      sx={{
                        backgroundColor,
                        color,
                        minWidth: `${maxValueLength * 10}px`,
                      }}
                    >
                      {by === "base"
                        ? show === "count"
                          ? formatNumericValue(value as number)
                          : formatCurrencyValue(value as number, true)
                        : relativeTo === "prior" &&
                          ((axis === "cohort-tenure" && index === 0) ||
                            (axis === "calendar-period" &&
                              cohort.startsWith(tenure as string)))
                        ? t("Common.NotAvailable")
                        : typeof value === "number"
                        ? formatPercentageValue(value)
                        : t(value)}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell
              variant="head"
              sx={{
                position: "sticky",
                left: 0,
                zIndex: 3,
                textWrap: "nowrap",
                backgroundColor: "var(--white)",
              }}
            >
              {t("CohortHeatMap.TotalLabel")}
            </TableCell>
            {columns.map((col, colIndex) => {
              if (by === "base") {
                const total = getTenureTotal(
                  dataGroupedByCohortMonth,
                  headerKey,
                  col,
                  valueKey
                );
                return (
                  <TableCell key={col} variant="head">
                    {show === "count"
                      ? formatNumericValue(total)
                      : formatCurrencyValue(total, true)}
                  </TableCell>
                );
              }
              const total =
                relativeTo === "prior" &&
                axis === "cohort-tenure" &&
                colIndex === 0
                  ? t("Common.NotAvailable")
                  : getRetentionTotal(rows, headerKey, col, by, relativeTo);

              return (
                <TableCell key={col} variant="head">
                  {typeof total === "string" ? t(total) : total}
                </TableCell>
              );
            })}
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
