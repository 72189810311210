import {
  HttpTransportType,
  HubConnectionBuilder,
  JsonHubProtocol,
  LogLevel,
} from "@microsoft/signalr";
import { getAccessToken } from "common/providers/AuthUserProvider";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export interface SocketPayload<TData> {
  topic: string;
  data: TData;
}

export default function useSocket<TData>(
  resource: string,
  methodName: string,
  callBack: (payload: SocketPayload<TData>) => void
) {
  const params = useParams();
  useEffect(() => {
    const token = getAccessToken();
    const teamId = params.id;
    const connection = new HubConnectionBuilder()
      .withHubProtocol(new JsonHubProtocol())
      .configureLogging(LogLevel.Debug)
      .withUrl(`${resource}?token=${token}&teamId=${teamId}`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .build();

    connection.on(methodName, (data) => callBack(data));
    connection.on("ClientJoin", (data) => console.info("ClientJoin", data));

    connection.start().catch((error) => console.error({ error }));

    return () => {
      connection.stop();
    };
  }, [params.id]);
}

export function useFileSocket<TData>(
  callBack: (payload: SocketPayload<TData>) => void
) {
  useSocket(
    `${process.env.REACT_APP_API_FILE_HUBS}/hubs/file-status`,
    "FileStatusChange",
    callBack
  );
}

export function useReportSocket<TData>(
  callBack: (payload: SocketPayload<TData>) => void
) {
  useSocket(
    `${process.env.REACT_APP_API_REPORT_HUBS}/hubs/export-report`,
    "ExportReport",
    callBack
  );
}
