import { Stack, AppBar, Paper, Toolbar, Box, Typography } from "@mui/material";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import GrowthEdgeLogo from "assets/images/GrowthEdge-primary-white-logo.png";

interface AuthenticationLayoutProps {
  children: ReactNode;
}

export default function AuthenticationLayout({
  children,
}: AuthenticationLayoutProps) {
  const { t } = useTranslation();

  return (
    <Stack direction="column" justifyContent="stretch">
      <AppBar sx={{ boxShadow: "none", height: 64, position: "relative" }}>
        <Paper variant="outlined" sx={{ borderRadius: 0 }}>
          <Toolbar
            sx={{
              paddingX: 3,
              paddingY: 2,
            }}
          >
            <Box
              sx={{
                background: "var(--logo-light)",
                backgroundRepeat: "no-repeat",
                height: "30px",
                width: "192px",
              }}
            />
          </Toolbar>
        </Paper>
      </AppBar>
      <Stack
        direction="row"
        sx={{
          background: "var(--bg-url)",
          height: "calc(100vh - 64px)",
          backgroundSize: "100% 100%",
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ width: "50vw", padding: "58px" }}
        >
          <Typography
            variant="h2"
            sx={(theme) => ({ color: theme.palette.primary.contrastText })}
          >
            {t("Login.WelcomeMsg")}
          </Typography>
          <Box
            component="img"
            sx={{
              height: 64,
              width: 400,
            }}
            src={GrowthEdgeLogo}
          />
          <Typography
            variant="h6"
            textAlign="center"
            sx={(theme) => ({
              color: theme.palette.primary.contrastText,
              whiteSpace: "pre-line",
              marginTop: "20px",
            })}
          >
            {t("Login.Subheader")}
          </Typography>
        </Stack>
        <Stack sx={{ width: "50vw" }}>{children}</Stack>
      </Stack>
    </Stack>
  );
}
