import { ListOutlined, ViewAgendaOutlined } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Stack } from "@mui/material";
import { teamManagementClient } from "common/client/teamManagementClient";
import {
  LAST_ACESS_DEFAULT,
  PAGE_SIZE_OPTIONS,
  ROUTE_PATHS,
} from "common/constants";
import AppBar from "components/AppBar";
import PatternCard from "components/Card/PatternCard";
import TeamMenu from "components/Menu/TeamMenu";
import Modal from "components/Modal";
import { Search, SearchIconWrapper, StyledInputBase } from "components/Search";
import InviteWorkSpaceMemberModal from "containers/InviteWorkSpaceMemberModal";
import { WorkspaceModel } from "models/workspace";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useLoaderData,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { archiveWorkspace, deleteWorkspace } from "services/workspaceService";
import { useAlert } from "utils/hook/useAlert";
import { useToast } from "utils/hook/useNotification";
import { Role } from "models/team";
import { selectRoleByTeam } from "slices/teamSlice";
import { useSelector } from "common/store";
import extractIdFromPathname from "utils/extractIdFromPathname";
import PageNotFound from "components/Router/PageNotFound";
import { ApiErrorModel } from "models/apiErrorModel";
import { capitalize } from "utils/format";

import { CardView } from "./components/CardView";
import { ListView } from "./components/ListView";
import { DataType } from "./types";
import ModalContentRenameWorkspace from "./components/ModalContentRenameWorkspace";

export default function WorkspaceManagePage() {
  const data = useLoaderData() as DataType;
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState<WorkspaceModel>();
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const { showSuccess, showError } = useToast();

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const role = useSelector(selectRoleByTeam);
  const isSystemAdmin = useSelector((state) => state.team.isSystemAdmin);
  const enableCreateWorkspace = isSystemAdmin || role === Role.Admin;

  useEffect(() => {
    searchInputRef.current?.getElementsByTagName("input")[0].focus();
  }, [searchParams.get("search")]);

  const handleOnDeleteOptionClick = (workspace: WorkspaceModel) => {
    showAlert({
      title: t("Workspace.Actions.Delete.Title"),
      message: t("Workspace.Actions.Delete.Description"),
      confirmLabel: t("Workspace.Actions.Delete.Action"),
      cancelLabel: "Cancel",
      onConfirm: () => {
        deleteWorkspace(workspace.id)
          .then(() => {
            showSuccess({
              message: (
                <>
                  <i>{workspace.name}</i>
                  {t("Workspace.Actions.Delete.SuccesMessage")}
                </>
              ),
            });
            navigate(`?${searchParams.toString()}`);
          })
          .catch(() => {
            showError({
              message: t("Workspace.Actions.Delete.FailedMessage"),
            });
          });
      },
    });
  };

  const handleOnArchiveOptionClick = (workspace: WorkspaceModel) => {
    showAlert({
      title: t("Workspace.Actions.Archive.Title"),
      message: t("Workspace.Actions.Archive.Description"),
      confirmLabel: t("Workspace.Actions.Archive.Action"),
      cancelLabel: "Cancel",
      onConfirm: () => {
        archiveWorkspace(workspace.id)
          .then(() => {
            showSuccess({
              message: (
                <>
                  <i>{workspace.name}</i>
                  {t("Workspace.Actions.Archive.SuccesMessage")}
                  <u>{t("Common.GrowthEdgeCustomerCare")}</u>.
                </>
              ),
            });
            navigate(`?${searchParams.toString()}`);
          })
          .catch(() => {
            showError({
              message: t("Workspace.Actions.Archive.FailedMessage"),
            });
          });
      },
    });
  };

  function handleOnOptionSelect(optionId: string, row: WorkspaceModel): void {
    if (optionId === "archive") handleOnArchiveOptionClick(row);
    if (optionId === "delete") handleOnDeleteOptionClick(row);
    setSelectedRow(row);
    if (optionId === "add-member") setIsInviteModalOpen(true);
    if (optionId === "rename") setIsRenameModalOpen(true);
  }

  if (data.error && data.status === 401 && params.id !== LAST_ACESS_DEFAULT)
    return <PageNotFound />;
  return (
    <>
      <Stack
        sx={{
          backgroundColor: "var(--bg-color)",
        }}
      >
        <AppBar>
          <TeamMenu />
        </AppBar>
        <div
          style={{
            padding: "24px 24px 0px 24px",
          }}
        >
          <PatternCard
            title="Team Workspace"
            actionTitle={
              enableCreateWorkspace
                ? t("Workspace.Actions.AddWorkspace")
                : undefined
            }
            size="medium"
            onActionClick={() => {
              navigate(
                `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/untitled/new`
              );
            }}
          />
          <div
            style={{
              padding: "24px",
              backgroundColor: "var(--primary-contrast)",
            }}
          >
            <Stack justifyContent="space-between" direction={"row"}>
              <Stack
                justifyContent={"space-between"}
                maxWidth={"fit-content"}
                direction={"row"}
              ></Stack>
              <Stack
                justifyContent={"space-between"}
                alignItems={"center"}
                maxWidth={"fit-content"}
                direction={"row"}
              >
                <Button
                  onClick={() => {
                    if (searchParams.get("view") !== "list") return;
                    setSearchParams((prev) => {
                      return {
                        ...Object.fromEntries(prev),
                        view: "card",
                      };
                    });
                  }}
                  sx={{
                    color:
                      searchParams.get("view") === "list"
                        ? "action.disabled"
                        : "primary",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <ViewAgendaOutlined sx={{ width: "18px", height: "18px" }} />{" "}
                  {t("WorkspaceManage.Toolbar.CardView")}
                </Button>
                <Button
                  onClick={() => {
                    if (searchParams.get("view") === "list") return;
                    setSearchParams((prev) => {
                      return {
                        ...Object.fromEntries(prev),
                        view: "list",
                      };
                    });
                  }}
                  sx={{
                    color:
                      searchParams.get("view") === "list"
                        ? "primary"
                        : "action.disabled",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  <ListOutlined sx={{ width: "18px", height: "18px" }} />{" "}
                  {t("WorkspaceManage.Toolbar.ListView")}
                </Button>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    e.preventDefault();
                    const formData = new FormData(
                      e.currentTarget as HTMLFormElement
                    );
                    if (
                      formData.get("search") === "" &&
                      !searchParams.has("search")
                    )
                      return;
                    setSearchParams((prev) => {
                      const prevClone = Object.fromEntries(prev);
                      delete prevClone.page;
                      if (formData.get("search") === "" && prevClone.search) {
                        delete prevClone.search;
                        return prevClone;
                      }
                      return {
                        ...prevClone,
                        search: formData.get("search") as string,
                      };
                    });
                  }}
                >
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon
                        sx={{
                          width: "24px",
                          height: "24px",
                        }}
                      />
                    </SearchIconWrapper>
                    <StyledInputBase
                      autoComplete="off"
                      ref={searchInputRef}
                      name="search"
                      placeholder="Search…"
                      defaultValue={searchParams.get("search")}
                      inputProps={{ "aria-label": "search" }}
                    />
                  </Search>
                </form>
              </Stack>
            </Stack>
            <div className="content">
              {searchParams.get("view") === "list" ? (
                <ListView data={data} onOptionSelect={handleOnOptionSelect} />
              ) : (
                <CardView data={data} onOptionSelect={handleOnOptionSelect} />
              )}
            </div>
          </div>
        </div>
      </Stack>
      <InviteWorkSpaceMemberModal
        open={isInviteModalOpen}
        onSubmit={() => {
          navigate(window.location.pathname + window.location.search, {
            replace: true,
          });
        }}
        onClose={() => setIsInviteModalOpen(false)}
        workspaceName={selectedRow?.name || ""}
        workspaceId={selectedRow?.id || ""}
      />
      <Modal
        minWidth={600}
        open={isRenameModalOpen}
        onClose={() => setIsRenameModalOpen(false)}
      >
        <ModalContentRenameWorkspace
          workspace={selectedRow}
          onCancel={() => setIsRenameModalOpen(false)}
          onCompleted={() => {
            setIsRenameModalOpen(false);
            navigate(`?${searchParams.toString()}`);
          }}
        />
      </Modal>
    </>
  );
}

export async function loader({ request }: { request: Request }) {
  const url = new URL(request.url);
  const teamId = extractIdFromPathname(ROUTE_PATHS.TEAMS, url.pathname);
  const queryParams = new URLSearchParams(url.search);
  try {
    const searchData = await teamManagementClient.post(
      `/workspaces/search?teamId=${teamId}`,
      {
        filters: {
          text: queryParams.get("search") || "",
        },
        orderBy: capitalize(queryParams.get("orderBy") || "createdAt"),
        order: queryParams.get("order") || "desc",
        page: Number.parseInt(queryParams.get("page")!) || 0,
        pageSize:
          Number.parseInt(queryParams.get("pageSize")!) || PAGE_SIZE_OPTIONS[0],
      }
    );
    return searchData.data.data;
  } catch (error) {
    return {
      error: true,
      status: (error as ApiErrorModel).status,
      errorMessage: "Something wrong. Please try again later.",
      data: [],
      pagination: {
        page: 0,
        pageSize: PAGE_SIZE_OPTIONS[0],
        total: 0,
      },
    };
  }
}
