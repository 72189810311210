import {
  DatasetOutlined,
  HomeOutlined,
  ListOutlined,
  MiscellaneousServicesOutlined,
  PeopleOutlineOutlined,
  QuestionMarkRounded,
  RefreshOutlined,
} from "@mui/icons-material";

export const DRAWER_WIDTH = 256;

export const DATE_PICKER_FORMAT = "YYYY-MM-DD";

export const NAVBAR_ITEMS = [
  {
    type: "item",
    key: "Dashboard_Overview",
    disabled: true,
    icon: <HomeOutlined />,
    children: undefined,
  },
  {
    type: "item",
    key: "Dashboard_Revenue",
    disabled: false,
    icon: <RefreshOutlined />,
    children: [
      {
        refKey: "SectionRollForward",
        title: "Dashboard.NavBar.SectionRollForward",
      },
      {
        refKey: "SectionGrowthRate",
        title: "Dashboard.NavBar.SectionGrowthRate",
      },
    ],
  },
  {
    type: "item",
    key: "Dashboard_Products",
    disabled: true,
    icon: <DatasetOutlined />,
    children: undefined,
  },
  {
    type: "item",
    key: "Dashboard_Customers",
    disabled: false,
    icon: <PeopleOutlineOutlined />,
    children: [
      {
        refKey: "SectionCountChanges",
        title: "Dashboard.NavBar.SectionCountChanges",
      },
      {
        refKey: "SectionTopCustomers",
        title: "Dashboard.NavBar.SectionTopCustomers",
      },
      {
        refKey: "SectionCohorts",
        title: "Dashboard.NavBar.SectionCohorts",
      },
    ],
  },
  {
    type: "item",
    key: "Dashboard_Additional",
    disabled: false,
    icon: <MiscellaneousServicesOutlined />,
    children: [
      {
        refKey: "SectionContractRenewal",
        title: "Dashboard.NavBar.SectionContractRenewal",
      },
    ],
  },
  { type: "divider" },
  {
    type: "item",
    key: "Dashboard_Glossary",
    disabled: true,
    icon: <ListOutlined />,
    children: undefined,
  },
  {
    type: "item",
    key: "Dashboard_FAQ",
    disabled: true,
    icon: <QuestionMarkRounded />,
    children: undefined,
  },
];
