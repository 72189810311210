import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid2";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import {
  Chart,
  CustomerLevel,
  ReportMeasurement,
  ReportView,
} from "common/constants";
import { useChartSettings, useReportSelector } from "common/store";
import GradientCard from "components/Card/GradientCard";
import { ChartDatum } from "components/Charts/model";
import { OverviewChartModel } from "models/report";
import React, { useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getRevenueByPeriodChart } from "services/reportService";
import { setChartSettings, setChartShouldFetch } from "slices/reportSlice";
import {
  computeGrossRetentionChartData,
  computeGrowthRateChartData,
  computeNetRetentionChartData,
  computeRevenueChartData,
  filterDateRange,
  getNextMonth,
  getPreviousMonth,
} from "utils/chartUtils/overviewCharts";
import useDidUpdateEffect from "utils/hook/useDidUpdateEffect";
import {
  generateBarLegends,
  getSourceColumnName,
  hasCustSegMapped,
  hasProdSegMapped,
} from "utils/report";
import lodashGroupBy from "lodash/groupBy";

import PercentLineChart from "../charts/Overview/PercentLineChart";
import RevenueAreaChart from "../charts/Overview/RevenueAreaChart";

function ChartWrapper({
  isCustomerProductLevel,
  children,
}: {
  isCustomerProductLevel?: boolean;
  children: React.JSX.Element;
}) {
  const { t } = useTranslation();

  return isCustomerProductLevel ? <>{t("Common.ComingSoon")}</> : children;
}

export default function SectionOverview() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { reportData, reportSettings } = useReportSelector();
  const chartSettings = useChartSettings("overview");
  const chartData = useReportSelector().chartData.overview;

  const isCustomerProductLevel = useMemo(() => {
    return (
      reportSettings.params.customerLevel === CustomerLevel.CustomerProduct
    );
  }, [reportSettings.params.customerLevel]);

  const isMappedCustSeg = useMemo(
    () => hasCustSegMapped(reportData.mapping),
    [reportData.mapping]
  );

  const isMappedProdSeg = useMemo(
    () => hasProdSegMapped(reportData.mapping),
    [reportData.mapping]
  );

  const segmentSelects = useMemo(() => {
    if (!reportData.mapping) return [];

    let segmentFields: string[] = [];
    if (chartSettings.segView === ReportView.CustomerType) {
      segmentFields = Object.keys(reportData.mapping).filter((x) =>
        x.startsWith("customerseg")
      );
    }
    if (chartSettings.segView === ReportView.ProductType) {
      segmentFields = Object.keys(reportData.mapping).filter((x) =>
        x.startsWith("product")
      );
    }
    const result = segmentFields
      .map((x) => ({
        key: x,
        value: reportData.mapping![x],
      }))
      .filter((x) => x.value);

    return result;
  }, [chartSettings.segView, reportData.mapping]);

  const selectedSegment = useMemo(() => {
    return getSourceColumnName(reportData.mapping, chartSettings.segKey || "");
  }, [reportData.mapping, chartSettings.segKey]);

  const legends: ChartDatum<OverviewChartModel>[] = useMemo(() => {
    let segViewName: "customer" | "product" | null = null;
    if (chartSettings.segView === ReportView.CustomerType)
      segViewName = "customer";
    if (chartSettings.segView === ReportView.ProductType)
      segViewName = "product";
    return generateBarLegends(
      segViewName,
      chartSettings.segKey,
      reportData,
      reportSettings.filters
    );
  }, [
    chartSettings.segView,
    chartSettings.segKey,
    reportData,
    reportSettings.filters,
  ]);

  const displayData = useMemo(() => {
    if (
      chartData.data === undefined ||
      !reportSettings.filters.minDate ||
      !reportSettings.filters.maxDate
    )
      return {
        [Chart.OverviewRevenue]: [],
        [Chart.OverviewGrowthRate]: [],
        [Chart.OverviewGrossRet]: [],
        [Chart.OverviewNetRet]: [],
      };

    const filteredByDateRange = filterDateRange(chartData.data, reportSettings);
    const months = lodashGroupBy(filteredByDateRange, (item) => item.Month);

    if (Object.keys(months).length === 1) {
      const month = Object.keys(months)[0];
      const previousMonth = getPreviousMonth(month);
      const nextMonth = getNextMonth(month);

      if (!months[previousMonth]) {
        months[previousMonth] = [];
      }
      if (!months[nextMonth]) {
        months[nextMonth] = [];
      }

      for (const segment of Object.values(months[month])) {
        months[previousMonth].push({
          Month: previousMonth,
          SelectedSegmentation: segment.SelectedSegmentation,
          ArrEndingBalance: 0,
          ArrGrossRetention: 0,
          ArrNetRetention: 0,
          ArrGrowthRate: 0,
          CArrEndingBalance: 0,
          CArrGrossRetention: 0,
          CArrNetRetention: 0,
          CArrGrowthRate: 0,
          DISPLAYQTR: "", // TODO: How to calculate prev fiscal quarter?
          FISCALYR: "", // TODO: How to calculate prev fiscal year?
        });
        months[nextMonth].push({
          Month: nextMonth,
          SelectedSegmentation: segment.SelectedSegmentation,
          ArrEndingBalance: 0,
          ArrGrossRetention: 0,
          ArrNetRetention: 0,
          ArrGrowthRate: 0,
          CArrEndingBalance: 0,
          CArrGrossRetention: 0,
          CArrNetRetention: 0,
          CArrGrowthRate: 0,
          DISPLAYQTR: "", // TODO: How to calculate next fiscal quarter?
          FISCALYR: "", // TODO: How to calculate next fiscal year?
        });
      }
    }

    return {
      [Chart.OverviewRevenue]: computeRevenueChartData(
        months,
        reportSettings,
        chartSettings.segView
      ),
      [Chart.OverviewGrowthRate]: computeGrowthRateChartData(
        months,
        reportSettings,
        chartSettings.segView
      ),
      [Chart.OverviewGrossRet]: computeGrossRetentionChartData(
        months,
        reportSettings,
        chartSettings.segView
      ),
      [Chart.OverviewNetRet]: computeNetRetentionChartData(
        months,
        reportSettings,
        chartSettings.segView
      ),
    };
  }, [
    chartData.isLoading,
    reportSettings.measurement,
    reportSettings.filters.minDate,
    reportSettings.filters.maxDate,
  ]);

  function handleOnViewToggleChanged(value: ReportView | null): void {
    if (value !== chartSettings.segView) {
      dispatch(setChartSettings({ overview: { segView: value } }));
    }
  }

  function handleOnSegmentKeyChanged(value: string | null): void {
    if (value !== chartSettings.segKey) {
      dispatch(setChartSettings({ overview: { segKey: value } }));
    }
  }

  useDidUpdateEffect(() => {
    if (reportData.isLoading) return;

    if (reportData.mapping && chartSettings.segView === undefined) {
      let newSegView = null;
      if (isMappedProdSeg) newSegView = ReportView.ProductType;
      if (isMappedCustSeg) newSegView = ReportView.CustomerType;
      handleOnViewToggleChanged(newSegView);
    }
  }, [reportData.isLoading]);

  useDidUpdateEffect(() => {
    if (chartSettings.segView === undefined) return;

    const value = segmentSelects.find((x) => x.key === chartSettings.segKey);
    if (value?.key) {
      handleOnSegmentKeyChanged(value.key);
      return;
    }

    handleOnSegmentKeyChanged(
      segmentSelects.length > 0 ? segmentSelects[0].key : null
    );
  }, [segmentSelects]);

  useEffect(() => {
    if (
      !reportData.isLoading &&
      chartData.shouldFetch &&
      chartSettings.segKey !== undefined
    ) {
      dispatch(
        getRevenueByPeriodChart({
          view: chartSettings.segView,
          segmentKey: chartSettings.segKey,
        })
      );
    }
  }, [reportData.isLoading, chartData.shouldFetch, chartSettings.segKey]);

  useDidUpdateEffect(() => {
    dispatch(setChartShouldFetch(["overview"]));
  }, [
    chartSettings.segKey,
    reportSettings.filters.customers,
    reportSettings.filters.segmentCustomers,
    reportSettings.filters.segmentProducts,
  ]);

  return (
    <>
      <Stack
        direction="row"
        alignItems="flex-start"
        spacing={2}
        marginBottom={2}
      >
        <Typography variant="h6" marginBottom={2} color="var(--text-secondary)">
          {t("Dashboard.Charts.SectionGrowthRate")}
        </Typography>
        <ToggleButtonGroup
          exclusive
          size="small"
          value={chartSettings.segView}
          onChange={(_, v) => handleOnViewToggleChanged(v)}
        >
          <ToggleButton
            size="small"
            value={ReportView.CustomerType}
            disabled={!hasCustSegMapped(reportData.mapping)}
          >
            {t("Common.CustSeg")}
          </ToggleButton>
          <ToggleButton
            size="small"
            value={ReportView.ProductType}
            disabled={!hasProdSegMapped(reportData.mapping)}
          >
            {t("Common.ProdSeg")}
          </ToggleButton>
          <ToggleButton size="small" value={ReportView.Extras} disabled>
            {t("Common.Extras")}
          </ToggleButton>
        </ToggleButtonGroup>
        {(isMappedCustSeg || isMappedProdSeg) && (
          <FormControl
            size="small"
            sx={{ width: "220px" }}
            disabled={isCustomerProductLevel}
          >
            <InputLabel>
              {chartSettings.segView === ReportView.CustomerType
                ? t("Common.CustSeg")
                : t("Common.ProdSeg")}
            </InputLabel>
            <Select
              MenuProps={{ disableScrollLock: true }}
              label={
                chartSettings.segView === ReportView.CustomerType
                  ? t("Common.CustSeg")
                  : t("Common.ProdSeg")
              }
              value={chartSettings.segKey ?? ""}
              onChange={(e) => handleOnSegmentKeyChanged(e.target.value)}
            >
              {segmentSelects.map((x) => (
                <MenuItem key={x.key} value={x.key}>
                  {x.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Stack>
      <Grid container spacing={3} alignItems="stretch">
        <Grid size={6}>
          <GradientCard
            title={t("Dashboard.Charts.RevenueByTitle", {
              view: ReportMeasurement[reportSettings.measurement],
              type: selectedSegment,
            })}
          >
            <ChartWrapper>
              <RevenueAreaChart
                isLoading={reportData.isLoading || chartData.isLoading}
                error={reportData.error || chartData.error}
                legends={legends}
                displayData={displayData[Chart.OverviewRevenue]}
              />
            </ChartWrapper>
          </GradientCard>
        </Grid>
        <Grid size={6}>
          <GradientCard
            title={t("Dashboard.Charts.GrowthRateTitle")}
            onZoomClick={(x) => console.log(x)}
            onMoreClick={() => console.log("more")}
          >
            <ChartWrapper>
              <PercentLineChart
                isLoading={reportData.isLoading || chartData.isLoading}
                error={reportData.error || chartData.error}
                legends={legends}
                displayData={displayData[Chart.OverviewGrowthRate]}
              />
            </ChartWrapper>
          </GradientCard>
        </Grid>
        <Grid size={6}>
          <GradientCard
            title={t("Dashboard.Charts.GrossRetentionTitle")}
            onMoreClick={() => console.log("more")}
          >
            <ChartWrapper isCustomerProductLevel={isCustomerProductLevel}>
              <PercentLineChart
                isLoading={reportData.isLoading || chartData.isLoading}
                error={reportData.error || chartData.error}
                legends={legends}
                displayData={displayData[Chart.OverviewGrossRet]}
              />
            </ChartWrapper>
          </GradientCard>
        </Grid>
        <Grid size={6}>
          <GradientCard
            title={t("Dashboard.Charts.NetRetentionTitle")}
            onMoreClick={() => console.log("more")}
          >
            <ChartWrapper isCustomerProductLevel={isCustomerProductLevel}>
              <PercentLineChart
                isLoading={reportData.isLoading || chartData.isLoading}
                error={reportData.error || chartData.error}
                legends={legends}
                displayData={displayData[Chart.OverviewNetRet]}
              />
            </ChartWrapper>
          </GradientCard>
        </Grid>
      </Grid>
    </>
  );
}
