import { FilterAltOutlined } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
  CustomerLevel,
  ROUTES,
  ROUTE_PATHS,
  ReportMeasurement,
} from "common/constants";
import { useDispatch, useReportSelector } from "common/store";
import { WorkspaceModel } from "models/workspace";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { setReportParams, setReportSettings } from "slices/reportSlice";

import ContextMenu from "./ContextMenu";
import DashboardFilter from "./DashboardFilter";
import DashboardToolbarChips from "./DashboardToolbarChips";

export default function DashboardToolbar({
  workspace,
}: {
  workspace?: WorkspaceModel;
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();

  const { reportData, reportSettings } = useReportSelector();

  const [openDrawer, setOpenDrawer] = useState(false);

  function handleOnOptionSelect(optionId: string): void {
    if (optionId === "parameters")
      navigate(
        ROUTES.REPORT_PARAMETERS(params.id!, params.wsId!, params.dashboardId!),
        { state: { from: window.location.pathname, page: t("Reports.RRI") } }
      );
    if (optionId === "manage") {
      const url = `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/${params.wsId}/${params.dashboardId}/${ROUTE_PATHS.MANAGE_SOURCE_DATA}/${ROUTE_PATHS.MANAGE_SOURCE_DATA_FILE}`;
      navigate(url, {
        state: { from: window.location.pathname, page: t("Reports.RRI") },
      });
    }
  }

  function handleOnCustomerLevelChanged(_: unknown, value: string): void {
    dispatch(
      setReportParams({
        customerLevel: Number.parseInt(value) as CustomerLevel,
      })
    );
  }

  function handleOnMeasurementChanged(_: unknown, value: string): void {
    dispatch(
      setReportSettings({
        measurement: Number.parseInt(value) as ReportMeasurement,
      })
    );
  }

  return (
    <>
      <Stack
        width="100%"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <Typography variant="h5" color="var(--text-secondary)">
          {t("Dashboard.ToolBar.Title", {
            name: workspace?.name || "<Workspace Name>",
          })}
        </Typography>
        <Box display="flex">
          <Button
            size="medium"
            color="secondary"
            variant="outlined"
            disabled={reportData.isLoading}
            startIcon={<FilterAltOutlined />}
            onClick={() => setOpenDrawer(true)}
            sx={{ borderRadius: 120, marginRight: 2 }}
          >
            {t("Dashboard.ToolBar.Filters")}
            {reportData.isLoading && (
              <CircularProgress sx={{ marginLeft: 1 }} size={16} />
            )}
          </Button>
          <ContextMenu
            onOptionSelect={handleOnOptionSelect}
            wsRole={workspace?.role}
          />
        </Box>
      </Stack>
      <Stack
        width="100%"
        direction="row"
        justifyContent="space-between"
        alignItems="start"
      >
        <DashboardToolbarChips />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          minWidth={650}
        >
          <Typography variant="body2" marginRight={1}>
            {t("Dashboard.ToolBar.ViewBy")}
          </Typography>
          <RadioGroup
            row
            value={reportSettings.measurement}
            onChange={handleOnMeasurementChanged}
          >
            <FormControlLabel
              value={ReportMeasurement.ARR}
              control={<Radio size="small" />}
              slotProps={{ typography: { variant: "body2" } }}
              label={t("Dashboard.ToolBar.ARR")}
            />
            <FormControlLabel
              value={ReportMeasurement.MRR}
              control={<Radio size="small" />}
              slotProps={{ typography: { variant: "body2" } }}
              label={t("Dashboard.ToolBar.MRR")}
            />
            <FormControlLabel
              value={ReportMeasurement.CARR}
              control={<Radio size="small" />}
              slotProps={{ typography: { variant: "body2" } }}
              label={t("Dashboard.ToolBar.CARR")}
            />
            <FormControlLabel
              value={ReportMeasurement.CMRR}
              control={<Radio size="small" />}
              slotProps={{ typography: { variant: "body2" } }}
              label={t("Dashboard.ToolBar.CMRR")}
            />
          </RadioGroup>
          <Divider orientation="vertical" flexItem sx={{ marginRight: 2 }} />
          <Typography variant="body2" marginRight={1}>
            {t("Dashboard.ToolBar.CustomerLevel")}
          </Typography>
          <RadioGroup
            row
            value={reportSettings.params.customerLevel}
            onChange={handleOnCustomerLevelChanged}
          >
            <FormControlLabel
              value={CustomerLevel.Customer}
              control={<Radio size="small" />}
              slotProps={{ typography: { variant: "body2" } }}
              label={t("Common.Customer")}
            />
            <FormControlLabel
              value={CustomerLevel.CustomerProduct}
              control={<Radio size="small" />}
              slotProps={{ typography: { variant: "body2" } }}
              label={t("Common.CustomerProduct")}
            />
          </RadioGroup>
        </Stack>
      </Stack>
      <Divider sx={{ marginTop: 1, marginBottom: 2 }} />
      <Drawer
        anchor="right"
        variant="temporary"
        ModalProps={{ keepMounted: true }}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
      >
        <DashboardFilter onClose={() => setOpenDrawer(false)} />
      </Drawer>
    </>
  );
}
