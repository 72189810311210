import React, { MouseEventHandler, useMemo, useState } from "react";
import {
  ResponsiveContainer,
  ComposedChart,
  XAxis,
  YAxis,
  Line,
  Bar,
  Tooltip,
  CartesianGrid,
  Legend,
  DotProps,
  Cell,
} from "recharts";
import type { CategoricalChartProps } from "recharts/types/chart/generateCategoricalChart";
import ChartTooltip from "components/ChartTooltip";
import { axisTopGap } from "common/constants";

import useChartAreaHover from "../useChartAreaHover";
import { ChartDatum, ChartCommonProps } from "../model";
import { calculateMaxChartValue, formatLegendLabel } from "../chartUtils";

function ReferenceLine({
  cx,
  cy,
  width,
  columnCount,
  onMouseOver,
  onMouseOut,
}: DotProps & {
  columnCount: number;
  onMouseOver: MouseEventHandler;
  onMouseOut: MouseEventHandler;
}) {
  // Cartesian grid's size from design: 1139x491
  // width is Cartesian grid's actual width
  // Calculate the line's width based on the number of columns
  const barWidth = ((width as number) || 1139) / columnCount;
  const actualWidth = barWidth - 196 / columnCount;

  return (
    <svg
      x={cx && cx - actualWidth / 2 - 1 / columnCount} // Try to make the line center vertically with the bar
      y={cy && cy - 3}
      xmlns="http://www.w3.org/2000/svg"
      width={actualWidth}
      fill="none"
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <line
        x1={0}
        x2="100%"
        y1={1}
        y2={1}
        stroke="var(--white)"
        strokeWidth={2}
      />
      <line
        x1={0}
        x2="100%"
        y1={3}
        y2={3}
        stroke="var(--neutral-08)"
        strokeWidth={2}
      />
      <line
        x1={0}
        x2="100%"
        y1={5}
        y2={5}
        stroke="var(--white)"
        strokeWidth={2}
      />
    </svg>
  );
}

interface LineBarComposedChartProps<DataModel>
  extends ChartCommonProps<DataModel> {
  line?: ChartDatum<DataModel>;
  bars: ChartDatum<DataModel>[];
  chartProps?: CategoricalChartProps;
  xAxisGroupKey?: keyof DataModel;
  selectedCategory?: string;
  selectedXValue?: string;
  onSelectCategory?: (value?: string) => void;
  onSelectXValue?: (value?: string) => void;
}

export default function LineBarComposedChart<DataModel>({
  data,
  line,
  bars,
  xAxisKey,
  width,
  height,
  chartProps = {},
  xAxisProps,
  yAxisProps,
  xAxisGroupKey,
  margin,
  selectedCategory,
  selectedXValue,
  onSelectCategory,
  onSelectXValue,
  renderTooltip,
}: LineBarComposedChartProps<DataModel>) {
  const { hoveringArea, handleMouseOut, handleMouseOver } =
    useChartAreaHover<DataModel>();
  const [hoveringXValue, setHoveringXValue] = useState<string>();

  const barGap = useMemo(() => {
    // Calculate gap between bars relatively to the number of bars
    return 132 / data.length;
  }, [data.length]);

  const GetChartDomain = () => {
    const maxValueList = data.map((x) => calculateMaxChartValue(x, bars));
    let maxDomainValue = Math.max(...maxValueList);

    // count digit of max value
    let count = 0;
    for (let n = maxDomainValue; n > 0; n = Math.floor(n / 10)) {
      count++;
    }

    // if max value have only 1 digit so maximum value is 10
    if (count === 1) {
      return 10;
    }

    maxDomainValue = maxDomainValue * axisTopGap;

    // calculate bottom/top gap for max value on y-axis
    return (
      Math.ceil(maxDomainValue / Math.pow(10, count - 1)) *
      Math.pow(10, count - 1)
    );
  };

  const chartDomainMaxValue = GetChartDomain();

  const GetTickArray = (maxValue: number) => {
    if (maxValue === 0) return [0];

    return [
      -chartDomainMaxValue,
      -Math.trunc(chartDomainMaxValue / 2),
      0,
      Math.trunc(chartDomainMaxValue / 2),
      chartDomainMaxValue,
    ];
  };

  const tickArray = GetTickArray(chartDomainMaxValue);
  return (
    <ResponsiveContainer width={width} height={height}>
      <ComposedChart
        data={data}
        stackOffset="sign"
        barCategoryGap={barGap}
        margin={margin}
        {...chartProps}
      >
        <CartesianGrid strokeDasharray="1 3" />
        <XAxis
          dataKey={xAxisKey as string}
          xAxisId={xAxisKey as string}
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: 11 }}
          {...(xAxisProps as Object)}
        />
        {xAxisGroupKey && (
          <XAxis
            dataKey={xAxisGroupKey as string}
            xAxisId={xAxisGroupKey as string}
            allowDuplicatedCategory={false}
            axisLine={false}
            tick={{ fontSize: 11 }}
          />
        )}
        <YAxis
          domain={[-chartDomainMaxValue, chartDomainMaxValue]}
          ticks={tickArray}
          yAxisId="yAxis"
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: 11 }}
          {...(yAxisProps as Object)}
        />
        <YAxis
          domain={[-chartDomainMaxValue, chartDomainMaxValue]}
          ticks={tickArray}
          yAxisId="right"
          orientation="right"
          axisLine={false}
          tickLine={false}
          tick={{ fontSize: 11 }}
          {...(yAxisProps as Object)}
        />
        <Tooltip
          wrapperStyle={{ zIndex: 10 }}
          isAnimationActive={false}
          cursor={false}
          content={
            renderTooltip ? (
              <ChartTooltip
                hoveringDataKey={hoveringArea as string}
                onHoveringChange={(payload) =>
                  setHoveringXValue(
                    payload?.[0]?.payload[xAxisKey] || undefined
                  )
                }
              >
                {(payload) => {
                  return renderTooltip(payload);
                }}
              </ChartTooltip>
            ) : undefined
          }
        />
        <Legend
          verticalAlign="top"
          align="left"
          iconType="circle"
          iconSize={12}
          wrapperStyle={{
            top: 0,
            left: 20,
            fontSize: "12px",
            paddingBottom: 14,
            paddingLeft: margin?.left,
          }}
          formatter={(value) => {
            if (value === line?.key) {
              return formatLegendLabel(`(line) ${value}`);
            }
            return formatLegendLabel(value);
          }}
        />
        {bars.map((bar) => (
          <Bar
            data-testid="LineBarComposedChart-bar"
            key={bar.key as string}
            dataKey={bar.key as string}
            stackId="stack"
            xAxisId={xAxisKey as string}
            yAxisId="yAxis"
            fill={bar.color}
            onMouseOver={() => handleMouseOver(bar.key)}
            onMouseOut={handleMouseOut}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${bar.key as string}-${index}`}
                opacity={
                  selectedCategory &&
                  (bar.key !== selectedCategory ||
                    (entry[xAxisKey] as unknown as string) !== selectedXValue)
                    ? 0.3
                    : undefined
                }
                onClick={() => {
                  if (!bar.clickable) {
                    return;
                  }
                  const clickedSameBar =
                    bar.key === selectedCategory &&
                    hoveringXValue === selectedXValue;

                  onSelectCategory &&
                    onSelectCategory(
                      clickedSameBar ? undefined : (bar.key as string)
                    );
                  onSelectXValue &&
                    onSelectXValue(clickedSameBar ? undefined : hoveringXValue);
                }}
                cursor={bar.clickable ? "pointer" : undefined}
                filter={
                  bar.key === selectedCategory &&
                  (entry[xAxisKey] as unknown as string) === selectedXValue
                    ? "drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.12)) drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.14)) drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.20))"
                    : undefined
                }
              />
            ))}
          </Bar>
        ))}
        {line && (
          <Line
            dataKey={line.key as string}
            name={line.key as string}
            stroke="var(--black)"
            strokeWidth={0}
            dot={
              <ReferenceLine
                columnCount={data.length}
                onMouseOver={() => handleMouseOver(line.key)}
                onMouseOut={handleMouseOut}
              />
            }
            xAxisId={xAxisKey as string}
            yAxisId="yAxis"
            activeDot={false}
          />
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
}
