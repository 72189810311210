import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
} from "@mui/material";
import LoadingButton from "components/Button/LoadingButton";
import Modal from "components/Modal";
import DataGrid from "components/Table/DataGrid";
import {
  Collection,
  DEFAULT_PAYLOAD,
  GetCollectionPayload,
} from "models/collection";
import { Role } from "models/team";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  SearchMemberWorkspaceModel,
  SearchMemberWorkspacePayload,
  SearchTeamMemberModel,
  getMemberWorkspacesAsync,
  inviteWorkspaceMembersAsync,
  removeWorkspaceMemberAsync,
  updateWorkspaceRolesAsync,
} from "services/teamService";
import { formatUserDisplayName } from "utils/format";
import useFetch from "utils/hook/useFetch";
import { useToast } from "utils/hook/useNotification";

import { getColumns, EditingRoles } from "./WorkspacesAccessModal.utils";

interface WorkspacesAccessModalProps {
  user: SearchTeamMemberModel | undefined;
  onClose: () => void;
}

const initialPayload: GetCollectionPayload = {
  ...DEFAULT_PAYLOAD,
  orderBy: "CreatedAt",
  order: "desc",
};

export default function WorkspacesAccessModal({
  user,
  onClose,
}: WorkspacesAccessModalProps) {
  const params = useParams();
  const { t } = useTranslation();
  const { showSuccess, showError } = useToast();

  const [isAssignedTab, setIsAssignedTab] = useState<boolean>(true); // true means viewing assigned tab

  const [editingRoles, setEditingRoles] = useState<EditingRoles>({});

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [pagination] = useState<GetCollectionPayload>(initialPayload);

  const titleKey = useMemo(() => {
    return isAssignedTab
      ? t("TeamSettings.TeamUsers.WorkspaceModel.TitleAssigned")
      : t("TeamSettings.TeamUsers.WorkspaceModel.TitleUnassigned");
  }, [isAssignedTab]);

  const {
    data,
    isLoading: isGettingWorkspaces,
    execute: getWorkspaces,
  } = useFetch<
    SearchMemberWorkspacePayload,
    Collection<SearchMemberWorkspaceModel>
  >({
    fn: async (payload) => getMemberWorkspacesAsync(payload),
  });

  function handleOnClose() {
    onClose();
    setEditingRoles({});
    setIsAssignedTab(true);
  }

  const handleRequestData = useCallback(
    (payload: GetCollectionPayload) => {
      getWorkspaces({
        teamId: params.id!,
        userId: user!.id,
        query: { ...payload, isAssigned: isAssignedTab },
      });
    },
    [params.id, user, isAssignedTab, pagination]
  );

  function handleRoleChange(row: SearchMemberWorkspaceModel, value: Role) {
    setEditingRoles({ ...editingRoles, [row.workspaceId]: value });
    if (!isAssignedTab) return;
    setIsLoading(true);

    const msgObj = {
      user: formatUserDisplayName(user!),
      workspace: row.workspaceName,
    };
    const successMsg =
      value === Role.ReportViewer
        ? "TeamSettings.TeamUsers.WorkspaceModel.EditToViewer"
        : "TeamSettings.TeamUsers.WorkspaceModel.EditToEditor";
    const errorMsg = "TeamSettings.TeamUsers.WorkspaceModel.EditRoleFailed";
    updateWorkspaceRolesAsync(params.id!, user!.id, [
      { workspaceId: row.workspaceId, workspaceRole: Role[value] },
    ])
      .then(() => showSuccess({ message: t(successMsg, msgObj) }))
      .then(() => handleRequestData(pagination))
      .catch(() => showError({ message: t(errorMsg, msgObj) }))
      .finally(() => setIsLoading(false));
  }

  function handleRemoveClick(row: SearchMemberWorkspaceModel) {
    setIsLoading(true);
    const msgObj = {
      user: formatUserDisplayName(user!),
      workspace: row.workspaceName,
    };
    const successMsg = "TeamSettings.TeamUsers.WorkspaceModel.RemoveSuccess";
    const errorMsg = "TeamSettings.TeamUsers.WorkspaceModel.RemoveFailed";
    removeWorkspaceMemberAsync(row.tenantId, row.workspaceId, user!.id)
      .then(() => showSuccess({ message: t(successMsg, msgObj) }))
      .then(() => handleRequestData(pagination))
      .catch(() => showError({ message: t(errorMsg, msgObj) }))
      .finally(() => setIsLoading(false));
  }

  function handleAddClick(row: SearchMemberWorkspaceModel, role: Role) {
    setIsLoading(true);
    const msgObj = {
      user: formatUserDisplayName(user!),
      role: role === Role.ReportEditor ? "an editor" : "a viewer",
      workspace: row.workspaceName,
    };
    const successMsg = "TeamSettings.TeamUsers.WorkspaceModel.InviteSuccess";
    const errorMsg = "TeamSettings.TeamUsers.WorkspaceModel.InviteFailed";
    inviteWorkspaceMembersAsync(
      params.id!,
      [user!.email],
      [{ workspaceId: row.workspaceId, workspaceRole: Role[role] }]
    )
      .then(() => showSuccess({ message: t(successMsg, msgObj) }))
      .then(() => handleRequestData(pagination))
      .catch(() => showError({ message: t(errorMsg, msgObj) }))
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    if (user) handleRequestData(pagination);
  }, [user, isAssignedTab]);

  return (
    <Modal
      open={!!user}
      onClose={handleOnClose}
      sx={{ maxWidth: 650, minWidth: 600 }}
    >
      <DialogTitle sx={{ padding: 2 }} typography="h5">
        {t(titleKey, { name: user && formatUserDisplayName(user) })}
      </DialogTitle>
      <DialogContent sx={{ padding: 2 }}>
        <Tabs
          value={isAssignedTab}
          onChange={(_, value) => setIsAssignedTab(value)}
        >
          <Tab label={t("Common.Assigned")} value={true} />
          <Tab label={t("Common.Unassigned")} value={false} />
        </Tabs>
        <Box sx={{ maxHeight: 500, overflowY: "scroll" }}>
          <DataGrid
            columnHeaderHeight={72}
            rowHeight={67}
            columns={getColumns(
              editingRoles,
              isAssignedTab,
              isLoading,
              handleAddClick,
              handleRoleChange,
              handleRemoveClick
            )}
            getRowId={(row) => row.workspaceId}
            data={data}
            isLoading={isGettingWorkspaces}
            payload={pagination}
            onRequestData={handleRequestData}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          paddingX: 2,
          paddingY: 1,
        }}
      >
        <LoadingButton
          data-testid="WorkspacesAccessModal__DoneBtn"
          variant="contained"
          onClick={handleOnClose}
          loading={isLoading}
          disabled={isLoading}
        >
          {t("Common.Done")}
        </LoadingButton>
      </DialogActions>
    </Modal>
  );
}
