import { ChevronLeftOutlined, ChevronRightOutlined } from "@mui/icons-material";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import moment, { Moment } from "moment";
import React, { useMemo } from "react";
import ReactDatePicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css";

const MyContainer = ({
  className,
  children,
}: {
  className: string;
  children: React.ReactNode;
}) => {
  return (
    <Card elevation={8} sx={{ paddingX: 2, zIndex: 100 }}>
      <CalendarContainer className={className}>
        <div style={{ position: "relative" }}>{children}</div>
      </CalendarContainer>
    </Card>
  );
};

const MyHeader = (props: {
  monthDate: Date;
  customHeaderCount: number;
  decreaseYear: React.MouseEventHandler<HTMLButtonElement> | undefined;
  increaseYear: React.MouseEventHandler<HTMLButtonElement> | undefined;
}) => {
  return (
    <Stack direction="row">
      <Typography
        flexGrow={1}
        variant="subtitle1"
        fontFamily="Roboto"
        textAlign="left"
        fontWeight={500}
        alignContent="center"
      >
        {props.monthDate.toLocaleString("en-US", {
          year: "numeric",
        })}
      </Typography>
      <IconButton aria-label="Previous Month" onClick={props.decreaseYear}>
        <ChevronLeftOutlined />
      </IconButton>
      <IconButton aria-label="Next Month" onClick={props.increaseYear}>
        <ChevronRightOutlined />
      </IconButton>
    </Stack>
  );
};

function getFormat(type: "month" | "quarter" | "year") {
  switch (type) {
    case "month": {
      return "yyyy, MMM";
    }
    case "quarter": {
      return "yyyy, QQQ";
    }
    case "year": {
      return "yyyy";
    }
    default: {
      throw new Error("Function not implemented.");
    }
  }
}

interface Props {
  label: string;
  value: Moment | null;
  onChange: (date: Moment | null) => void;
  minDate?: Moment;
  maxDate?: Moment;
  fiscalMonth?: number;
  size?: "medium" | "small";
  type: "month" | "quarter" | "year";
  disabled?: boolean;
}

export default function DatePicker(props: Props) {
  const { type, label, size } = props;

  const minDate = useMemo(() => {
    if (!props.minDate) return;
    if (!props.fiscalMonth) return props.minDate.toDate();

    const cloned = props.minDate.clone();
    const fiscalDate = cloned.add(13 - props.fiscalMonth, "month");
    return fiscalDate.startOf(type).toDate();
  }, [props.minDate]);

  const maxDate = useMemo(() => {
    if (!props.maxDate) return;
    if (!props.fiscalMonth) return props.maxDate.toDate();

    const cloned = props.maxDate.clone();
    const fiscalDate = cloned.add(13 - props.fiscalMonth, "month");
    return fiscalDate.startOf(type).toDate();
  }, [props.maxDate]);

  const selectedDate = useMemo(() => {
    if (!props.value) return;
    if (!props.fiscalMonth) return props.value.toDate();

    const fiscalDate = props.value.clone().add(13 - props.fiscalMonth, "month");
    return fiscalDate.startOf(type).toDate();
  }, [props.value]);

  function handleOnChange(date: Date | null): void {
    if (props.fiscalMonth) {
      const fiscalDate = moment(date).add(props.fiscalMonth - 13, "month");
      props.onChange(fiscalDate);
    } else {
      date && props.onChange(moment(date));
    }
  }

  return (
    <ReactDatePicker
      selected={selectedDate}
      onChange={handleOnChange}
      showPopperArrow={false}
      customInput={<TextField label={label} size={size} variant="outlined" />}
      minDate={minDate}
      maxDate={maxDate}
      popperPlacement="bottom-start"
      dateFormat={getFormat(type)}
      calendarContainer={MyContainer}
      renderCustomHeader={MyHeader}
      showMonthYearPicker={type === "month"}
      showQuarterYearPicker={type === "quarter"}
      showYearPicker={type === "year"}
      disabled={props.disabled}
    />
  );
}
