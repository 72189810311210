import { Search, MoreHorizOutlined } from "@mui/icons-material";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import {
  GridColDef,
  GridPaginationModel,
  GridSlots,
  GridSortItem,
  GridSortModel,
  GridToolbarFilterButton,
  DataGrid as MuiDataGrid,
  GridRowSelectionModel,
  GridSlotProps,
  GridRowHeightParams,
  GridRowHeightReturnValue,
  GridApi,
} from "@mui/x-data-grid";
import {
  Collection,
  DEFAULT_PAGESIZE,
  FilterCollectionPayload,
  PAGESIZE_OPTIONS,
} from "models/collection";
import React, { JSXElementConstructor, MutableRefObject, useMemo } from "react";
import IconMenu, { IconMenuItemProps } from "components/Menu/IconMenu";

function getContextMenuColumn<Data>(
  getMenuOptions: (row: Data) => IconMenuItemProps[]
): GridColDef {
  return {
    field: "id",
    renderHeader: () => null,
    minWidth: 100,
    renderCell: (params) => (
      <IconMenu
        icon={<MoreHorizOutlined />}
        options={getMenuOptions(params.row)}
      />
    ),
    align: "right",
    sortable: false,
  };
}

interface DataGridProps<Data> {
  apiRef?: MutableRefObject<GridApi>;
  rowHeight?: number;
  columnHeaderHeight?: number;
  columns: GridColDef[];
  data?: Collection<Data>;
  payload: FilterCollectionPayload;
  isLoading: boolean;
  hasSearchBox?: boolean;
  hasFilterButton?: boolean;
  onRequestData: (payload: FilterCollectionPayload) => void;
  rowSelection?: boolean;
  isRowSelectable?: boolean;
  checkboxSelection?: boolean;
  rowSelectionModel?: GridRowSelectionModel;
  onRowSelectionModelChange?: (newSelection: GridRowSelectionModel) => void;
  rowContextMenuOptions?: (row: Data) => IconMenuItemProps[];
  getRowId?: (data: Data) => string | number;
  FooterComponent?: JSXElementConstructor<GridSlotProps["footer"]>;
  getRowHeight?: (params: GridRowHeightParams) => GridRowHeightReturnValue;
  paginationModel?: GridPaginationModel;
  onPaginationModelChange?: (model: GridPaginationModel) => void;
}

interface ToolbarProps {
  payload: FilterCollectionPayload;
  hasSearchBox?: boolean;
  hasFilterButton?: boolean;
  onRequestData: (payload: FilterCollectionPayload) => void;
}

function Toolbar(props: ToolbarProps) {
  const timerRef = React.useRef<ReturnType<typeof setTimeout> | undefined>();

  function handleSearchBoxChanged(
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void {
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      props.payload.filters = { text: event.target.value };
      props.onRequestData(props.payload);
    }, 250);
  }

  return (
    <Stack direction="row" justifyContent="space-between">
      <div>{props.hasFilterButton && <GridToolbarFilterButton />}</div>
      {props.hasSearchBox && (
        <FormControl>
          <OutlinedInput
            id="search"
            size="small"
            placeholder="Search..."
            startAdornment={
              <Search sx={{ color: "var(--icon-color)", marginRight: 1 }} />
            }
            onChange={handleSearchBoxChanged}
          />
        </FormControl>
      )}
    </Stack>
  );
}

export default function DataGrid<Data>(props: DataGridProps<Data>) {
  const {
    payload,
    rowSelection,
    checkboxSelection,
    isRowSelectable = true,
    rowSelectionModel,
    onRowSelectionModelChange,
    getRowId,
    FooterComponent,
    getRowHeight,
    paginationModel,
    onPaginationModelChange,
  } = props;

  const initialSortModel: GridSortItem[] = payload.orderBy
    ? [{ field: payload.orderBy!, sort: payload.order ?? "asc" }]
    : [];

  function handleOnSortModelChanged(model: GridSortModel): void {
    payload.orderBy = model[0]!.field;
    payload.order = model[0]!.sort;
    props.onRequestData(payload);
  }

  function handleOnPaginationModelChanged(model: GridPaginationModel): void {
    payload.page = model.page;
    payload.pageSize = model.pageSize;
    props.onRequestData(payload);
  }

  const columns = useMemo(() => {
    if (props.rowContextMenuOptions) {
      return [
        ...props.columns,
        getContextMenuColumn(props.rowContextMenuOptions),
      ];
    }
    return props.columns;
  }, [props.columns, props.rowContextMenuOptions]);

  return (
    <div style={{ width: "100%" }}>
      <MuiDataGrid
        apiRef={props.apiRef}
        sx={{
          border: 0,
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
        }}
        autoHeight
        disableColumnMenu
        disableColumnResize
        columnHeaderHeight={props.columnHeaderHeight ?? 72}
        rowHeight={props.rowHeight ?? 67}
        sortingMode="server"
        paginationMode="server"
        rowSelection={rowSelection}
        isRowSelectable={() => isRowSelectable}
        checkboxSelection={checkboxSelection}
        rows={props.data?.data}
        columns={columns}
        pageSizeOptions={PAGESIZE_OPTIONS}
        rowCount={props.data?.pagination?.total ?? 0}
        loading={props.isLoading}
        sortingOrder={["asc", "desc"]}
        slotProps={{
          loadingOverlay: {
            variant: "linear-progress",
            noRowsVariant: "skeleton",
          },
          // @ts-ignore
          toolbar: props,
        }}
        initialState={{
          pagination: {
            paginationModel: {
              page: 0,
              pageSize: DEFAULT_PAGESIZE,
            },
          },
          sorting: {
            sortModel: initialSortModel,
          },
        }}
        slots={{
          // @ts-ignore
          toolbar: Toolbar as GridSlots["toolbar"],
          footer: FooterComponent,
        }}
        onSortModelChange={handleOnSortModelChanged}
        onPaginationModelChange={
          onPaginationModelChange || handleOnPaginationModelChanged
        }
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={onRowSelectionModelChange}
        getRowId={getRowId}
        getRowHeight={getRowHeight}
        paginationModel={paginationModel}
      />
    </div>
  );
}
