import {
  CategoriesAndPeriodsTableModel,
  RevenueByChangeCategoryChartModel,
  RevenueByChangeCategoryResponse,
  RevenueByChangeCategoryVolumeImpactResponse,
} from "models/report";
import {
  CustomerLevel,
  MEASUREMENT_PROPERTIES,
  ReportMeasurement,
  RevenueType,
} from "common/constants";
import { Column } from "components/Table/models";
import { formatCurrencyValue, formatPeriodText } from "utils/format";
import { getLastItemOfEachGroup } from "utils/report";
import { CATEGORIES_BY_CUSTOMER_LEVEL } from "common/constants/rollForwardReport";
import {
  ChartData,
  ReportData,
  ReportSettings,
} from "slices/models/reportSliceModel";

function getKeys(reportSettings: ReportSettings) {
  switch (reportSettings.measurement) {
    case ReportMeasurement.MRR: {
      return "SumMrr";
    }
    case ReportMeasurement.ARR: {
      return "SumArr";
    }
    case ReportMeasurement.CMRR: {
      return "SumCMrr";
    }
    case ReportMeasurement.CARR: {
      return "SumCArr";
    }
    // No default
  }
}

function getLastMonthFromDataSubRows(
  chartDataVolumeImpact: ChartData<
    RevenueByChangeCategoryVolumeImpactResponse[]
  >,
  groupBy: string
) {
  if (groupBy === "DISPLAYQTR") {
    return (
      chartDataVolumeImpact.data?.filter((item) => item.IsLastMonthOfQuarter) ||
      []
    );
  }
  if (groupBy === "FISCALYR") {
    return (
      chartDataVolumeImpact.data?.filter((item) => item.IsLastMonthOfYear) || []
    );
  }
  return chartDataVolumeImpact.data || [];
}
function handleVolumeImpactSubRows(
  chartDataVolumeImpact:
    | ChartData<RevenueByChangeCategoryVolumeImpactResponse[]>
    | undefined,
  subRows: { [categories: string]: CategoriesAndPeriodsTableModel[] },
  reportSettings: ReportSettings
) {
  if (!chartDataVolumeImpact?.data || !subRows.Upsell) return;
  const UpsellPrice = {
    category: "Upsell Price",
  } as CategoriesAndPeriodsTableModel;
  const UpsellVolume = {
    category: "Upsell Volume",
  } as CategoriesAndPeriodsTableModel;
  const DownsellPrice = {
    category: "Downsell Price",
  } as CategoriesAndPeriodsTableModel;
  const DownsellVolume = {
    category: "Downsell Volume",
  } as CategoriesAndPeriodsTableModel;
  let groupedData: RevenueByChangeCategoryVolumeImpactResponse[] = [];
  let periodType = "Month" as "Month" | "DISPLAYQTR" | "FISCALYR";
  if (reportSettings.params.revenueType === RevenueType.Monthly) {
    groupedData = getLastMonthFromDataSubRows(chartDataVolumeImpact, "Month");
    periodType = "Month";
  }
  if (reportSettings.params.revenueType === RevenueType.Quarterly) {
    groupedData = getLastMonthFromDataSubRows(
      chartDataVolumeImpact,
      "DISPLAYQTR"
    );
    periodType = "DISPLAYQTR";
  }
  if (reportSettings.params.revenueType === RevenueType.Yearly) {
    groupedData = getLastMonthFromDataSubRows(
      chartDataVolumeImpact,
      "FISCALYR"
    );
    periodType = "FISCALYR";
  }
  for (const period of groupedData) {
    UpsellPrice[formatPeriodText(period[periodType])] =
      period.UPSELL?.[getKeys(reportSettings) + "PriceImpact"] || 0;
    UpsellVolume[formatPeriodText(period[periodType])] =
      period.UPSELL?.[getKeys(reportSettings) + "VolumeImpact"] || 0;
    DownsellPrice[formatPeriodText(period[periodType])] =
      period.DOWNSELL?.[getKeys(reportSettings) + "PriceImpact"] || 0;
    DownsellVolume[formatPeriodText(period[periodType])] =
      period.DOWNSELL?.[getKeys(reportSettings) + "VolumeImpact"] || 0;
  }
  subRows.Upsell.push(UpsellPrice, UpsellVolume);
  subRows.Downsell.push(DownsellPrice, DownsellVolume);
}

export function getRevenueByChangeCategoryTableColumns(
  periods: RevenueByChangeCategoryChartModel[]
) {
  const columns: Column<CategoriesAndPeriodsTableModel>[] = [
    {
      header: "Change Category",
      render: (data) => data.category,
      footer: "Ending Balance",
      sx: {
        position: "sticky",
        left: 0,
        zIndex: 3,
        textWrap: "nowrap",
      },
    },
  ];

  for (const period of periods) {
    const month = formatPeriodText(period.xValue);

    columns.push({
      header: month,
      render: (data) =>
        formatCurrencyValue((data[month] as number) || 0, false, true),
      footer: formatCurrencyValue(period["Ending Balance"]),
      sx: {
        textWrap: "nowrap",
      },
    });
  }

  return columns;
}

export function getRevenueByChangeCategoryTableData(
  periods: RevenueByChangeCategoryChartModel[],
  customerLevel: CustomerLevel
): CategoriesAndPeriodsTableModel[] {
  const data = CATEGORIES_BY_CUSTOMER_LEVEL[customerLevel].map((category) => ({
    category,
  })) as CategoriesAndPeriodsTableModel[];

  for (const period of periods) {
    for (const [index, category] of data.entries()) {
      const month = formatPeriodText(period.xValue);

      category[month] = period[
        CATEGORIES_BY_CUSTOMER_LEVEL[customerLevel][index]
      ] as number;
    }
  }

  return data;
}

function subRowsSortsFn(
  a: CategoriesAndPeriodsTableModel,
  b: CategoriesAndPeriodsTableModel
) {
  const aIndex = Number.parseInt(a.category.match(/\d+/)![0], 10);
  const bIndex = Number.parseInt(b.category.match(/\d+/)![0], 10);

  return aIndex - bIndex;
}

export function computeReportData(
  data: RevenueByChangeCategoryResponse[],
  reportSettings: ReportSettings,
  reportData?: ReportData,
  chartDataVolumeImpact?: ChartData<
    RevenueByChangeCategoryVolumeImpactResponse[]
  >
): {
  computedData: RevenueByChangeCategoryChartModel[];
  subRows: { [categories: string]: CategoriesAndPeriodsTableModel[] };
} {
  let groupedData: RevenueByChangeCategoryResponse[] = [];
  if (reportSettings.params.revenueType === RevenueType.Monthly)
    groupedData = getLastItemOfEachGroup(data, "Month");
  if (reportSettings.params.revenueType === RevenueType.Quarterly)
    groupedData = getLastItemOfEachGroup(data, "DISPLAYQTR");
  if (reportSettings.params.revenueType === RevenueType.Yearly)
    groupedData = getLastItemOfEachGroup(data, "FISCALYR");
  const subRows: { [categories: string]: CategoriesAndPeriodsTableModel[] } = {
    "Cross-Sell": [],
    "Lost Product": [],
  };
  if (
    reportSettings.params.customerLevel === CustomerLevel.Customer &&
    reportData?.file?.mapping?.some(
      (x) => x.mdtName === "Quantity" && x.sourceName
    )
  ) {
    subRows.Upsell = [];
    subRows.Downsell = [];
  }
  const computedData = groupedData.map((period) => {
    const key = MEASUREMENT_PROPERTIES[reportSettings.measurement];
    let xValue = "";
    if (reportSettings.params.revenueType === RevenueType.Monthly)
      xValue = period.Month;
    if (reportSettings.params.revenueType === RevenueType.Quarterly)
      xValue = period.DISPLAYQTR;
    if (reportSettings.params.revenueType === RevenueType.Yearly)
      xValue = period.FISCALYR;

    const computedPeriod: RevenueByChangeCategoryChartModel = {
      xValue,
      "Beginning Balance": period.BeginningBalance[key] || 0,
      "New Customer":
        (period["NEW CUSTOMER"] && period["NEW CUSTOMER"][key]) || 0,
      "Customer Reactivation":
        (period["CUSTOMER REACTIVATION"] &&
          period["CUSTOMER REACTIVATION"][key]) ||
        0,
      Upsell: (period.UPSELL && period.UPSELL[key]) || 0,
      Downsell: (period.DOWNSELL && period.DOWNSELL[key]) || 0,
      "Lost Customer":
        (period["LOST CUSTOMER"] && period["LOST CUSTOMER"][key]) || 0,
      "Ending/Beginning Revenue Credits":
        (period["BEGINNING/ENDING REVENUE CREDITS"] &&
          period["BEGINNING/ENDING REVENUE CREDITS"][key]) ||
        0,
      "Cust Segment Migration":
        (period["CUSTOMER SEGMENT MIGRATION"] &&
          period["CUSTOMER SEGMENT MIGRATION"][key]) ||
        0,
      "Ending Balance": period.EndingBalance[key] || 0,
      year: period.Month.split("-")[0],
      month: period.Month,
    };

    if (reportSettings.params.customerLevel === CustomerLevel.CustomerProduct) {
      computedPeriod["Cross-Sell"] = 0;
      computedPeriod["Lost Product"] = 0;
      computedPeriod["Product Reactivation"] = 0;

      const formatedPeriod = formatPeriodText(xValue);

      for (const [category, measurements] of Object.entries(period)) {
        const value: number = measurements[key];

        if (category.startsWith("CROSS-SELLPRODUCT")) {
          computedPeriod["Cross-Sell"] = computedPeriod["Cross-Sell"] + value;

          const displayCategory = category.replace(
            "CROSS-SELLPRODUCT",
            "Cross-Sell "
          );
          const currentRow = subRows["Cross-Sell"].find(
            (r) => r.category === displayCategory
          );
          if (currentRow) {
            currentRow[formatedPeriod] = value;
          } else {
            subRows["Cross-Sell"].push({
              category: displayCategory,
              [formatedPeriod]: value,
            } as CategoriesAndPeriodsTableModel);
          }
        }
        if (category.startsWith("LOST-PRODUCTPRODUCT")) {
          computedPeriod["Lost Product"] =
            computedPeriod["Lost Product"] + value;

          const displayCategory = category.replace(
            "LOST-PRODUCTPRODUCT",
            "Lost Product "
          );
          const currentRow = subRows["Lost Product"].find(
            (r) => r.category === displayCategory
          );
          if (currentRow) {
            currentRow[formatedPeriod] = value;
          } else {
            subRows["Lost Product"].push({
              category: displayCategory,
              [formatedPeriod]: value,
            } as CategoriesAndPeriodsTableModel);
          }
        }
        if (category.startsWith("PRODUCT REACTIVATIONPRODUCT")) {
          computedPeriod["Product Reactivation"] =
            computedPeriod["Product Reactivation"] + value;
        }
      }
    }
    return computedPeriod;
  });

  subRows["Cross-Sell"].sort(subRowsSortsFn);
  subRows["Lost Product"].sort(subRowsSortsFn);
  handleVolumeImpactSubRows(chartDataVolumeImpact, subRows, reportSettings);
  return {
    computedData,
    subRows,
  };
}
