import GradientCard from "components/Card/GradientCard";
import { useTranslation } from "react-i18next";
import { ARRChangeOverviewCategoryModel } from "models/report";

import {
  WrapperContainer,
  chartMinHeight,
} from "../components/CommonComponents";
import ARRChangesOverviewChart from "../charts/ARRChangesOverviewChart";

export default function SectionARRChangesOverview() {
  const { t } = useTranslation();

  const displayableReportData: ARRChangeOverviewCategoryModel[] = [
    {
      xValue: "2022-01",
      "Beginning Balance": 200_000,
      "New Customer": 300_000,
      Upsell: -100_000,
      Reactivation: -200_000,
      Downsell: -400_000,
      "Lost Customer": -500_000,
      Total: -600_000,
    },
    {
      xValue: "2022-02",
      "Beginning Balance": 600_000,
      "New Customer": 300_000,
      Upsell: 200_000,
      Reactivation: 400_000,
      Downsell: 100_000,
      "Lost Customer": 300_000,
      Total: 400_000,
    },
    {
      xValue: "2022-03",
      "Beginning Balance": 400_000,
      "New Customer": 300_000,
      Upsell: 600_000,
      Reactivation: 900_000,
      Downsell: 100_000,
      "Lost Customer": -900_000,
    },
    {
      xValue: "2023-01",
      "Beginning Balance": -100_000,
      "New Customer": -200_000,
      Upsell: -300_000,
      Reactivation: -500_000,
      Downsell: -800_000,
      "Lost Customer": 700_000,
    },
    {
      xValue: "2023-02",
      "Beginning Balance": 700_000,
      "New Customer": 500_000,
      Upsell: 200_000,
      Reactivation: 600_000,
      Downsell: 400_000,
      "Lost Customer": 400_000,
    },
    {
      xValue: "2023-03",
      "Beginning Balance": 300_000,
      "New Customer": 200_000,
      Upsell: 600_000,
      Reactivation: 400_000,
      Downsell: 100_000,
      "Lost Customer": 100_000,
    },
  ];
  return (
    <>
      <GradientCard
        title={t("Dashboard.Charts.ARRChangesOverview")}
        subheader={t("Dashboard.Charts.ARRChangesOverviewSubheader")}
        onMoreClick={() => console.log("more")}
      >
        <WrapperContainer
          isLoading={false}
          isNoData={displayableReportData.length === 0}
        >
          <ARRChangesOverviewChart
            height={chartMinHeight}
            data={displayableReportData}
          />
        </WrapperContainer>
      </GradientCard>
    </>
  );
}
