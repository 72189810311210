import {
  FormControl,
  MenuItem,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import {
  AnalysisType,
  CustomerLevel,
  ReportMeasurement,
  RevenueType,
} from "common/constants";
import { useChartSettings, useDispatch, useReportSelector } from "common/store";
import GradientCard from "components/Card/GradientCard";
import CohortHeatMap from "components/CohortHeatMap";
import lodashSortBy from "lodash/sortBy";
import {
  ARRCohortReport,
  Axis,
  ByValue,
  CARRCohortReport,
  CohortReport,
  Measurement,
  RelativeTo,
  Show,
} from "models/report";
import { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getCustomerCohortChart } from "services/reportService";
import { setChartSettings, setChartShouldFetch } from "slices/reportSlice";
import useDidUpdateEffect from "utils/hook/useDidUpdateEffect";
import { shouldRefetchRevenueData } from "utils/report";

import { WrapperContainer } from "../components/CommonComponents";

import {
  getByOptions,
  MEASUREMENT_MAPPING,
  getShowOptions,
} from "./SectionCohorts.utils";

export default function SectionCohorts() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    reportData,
    reportSettings,
    chartData: allChartData,
  } = useReportSelector();
  const chartSettings = useChartSettings("customer-cohorts");
  const chartData = allChartData["customer-cohorts"];

  const currentMeasurement: Measurement = useMemo(() => {
    return MEASUREMENT_MAPPING[reportSettings.measurement] as Measurement;
  }, [reportSettings.measurement]);

  const [prevMeasurement, setPrevMeasurement] = useState<ReportMeasurement>(
    ReportMeasurement.ARR
  );

  const titleKey = useMemo(() => {
    if (chartSettings.axis === "cohort-tenure")
      return "Dashboard.CohortsCard.ByTenureTitle";
    if (chartSettings.axis === "calendar-period")
      return "Dashboard.CohortsCard.ByCalendarTitle";
    return "";
  }, [chartSettings.axis]);

  const currentShowValue = useMemo(() => {
    return chartSettings.show !== "count" && chartSettings.show !== "volume"
      ? "measure"
      : chartSettings.show;
  }, [chartSettings.show]);

  const filteredData = useMemo(() => {
    if (
      chartData.isLoading ||
      chartData.data === undefined ||
      !reportSettings.filters.minDate ||
      !reportSettings.filters.maxDate
    )
      return [];

    const startMonth = reportSettings.filters.minDate!.slice(0, -3);
    const endMonth = reportSettings.filters.maxDate!.slice(0, -3);

    const filtered = chartData.data.filter((item: CohortReport) => {
      if (item.Month < startMonth || item.Month > endMonth) return false;

      let arrStartDate =
        reportSettings.measurement === ReportMeasurement.ARR ||
        reportSettings.measurement === ReportMeasurement.MRR
          ? (item as ARRCohortReport).ARRSTARTDATE
          : (item as CARRCohortReport).CARRSTARTDATE;
      arrStartDate = arrStartDate && arrStartDate.slice(0, 7); // Get month part of the date: YYYY-MM

      if (arrStartDate < startMonth || arrStartDate > endMonth) {
        return false;
      }
      return true;
    });

    return lodashSortBy(filtered, (item) => item.Month);
  }, [
    chartData.isLoading,
    chartData.data,
    reportSettings.measurement,
    reportSettings.filters.minDate,
    reportSettings.filters.maxDate,
  ]);

  const showOptions = useMemo(() => {
    return getShowOptions(reportSettings.measurement);
  }, [reportSettings.measurement]);

  function handleOnPeriodViewChanged(value: RevenueType) {
    if (value !== chartSettings.period) {
      dispatch(
        setChartSettings({
          "customer-cohorts": {
            period: value,
            priorPeriodComparison: AnalysisType.YoY,
          },
        })
      );
    }
  }

  function handleOnAxisChanged(value: Axis) {
    if (value !== chartSettings.axis) {
      dispatch(setChartSettings({ "customer-cohorts": { axis: value } }));
    }
  }

  function handleOnShowChanged(value: Show) {
    if (value !== chartSettings.show) {
      dispatch(
        setChartSettings({
          "customer-cohorts": { show: value, byValue: "base" },
        })
      );
      dispatch(setChartShouldFetch(["customer-cohorts"]));
    }
  }

  function handleOnByValueChanged(value: ByValue) {
    const byOldValue = chartSettings.byValue;

    if (value !== byOldValue) {
      dispatch(
        setChartSettings({
          "customer-cohorts": { byValue: value },
        })
      );
      if (
        chartSettings.show !== "count" &&
        chartSettings.show !== "volume" &&
        (byOldValue === "base" || value === "base")
      ) {
        // When viewing by revenue, only refetch data when changing from "Base amount" to another option and vice versa
        dispatch(setChartShouldFetch(["customer-cohorts"]));
      }
    }
  }

  function handleRelativeToValueChange(value: RelativeTo) {
    if (value !== chartSettings.relativeTo) {
      dispatch(
        setChartSettings({
          "customer-cohorts": { relativeTo: value },
        })
      );
    }
  }

  function handlePriorPeriodComparisonChange(value: AnalysisType) {
    if (value !== chartSettings.priorPeriodComparison) {
      dispatch(
        setChartSettings({
          "customer-cohorts": { priorPeriodComparison: value },
        })
      );
    }
  }

  useEffect(() => {
    if (
      !reportData.isLoading &&
      chartData.shouldFetch &&
      chartSettings.show !== undefined
    ) {
      dispatch(
        getCustomerCohortChart({
          byBaseAmount: chartSettings.byValue === "base",
          showValue: chartSettings.show,
        })
      );
    }
  }, [reportData.isLoading, chartData.shouldFetch, chartSettings.show]);

  useEffect(() => {
    dispatch(setChartShouldFetch(["customer-cohorts"]));
  }, [chartSettings.priorPeriodComparison]);

  useDidUpdateEffect(() => {
    if (
      !reportData.file?.id ||
      !shouldRefetchRevenueData(reportSettings.measurement, prevMeasurement)
    ) {
      setPrevMeasurement(reportSettings.measurement);
      return;
    }
    dispatch(setChartShouldFetch(["customer-cohorts"]));
    setPrevMeasurement(reportSettings.measurement);
  }, [reportSettings.measurement]);

  useDidUpdateEffect(() => {
    dispatch(setChartShouldFetch(["customer-cohorts"]));
  }, [
    reportSettings.filters.customers,
    reportSettings.filters.segmentCustomers,
    reportSettings.filters.segmentProducts,
  ]);

  useDidUpdateEffect(() => {
    if (chartSettings.show !== "count" && chartSettings.show !== "volume") {
      handleOnShowChanged(currentMeasurement);
    }
  }, [currentMeasurement]);

  return (
    <>
      <Typography variant="h6" marginBottom={2} color="var(--text-secondary)">
        {t("Dashboard.Charts.SectionCohorts")}
      </Typography>
      <GradientCard
        title={
          <Stack direction="row" alignItems="center" flexWrap="wrap" gap={2}>
            <Stack direction="row" alignItems="center">
              <Typography variant="h5" sx={{ marginLeft: 1, marginRight: 2 }}>
                {t(titleKey)}:
              </Typography>
              <ToggleButtonGroup
                exclusive
                disabled={
                  reportSettings.params.customerLevel ===
                  CustomerLevel.CustomerProduct
                }
                value={chartSettings.period}
                onChange={(_, value) =>
                  value !== null && handleOnPeriodViewChanged(value)
                }
              >
                <ToggleButton value={RevenueType.Monthly}>
                  {t("Common.Month")}
                </ToggleButton>
                <ToggleButton value={RevenueType.Quarterly}>
                  {t("Common.Quarter")}
                </ToggleButton>
                <ToggleButton value={RevenueType.Yearly}>
                  {t("Common.Year")}
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Typography variant="h5" sx={{ marginLeft: 1, marginRight: 2 }}>
                {t("Common.Axis")}:
              </Typography>
              <ToggleButtonGroup
                exclusive
                disabled={
                  reportSettings.params.customerLevel ===
                  CustomerLevel.CustomerProduct
                }
                value={chartSettings.axis}
                onChange={(_, value) => handleOnAxisChanged(value)}
              >
                <ToggleButton value="cohort-tenure">
                  {t("Dashboard.CohortsCard.AxisTenure")}
                </ToggleButton>
                <ToggleButton value="calendar-period">
                  {t("Dashboard.CohortsCard.AxisCalendar")}
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Stack>
        }
      >
        <Stack direction="column">
          <Stack
            direction="row"
            alignItems="center"
            marginBottom={2}
            flexWrap="wrap"
            gap={2}
          >
            <Stack direction="row" alignItems="center">
              <Typography variant="h6" sx={{ marginLeft: 1, marginRight: 2 }}>
                {t("Dashboard.CohortsCard.LabelShow")}:
              </Typography>
              <ToggleButtonGroup
                exclusive
                disabled={
                  reportSettings.params.customerLevel ===
                  CustomerLevel.CustomerProduct
                }
                value={chartSettings.show}
                onChange={(_, value) =>
                  value !== null && handleOnShowChanged(value)
                }
              >
                {showOptions.map((option) => (
                  <ToggleButton
                    key={option.value}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {t(option.labelKey)}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Stack>
            <Stack direction="row" alignItems="center">
              <Typography variant="h6" sx={{ marginLeft: 1, marginRight: 2 }}>
                {t("Dashboard.CohortsCard.LabelBy")}:
              </Typography>
              <FormControl>
                <Select
                  disabled={
                    reportSettings.params.customerLevel ===
                    CustomerLevel.CustomerProduct
                  }
                  size="small"
                  sx={{ width: "250px" }}
                  value={chartSettings.byValue}
                  onChange={(e) =>
                    handleOnByValueChanged(e.target.value as ByValue)
                  }
                >
                  {getByOptions(chartSettings.show).map((options) => (
                    <MenuItem key={options.value} value={options.value}>
                      {t(options.labelKey)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            {chartSettings.byValue !== "base" && (
              <Stack direction="row" alignItems="center">
                <Typography variant="h6" sx={{ marginLeft: 1, marginRight: 2 }}>
                  {t("Dashboard.CohortsCard.LabelRelation")}:
                </Typography>
                <ToggleButtonGroup
                  exclusive
                  value={chartSettings.relativeTo}
                  onChange={(_, value) =>
                    value !== null && handleRelativeToValueChange(value)
                  }
                >
                  <ToggleButton value="initial">
                    {t("Dashboard.CohortsCard.RelationSelect.InitialPeriod")}
                  </ToggleButton>
                  <ToggleButton value="prior">
                    {t("Dashboard.CohortsCard.RelationSelect.PriorPeriod")}
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            )}
          </Stack>
          {chartSettings.byValue !== "base" &&
            chartSettings.relativeTo === "prior" && (
              <Stack direction="row" alignItems="center" marginBottom={2}>
                <Typography variant="h6" sx={{ marginLeft: 1, marginRight: 2 }}>
                  {t("Dashboard.CohortsCard.LabelPriorPeriodComparison")}:
                </Typography>
                <FormControl>
                  <Select
                    size="small"
                    sx={{ width: "220px" }}
                    value={chartSettings.priorPeriodComparison}
                    onChange={(e) =>
                      handlePriorPeriodComparisonChange(
                        e.target.value as AnalysisType
                      )
                    }
                  >
                    <MenuItem value={AnalysisType.YoY}>
                      {t(
                        t(
                          "Dashboard.CohortsCard.PriorPeriodComparisonSelect.YoY"
                        )
                      )}
                    </MenuItem>
                    {chartSettings.period !== RevenueType.Yearly && (
                      <MenuItem value={AnalysisType.QoQ}>
                        {t(
                          t(
                            "Dashboard.CohortsCard.PriorPeriodComparisonSelect.QoQ"
                          )
                        )}
                      </MenuItem>
                    )}
                    {chartSettings.period === RevenueType.Monthly && (
                      <MenuItem value={AnalysisType.MoM}>
                        {t(
                          t(
                            "Dashboard.CohortsCard.PriorPeriodComparisonSelect.MoM"
                          )
                        )}
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Stack>
            )}
          {reportSettings.params.customerLevel ===
          CustomerLevel.CustomerProduct ? (
            t("Common.ComingSoon")
          ) : (
            <WrapperContainer
              isLoading={reportData.isLoading || chartData.isLoading}
              error={reportData.error || chartData.error}
              isNoData={filteredData.length === 0}
              minHeight={0}
            >
              <CohortHeatMap
                data={filteredData}
                axis={chartSettings.axis || "cohort-tenure"}
                revenueType={chartSettings.period || RevenueType.Monthly}
                measurement={reportSettings.measurement}
                show={currentShowValue}
                by={chartSettings.byValue || "base"}
                relativeTo={chartSettings.relativeTo || "initial"}
              />
            </WrapperContainer>
          )}
        </Stack>
      </GradientCard>
    </>
  );
}
