import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import {
  useSelector as useRootSelector,
  useDispatch as useRootDispatch,
  TypedUseSelectorHook,
} from "react-redux";
import {
  defaultReportSettings,
  ChartSettings,
} from "slices/models/reportSliceModel";

import { reducer } from "./reducer";

const store = configureStore({
  reducer,
});

export const useDispatch = () => useRootDispatch<ReduxDispatch>();
export const useSelector: TypedUseSelectorHook<ReduxState> = useRootSelector;

export type ReduxStore = typeof store;
export type ReduxDispatch = typeof store.dispatch;
export type ReduxState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  ReduxState,
  unknown,
  Action
>;

export const useReportSelector = () =>
  useSelector((state) => {
    return {
      ...state.report,
      reportSettings:
        state.report.currentSettings[state.report.dashboardId] ??
        defaultReportSettings,
    };
  });

export const useChartSettings = <K extends keyof ChartSettings>(
  chartKey: K
): ChartSettings[K] => {
  return useReportSelector().reportSettings.chartSettings[chartKey];
};

/* Types */
export default store;
