import {
  ByValue,
  CohortReport,
  RelativeTo,
  ARRCohortReport,
  CARRCohortReport,
} from "models/report";
import type { Dictionary } from "lodash";
import { ReportMeasurement, RevenueType } from "common/constants";
import { formatPeriodText, formatPercentageValue } from "utils/format";
import { getLastItemOfEachGroup } from "utils/report";
import lodashOrderBy from "lodash/orderBy";

import {
  calendarPeriodMapping,
  revenueLabelMapping,
  measurementMapping,
} from "./constants";

export function getTenureTotal(
  groupedData: Dictionary<CohortReport[]>,
  columnKey: string,
  columnValue: string | number,
  valueKey: string
) {
  let total = 0;

  for (const group of Object.values(groupedData)) {
    const filteredValues = getLastItemOfEachGroup(
      lodashOrderBy(group, (item) => item.Month),
      columnKey as keyof CohortReport
    );
    const period = filteredValues.find(
      (p) => p[columnKey as keyof CohortReport] === columnValue
    );
    total += period
      ? (period[valueKey as keyof CohortReport] as number) || 0
      : 0;
  }
  return total;
}

function hsbToHsl(h: number, s: number, b: number): string {
  const l = (b / 100) * (100 - s / 2);
  const newS = l === 0 || l === 1 ? 0 : ((b - l) / Math.min(l, 100 - l)) * 100;

  return `hsl(${h}, ${newS}%, ${l}%)`;
}

export function getBgColor(
  value?: number | null,
  prevValue?: number | null
): string {
  if (
    value === undefined ||
    value === null ||
    prevValue === undefined ||
    prevValue === null
  ) {
    return hsbToHsl(151, 0, 100);
  }

  if (prevValue === undefined && prevValue === value) {
    return hsbToHsl(151, 0, 100);
  }
  let percentage = Math.abs(((value - prevValue) * 100) / prevValue);

  if (percentage > 200) {
    percentage = 200;
  }

  if (value > prevValue) {
    if (percentage <= 100) {
      return hsbToHsl(151, percentage, 100);
    }
    return hsbToHsl(151, 100, 100 - (percentage - 100) / 2);
  }
  if (value < prevValue) {
    if (percentage <= 100) {
      return hsbToHsl(14, percentage, 100);
    }
    return hsbToHsl(14, 100, 100 - (percentage - 100) / 2);
  }
  return hsbToHsl(151, 0, 100);
}

export function getTextColor(
  value?: number | null,
  prevValue?: number | null
): string {
  if (
    value === undefined ||
    value === null ||
    prevValue === undefined ||
    prevValue === null
  ) {
    return "var(--black)";
  }
  const percentage = Math.abs(((value - prevValue) * 100) / prevValue);

  if (value > prevValue && percentage > 160) {
    return "var(--white)";
  }
  if (value < prevValue && percentage > 130) {
    return "var(--white)";
  }
  return "var(--black)";
}

export function getColumns(
  groupedData: Dictionary<CohortReport[]>,
  key: string
) {
  const columnSet = new Set(
    Object.values(groupedData).flatMap((g) => {
      return g.flatMap((p) => p[key as keyof CohortReport]);
    })
  ) as Set<number | string>;

  return [...columnSet]
    .filter((col) => col !== undefined)
    .sort((a, b) => {
      if (typeof a === "string") {
        if (key === "DISPLAYQTR") {
          const [a1, a2] = a.split("-"); // e.g. Q1-24
          const [b1, b2] = (a as string).split("-");

          return `${a2}-${a1}`.localeCompare(`${b2}-${b1}`);
        }
        return (a as string).localeCompare(b as string);
      }
      return a - (b as number);
    });
}

export function getTenureKey(
  measurement: ReportMeasurement,
  revenueType: RevenueType
): string {
  const measureString =
    measurement === ReportMeasurement.ARR ||
    measurement === ReportMeasurement.MRR
      ? measurementMapping[ReportMeasurement.ARR]
      : measurementMapping[ReportMeasurement.CARR];

  return `${measureString}TENURE${revenueLabelMapping[revenueType]}`;
}

export function getTenureHeader(
  value: number | string,
  tenureCount: number
): string {
  if (value === undefined) {
    return "";
  }
  if (typeof value === "string") {
    return formatPeriodText(value);
  }
  const digits = tenureCount.toString().length;

  return value.toString().padStart(digits, "0");
}

function getInitialRetentionValue(
  cohort: Omit<CohortReport, "Month" | "DISPLAYQTR" | "FISCALYR">,
  by: ByValue
) {
  const {
    SUM_BEGINNING_BALANCE_INITIAL,
    SUM_DOWNSELL_INITIAL,
    SUM_UPSELL_INITIAL,
    SUM_LOST_CUSTOMER_INITIAL,
  } = cohort;

  if (
    ![
      SUM_BEGINNING_BALANCE_INITIAL,
      SUM_DOWNSELL_INITIAL,
      SUM_UPSELL_INITIAL,
      SUM_LOST_CUSTOMER_INITIAL,
    ].some((value) => value !== null && value !== undefined)
  ) {
    return null;
  }

  let dividend = 0;

  if (by === "gross") {
    dividend =
      (SUM_BEGINNING_BALANCE_INITIAL || 0) +
      (SUM_DOWNSELL_INITIAL || 0) +
      (SUM_LOST_CUSTOMER_INITIAL || 0);
  } else if (by === "net") {
    dividend =
      (SUM_BEGINNING_BALANCE_INITIAL || 0) +
      (SUM_UPSELL_INITIAL || 0) +
      (SUM_DOWNSELL_INITIAL || 0) +
      (SUM_LOST_CUSTOMER_INITIAL || 0);
  } else {
    dividend =
      (SUM_BEGINNING_BALANCE_INITIAL || 0) + (SUM_LOST_CUSTOMER_INITIAL || 0);
  }
  if (!SUM_BEGINNING_BALANCE_INITIAL) {
    if (dividend === 0) {
      return "Common.NotAvailable";
    }
    return "Common.InfiniteValue";
  }
  return dividend / SUM_BEGINNING_BALANCE_INITIAL;
}
function getPriorRetentionValue(
  cohort: Omit<CohortReport, "Month" | "DISPLAYQTR" | "FISCALYR">,
  by: ByValue
) {
  const {
    SUM_BEGINNING_BALANCE_PRIOR,
    SUM_DOWNSELL_PRIOR,
    SUM_UPSELL_PRIOR,
    SUM_LOST_CUSTOMER_PRIOR,
  } = cohort;

  if (
    ![
      SUM_BEGINNING_BALANCE_PRIOR,
      SUM_DOWNSELL_PRIOR,
      SUM_UPSELL_PRIOR,
      SUM_LOST_CUSTOMER_PRIOR,
    ].some((value) => value !== null && value !== undefined)
  ) {
    return null;
  }

  let dividend = 0;

  if (by === "gross") {
    dividend =
      (SUM_BEGINNING_BALANCE_PRIOR || 0) +
      (SUM_DOWNSELL_PRIOR || 0) +
      (SUM_LOST_CUSTOMER_PRIOR || 0);
  } else if (by === "net") {
    dividend =
      (SUM_BEGINNING_BALANCE_PRIOR || 0) +
      (SUM_UPSELL_PRIOR || 0) +
      (SUM_DOWNSELL_PRIOR || 0) +
      (SUM_LOST_CUSTOMER_PRIOR || 0);
  } else {
    dividend =
      (SUM_BEGINNING_BALANCE_PRIOR || 0) + (SUM_LOST_CUSTOMER_PRIOR || 0);
  }
  if (!SUM_BEGINNING_BALANCE_PRIOR) {
    if (dividend === 0) {
      return "Common.NotAvailable";
    }
    return "Common.InfiniteValue";
  }
  return dividend / SUM_BEGINNING_BALANCE_PRIOR;
}

function getCustomerRetentionValue(
  cohort: Omit<CohortReport, "Month" | "DISPLAYQTR" | "FISCALYR">,
  relativeTo: RelativeTo
) {
  const {
    INITIAL_CUSTOMER,
    INITIAL_LOST_CUSTOMER,
    PRIOR_CUSTOMER,
    PRIOR_LOST_CUSTOMER,
  } = cohort;

  if (relativeTo === "initial") {
    if (
      ![INITIAL_CUSTOMER, INITIAL_LOST_CUSTOMER].some(
        (value) => value !== null && value !== undefined
      )
    ) {
      return null;
    }
    const dividend = (INITIAL_CUSTOMER || 0) + (INITIAL_LOST_CUSTOMER || 0);

    if (!INITIAL_CUSTOMER) {
      if (!dividend) {
        return "Common.NotAvailable";
      }
      return "Common.InfiniteValue";
    }
    return dividend / INITIAL_CUSTOMER;
  }
  if (
    ![PRIOR_CUSTOMER, PRIOR_LOST_CUSTOMER].some(
      (value) => value !== null && value !== undefined
    )
  ) {
    return null;
  }
  const dividend = (PRIOR_CUSTOMER || 0) + (PRIOR_LOST_CUSTOMER || 0);

  if (!PRIOR_CUSTOMER) {
    if (!dividend) {
      return "Common.NotAvailable";
    }
    return "Common.InfiniteValue";
  }
  return dividend / PRIOR_CUSTOMER;
}

export function getRetentionValue(
  by: ByValue,
  relativeTo: RelativeTo,
  data: Omit<CohortReport, "Month" | "DISPLAYQTR" | "FISCALYR">
): number | string | null {
  let percentage: number | string | null = null;

  // show = "customer" & by = "customer retention"
  if (by === "customer") {
    percentage = getCustomerRetentionValue(data, relativeTo);
    // show = "revenue"
  } else {
    if (relativeTo === "initial") {
      percentage = getInitialRetentionValue(data, by);
    } else if (relativeTo === "prior") {
      percentage = getPriorRetentionValue(data, by);
    }
  }
  if (typeof percentage === "number") {
    percentage = percentage * 100;
  }
  return percentage;
}

function getCohortValuesByBaseAmount(
  headerKey: string,
  columns: (string | number)[],
  filteredByPeriod: { cohort: string; data: CohortReport[] }[],
  period: CohortReport
) {
  const cols: CohortReport[] = [];

  for (const col of columns) {
    let CUSTOMER_COUNT: number | null = null;
    let SumArrAmount: number | null = null;
    let SumMrrAmount: number | null = null;
    let SumCArrAmount: number | null = null;
    let SumCMrrAmount: number | null = null;

    for (const p of filteredByPeriod) {
      const current = p.data.find(
        (i) => i[headerKey as keyof CohortReport] === col
      );
      if (
        current?.CUSTOMER_COUNT !== null &&
        current?.CUSTOMER_COUNT !== undefined
      ) {
        CUSTOMER_COUNT = CUSTOMER_COUNT || 0;
        CUSTOMER_COUNT += current?.CUSTOMER_COUNT || 0;
      }
      if (
        (current as ARRCohortReport)?.SumArrAmount !== null &&
        (current as ARRCohortReport)?.SumArrAmount !== undefined
      ) {
        SumArrAmount = SumArrAmount || 0;
        SumArrAmount += (current as ARRCohortReport)?.SumArrAmount || 0;
      }
      if (
        (current as ARRCohortReport)?.SumMrrAmount !== null &&
        (current as ARRCohortReport)?.SumMrrAmount !== undefined
      ) {
        SumMrrAmount = SumMrrAmount || 0;
        SumMrrAmount += (current as ARRCohortReport)?.SumMrrAmount || 0;
      }
      if (
        (current as CARRCohortReport)?.SumCArrAmount !== null &&
        (current as CARRCohortReport)?.SumCArrAmount !== undefined
      ) {
        SumCArrAmount = SumCArrAmount || 0;
        SumCArrAmount += (current as CARRCohortReport)?.SumCArrAmount || 0;
      }
      if (
        (current as CARRCohortReport)?.SumCMrrAmount !== null &&
        (current as CARRCohortReport)?.SumCMrrAmount !== undefined
      ) {
        SumCMrrAmount = SumCMrrAmount || 0;
        SumCMrrAmount += (current as CARRCohortReport)?.SumCMrrAmount || 0;
      }
    }

    cols.push({
      Month: period.Month,
      FISCALYR: period.FISCALYR,
      DISPLAYQTR: period.DISPLAYQTR,
      [headerKey]: col,
      CUSTOMER_COUNT,
      SumArrAmount,
      SumMrrAmount,
      SumCArrAmount,
      SumCMrrAmount,
    } as unknown as CohortReport);
  }
  return cols;
}

function accumulateValue(
  current: number | null,
  value?: number | null
): number | null {
  if (typeof value === "number") {
    return (current || 0) + value;
  }
  return current;
}

// Sum retention-related values from grouped cohort, then return summed values as a CohortReport object
function getSummedRowsRetention(
  headerKey: string,
  headerValue: string | number,
  cohorts: { cohort: string; data: CohortReport[] }[]
) {
  // Revenue retention
  let SUM_BEGINNING_BALANCE_INITIAL: number | null = null;
  let SUM_DOWNSELL_INITIAL: number | null = null;
  let SUM_UPSELL_INITIAL: number | null = null;
  let SUM_LOST_CUSTOMER_INITIAL: number | null = null;
  let SUM_BEGINNING_BALANCE_PRIOR: number | null = null;
  let SUM_DOWNSELL_PRIOR: number | null = null;
  let SUM_UPSELL_PRIOR: number | null = null;
  let SUM_LOST_CUSTOMER_PRIOR: number | null = null;
  // Customer retention
  let INITIAL_CUSTOMER: number | null = null;
  let INITIAL_LOST_CUSTOMER: number | null = null;
  let PRIOR_CUSTOMER: number | null = null;
  let PRIOR_LOST_CUSTOMER: number | null = null;

  for (const p of cohorts) {
    const cohort = p.data.find(
      (i) => i[headerKey as keyof CohortReport] === headerValue
    );
    if (cohort) {
      SUM_BEGINNING_BALANCE_INITIAL = accumulateValue(
        SUM_BEGINNING_BALANCE_INITIAL,
        cohort.SUM_BEGINNING_BALANCE_INITIAL
      );
      SUM_DOWNSELL_INITIAL = accumulateValue(
        SUM_DOWNSELL_INITIAL,
        cohort.SUM_DOWNSELL_INITIAL
      );
      SUM_UPSELL_INITIAL = accumulateValue(
        SUM_UPSELL_INITIAL,
        cohort.SUM_UPSELL_INITIAL
      );
      SUM_LOST_CUSTOMER_INITIAL = accumulateValue(
        SUM_LOST_CUSTOMER_INITIAL,
        cohort.SUM_LOST_CUSTOMER_INITIAL
      );
      SUM_BEGINNING_BALANCE_PRIOR = accumulateValue(
        SUM_BEGINNING_BALANCE_PRIOR,
        cohort.SUM_BEGINNING_BALANCE_PRIOR
      );
      SUM_DOWNSELL_PRIOR = accumulateValue(
        SUM_DOWNSELL_PRIOR,
        cohort.SUM_DOWNSELL_PRIOR
      );
      SUM_UPSELL_PRIOR = accumulateValue(
        SUM_UPSELL_PRIOR,
        cohort.SUM_UPSELL_PRIOR
      );
      SUM_LOST_CUSTOMER_PRIOR = accumulateValue(
        SUM_LOST_CUSTOMER_PRIOR,
        cohort.SUM_LOST_CUSTOMER_PRIOR
      );
      INITIAL_CUSTOMER = accumulateValue(
        INITIAL_CUSTOMER,
        cohort.INITIAL_CUSTOMER
      );
      INITIAL_LOST_CUSTOMER = accumulateValue(
        INITIAL_LOST_CUSTOMER,
        cohort.INITIAL_LOST_CUSTOMER
      );
      PRIOR_CUSTOMER = accumulateValue(PRIOR_CUSTOMER, cohort.PRIOR_CUSTOMER);
      PRIOR_LOST_CUSTOMER = accumulateValue(
        PRIOR_LOST_CUSTOMER,
        cohort.PRIOR_LOST_CUSTOMER
      );
    }
  }
  return {
    SUM_BEGINNING_BALANCE_INITIAL,
    SUM_DOWNSELL_INITIAL,
    SUM_UPSELL_INITIAL,
    SUM_LOST_CUSTOMER_INITIAL,
    SUM_BEGINNING_BALANCE_PRIOR,
    SUM_DOWNSELL_PRIOR,
    SUM_UPSELL_PRIOR,
    SUM_LOST_CUSTOMER_PRIOR,
    INITIAL_CUSTOMER,
    INITIAL_LOST_CUSTOMER,
    PRIOR_CUSTOMER,
    PRIOR_LOST_CUSTOMER,
  } as Omit<CohortReport, "Month" | "DISPLAYQTR" | "FISCALYR">;
}

function getCohortValuesByRetentionAmount(
  headerKey: string,
  columns: (string | number)[],
  filteredByPeriod: { cohort: string; data: CohortReport[] }[],
  period: CohortReport,
  by: ByValue,
  relativeTo: RelativeTo
) {
  const cols: CohortReport[] = [];

  for (const col of columns) {
    const summedValues = getSummedRowsRetention(
      headerKey,
      col,
      filteredByPeriod
    );
    let retentionValue = getRetentionValue(by, relativeTo, summedValues);

    if (typeof retentionValue === "number") {
      retentionValue = Math.round(retentionValue * 100) / 100;
    }
    cols.push({
      Month: period.Month,
      FISCALYR: period.FISCALYR,
      DISPLAYQTR: period.DISPLAYQTR,
      [headerKey]: col,
      retentionValue,
      ...summedValues,
    } as unknown as CohortReport);
  }
  return cols;
}

export function getRows(
  groupedData: Dictionary<CohortReport[]>,
  headerKey: string,
  lastPeriodOfEachGroup: CohortReport[],
  columns: (string | number)[],
  revenueType: RevenueType,
  by: ByValue,
  relativeTo: RelativeTo
) {
  const sorted = lodashOrderBy(Object.entries(groupedData), ([key]) => key);

  const rowsByMonth = sorted.map(([cohort, values]) => {
    // Filter value for each of the columns of the current cohort
    let filteredValues = getLastItemOfEachGroup(
      lodashOrderBy(values, (item) => item.Month),
      headerKey as keyof CohortReport
    );
    if (by !== "base") {
      filteredValues = filteredValues.map((c) => {
        const retentionValue = getRetentionValue(by, relativeTo, c);

        return {
          ...c,
          retentionValue,
        };
      });
    }
    return {
      cohort,
      data: filteredValues,
    };
  });
  if (
    headerKey === "ARRTENURE" ||
    headerKey === "CARRTENURE" ||
    headerKey === "Month"
  ) {
    return rowsByMonth;
  }
  const summedRows: { cohort: string; data: CohortReport[] }[] = [];

  for (const [index, period] of lastPeriodOfEachGroup.entries()) {
    // Filter months by the current period (quarter or year)
    const filtered = rowsByMonth.filter((r) => {
      const month = r.cohort.slice(0, 7);

      if (index === 0) {
        return month <= period.Month;
      } else if (index === lastPeriodOfEachGroup.length - 1) {
        return month > lastPeriodOfEachGroup[index - 1].Month;
      } else {
        return (
          month > lastPeriodOfEachGroup[index - 1].Month &&
          month <= period.Month
        );
      }
    });
    if (filtered.length > 0) {
      const currentCohort = period[
        calendarPeriodMapping[revenueType] as keyof CohortReport
      ] as string;
      const cols =
        by === "base"
          ? getCohortValuesByBaseAmount(headerKey, columns, filtered, period)
          : getCohortValuesByRetentionAmount(
              headerKey,
              columns,
              filtered,
              period,
              by,
              relativeTo
            );

      summedRows.push({
        cohort: currentCohort,
        data: cols,
      });
    }
  }
  return summedRows;
}

export function getRetentionTotal(
  rows: { cohort: string; data: CohortReport[] }[],
  headerKey: string,
  headerValue: string | number,
  by: ByValue,
  relativeTo: RelativeTo
) {
  const value = getRetentionValue(
    by,
    relativeTo,
    getSummedRowsRetention(headerKey, headerValue, rows)
  );
  if (typeof value === "number") {
    return formatPercentageValue(value);
  }
  return value || "Common.NotAvailable";
}
