import {
  FormControl,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { RevenueType } from "common/constants";
import { useTranslation } from "react-i18next";
import { ChangeEvent } from "react";

interface TimeFrameSelectProps {
  value: RevenueType | "ytd";
  onChange: (value: RevenueType | "ytd") => void;
  label?: string;
  disabled?: boolean;
}

export default function TimeFrameSelect({
  value,
  onChange,
  label,
  disabled,
}: TimeFrameSelectProps) {
  const { t } = useTranslation();

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    newValue: string
  ) => {
    onChange(newValue as RevenueType | "ytd");
  };

  return (
    <FormControl disabled={disabled}>
      <Typography variant="body1">
        {label || t("TimeFrameSelect.Title")}
      </Typography>
      <RadioGroup value={value} onChange={handleChange}>
        <FormControlLabel
          value={RevenueType.Yearly}
          control={<Radio />}
          label={t("TimeFrameSelect.OptionYearly")}
        />
        <FormControlLabel
          value={RevenueType.Quarterly}
          control={<Radio />}
          label={t("TimeFrameSelect.OptionQuarterly")}
        />
        <FormControlLabel
          value={RevenueType.Monthly}
          control={<Radio />}
          label={t("TimeFrameSelect.OptionMonthly")}
        />
        <FormControlLabel
          disabled
          value="ytd"
          control={<Radio />}
          label={t("TimeFrameSelect.OptionYearToDate")}
        />
      </RadioGroup>
    </FormControl>
  );
}
