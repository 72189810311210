import { teamManagementClient } from "common/client/teamManagementClient";
import { FilterCollectionPayload } from "models/collection";
import { WorkspaceAccessModel } from "models/workspace";

export async function searchWorkspaces(payload: FilterCollectionPayload) {
  const { data: response } = await teamManagementClient.post(
    "/workspaces/search",
    payload
  );
  return response.data;
}

export interface RenameWorkspacePayload {
  name: string;
  description: string;
}

export async function renameWorkspace(
  wsId: string,
  body: RenameWorkspacePayload
) {
  const { data: response } = await teamManagementClient.put(
    `workspaces/${wsId}`,
    body
  );

  return response.data;
}

export async function createWorkspace(payload: { name: string }) {
  const { data: repsonse } = await teamManagementClient.post(
    "/workspaces",
    payload
  );

  return repsonse.data;
}

export async function deleteWorkspace(wsId: string) {
  const { data: response } = await teamManagementClient.delete(
    `workspaces/${wsId}`
  );

  return response.data;
}

export async function archiveWorkspace(wsId: string) {
  const { data: response } = await teamManagementClient.post(
    `workspaces/${wsId}/archive`
  );

  return response.data;
}

export async function getWorkspaceAccess(
  wsId: string
): Promise<WorkspaceAccessModel | null> {
  const { data: response } = await teamManagementClient.get(
    `workspaces/${wsId}/get-access`
  );

  return response.data;
}
