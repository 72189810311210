import {
  Chart,
  ReportMeasurement,
  ReportView,
  RevenueType,
} from "common/constants";
import lodash from "lodash";
import { OverviewChartData, OverviewChartModel } from "models/report";
import { ReportSettings } from "slices/models/reportSliceModel";
import { getLastItemOfEachGroup } from "utils/report";

const MEASUREMENT_PROPERTIES: {
  [key: string]: keyof OverviewChartData;
} = {
  [`${ReportMeasurement.ARR}${Chart.OverviewRevenue}`]: "ArrEndingBalance",
  [`${ReportMeasurement.ARR}${Chart.OverviewGrowthRate}`]: "ArrGrowthRate",
  [`${ReportMeasurement.ARR}${Chart.OverviewGrossRet}`]: "ArrGrossRetention",
  [`${ReportMeasurement.ARR}${Chart.OverviewNetRet}`]: "ArrNetRetention",
  [`${ReportMeasurement.CARR}${Chart.OverviewRevenue}`]: "CArrEndingBalance",
  [`${ReportMeasurement.CARR}${Chart.OverviewGrowthRate}`]: "CArrGrowthRate",
  [`${ReportMeasurement.CARR}${Chart.OverviewGrossRet}`]: "CArrGrossRetention",
  [`${ReportMeasurement.CARR}${Chart.OverviewNetRet}`]: "CArrNetRetention",
  [`${ReportMeasurement.MRR}${Chart.OverviewRevenue}`]: "ArrEndingBalance",
  [`${ReportMeasurement.MRR}${Chart.OverviewGrowthRate}`]: "ArrGrowthRate",
  [`${ReportMeasurement.MRR}${Chart.OverviewGrossRet}`]: "ArrGrossRetention",
  [`${ReportMeasurement.MRR}${Chart.OverviewNetRet}`]: "ArrNetRetention",
  [`${ReportMeasurement.CMRR}${Chart.OverviewRevenue}`]: "CArrEndingBalance",
  [`${ReportMeasurement.CMRR}${Chart.OverviewGrowthRate}`]: "CArrGrowthRate",
  [`${ReportMeasurement.CMRR}${Chart.OverviewGrossRet}`]: "CArrGrossRetention",
  [`${ReportMeasurement.CMRR}${Chart.OverviewNetRet}`]: "CArrNetRetention",
};

export function getPreviousMonth(period: string) {
  const [year, month] = period.split("-");
  if (month === "01") {
    return `${Number.parseInt(year) - 1}-12`;
  }
  return `${year}-${(Number.parseInt(month) - 1).toString().padStart(2, "0")}`;
}

export function getNextMonth(period: string) {
  const [year, month] = period.split("-");
  if (month === "12") {
    return `${Number.parseInt(year) + 1}-01`;
  }
  return `${year}-${(Number.parseInt(month) + 1).toString().padStart(2, "0")}`;
}

function getValueByMeasurement(
  data: OverviewChartData,
  measurement: ReportMeasurement,
  chart: Chart
) {
  const value = data[MEASUREMENT_PROPERTIES[`${measurement}${chart}`]];
  switch (measurement) {
    case ReportMeasurement.ARR:
    case ReportMeasurement.CARR: {
      return value as number;
    }
    case ReportMeasurement.MRR:
    case ReportMeasurement.CMRR: {
      if (chart !== Chart.OverviewRevenue) {
        return value as number;
      }
      return (value as number) / 12;
    }
  }
}

export function filterDateRange(
  data: OverviewChartData[],
  reportSettings: ReportSettings
) {
  const minDate = reportSettings.filters.minDate!.slice(0, -3);
  const maxDate = reportSettings.filters.maxDate!.slice(0, -3);

  const startIndex = lodash.findIndex(data, (x) => x.Month >= minDate);
  const endIndex = lodash.findLastIndex(data, (x) => x.Month <= maxDate);

  if (startIndex === -1 || endIndex === -1) return [];

  return data.slice(startIndex, endIndex + 1);
}

function buildChartModels(
  months: lodash.Dictionary<OverviewChartData[]>,
  reportSettings: ReportSettings,
  chartType: Chart,
  segView?: ReportView | null
): OverviewChartModel[] {
  let index = 0;
  const models = Object.entries(months).map(([key, values]) => {
    let xValue = "";
    if (reportSettings.params.revenueType === RevenueType.Monthly)
      xValue = values[0].Month;
    if (reportSettings.params.revenueType === RevenueType.Quarterly)
      xValue = values[0].DISPLAYQTR;
    if (reportSettings.params.revenueType === RevenueType.Yearly)
      xValue = values[0].FISCALYR;

    const model: OverviewChartModel = {
      month: key,
      index,
      xValue,
    } as OverviewChartModel;
    index++;

    for (const value of values) {
      const name = segView === null ? "Total" : value.SelectedSegmentation!;
      model[name] = getValueByMeasurement(
        value,
        reportSettings.measurement,
        chartType
      );
    }
    return model;
  });

  return getLastItemOfEachGroup(
    lodash.sortBy(models, (model) => model.month),
    "xValue"
  );
}

export function computeRevenueChartData(
  months: lodash.Dictionary<OverviewChartData[]>,
  reportSettings: ReportSettings,
  segView?: ReportView | null
): OverviewChartModel[] {
  return buildChartModels(
    months,
    reportSettings,
    Chart.OverviewRevenue,
    segView
  );
}

export function computeGrowthRateChartData(
  months: lodash.Dictionary<OverviewChartData[]>,
  reportSettings: ReportSettings,
  segView?: ReportView | null
): OverviewChartModel[] {
  return buildChartModels(
    months,
    reportSettings,
    Chart.OverviewGrowthRate,
    segView
  );
}

export function computeGrossRetentionChartData(
  months: lodash.Dictionary<OverviewChartData[]>,
  reportSettings: ReportSettings,
  segView?: ReportView | null
): OverviewChartModel[] {
  return buildChartModels(
    months,
    reportSettings,
    Chart.OverviewGrossRet,
    segView
  );
}

export function computeNetRetentionChartData(
  months: lodash.Dictionary<OverviewChartData[]>,
  reportSettings: ReportSettings,
  segView?: ReportView | null
): OverviewChartModel[] {
  return buildChartModels(
    months,
    reportSettings,
    Chart.OverviewNetRet,
    segView
  );
}
