import { useState, useCallback, useEffect } from "react";

export default function useChartAreaHover<DataModel>() {
  const [hoveringArea, setHoveringArea] = useState<keyof DataModel | null>();
  const [overTimeout, setOverTimeout] = useState<number | undefined>();
  const [outTimeout, setOutTimeout] = useState<number | undefined>();

  useEffect(() => {
    return () => {
      overTimeout && window.clearTimeout(overTimeout);
      outTimeout && window.clearTimeout(outTimeout);
    };
  }, []);

  const handleMouseOver = useCallback(
    (areaKey: keyof DataModel) => {
      window.clearTimeout(outTimeout);
      setOverTimeout(
        window.setTimeout(() => {
          setHoveringArea(areaKey);
        }, 150)
      );
    },
    [outTimeout]
  );

  const handleMouseOut = useCallback(() => {
    window.clearTimeout(overTimeout);
    setOutTimeout(
      window.setTimeout(() => {
        setHoveringArea(undefined);
      }, 150)
    );
  }, [overTimeout]);

  return {
    hoveringArea,
    handleMouseOver,
    handleMouseOut,
  };
}
