import { Divider, Stack } from "@mui/material";
import Logo from "components/Logo";
import React from "react";
import RevPulse from "assets/images/RevPulseLogo.png";

import MappingSteps from "./MappingSteps";

export default function MappingLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Stack
      style={{
        borderRadius: "8px",
        padding: "32px",
        minHeight: "calc(100vh - 50px)",
        background: "var(--mapping-layout-bg)",
      }}
    >
      <div
        style={{
          display: "flex",
          paddingBottom: "32px",
          flexDirection: "column",
          alignItems: "center",
          gap: "32px",
          flex: "1 0 0",
          alignSelf: "stretch",
          borderRadius: "8px",
          background: "#FFF",
        }}
      >
        <div
          style={{
            background:
              "linear-gradient(270deg, var(--palette-primary-light, #0097A7) 0%, #293252 100%)",
            height: "16px",
            width: "100%",
            borderRadius: "8px 8px 0 0",
          }}
        ></div>
        <div
          style={{
            display: "flex",
            padding: "0px 24px 16px 24px",
            flexDirection: "column",
            alignItems: "center",
            alignSelf: "stretch",
          }}
        >
          <div
            style={{
              display: "flex",
              padding: "4px",
              alignItems: "center",
              alignSelf: "stretch",
            }}
          >
            <Logo
              bgColor="transparent"
              path={RevPulse}
              description="RevPulse Report"
            />
          </div>
          <MappingSteps />
        </div>
        <Divider variant="middle" sx={{ width: "100%" }} />
        {children}
      </div>
    </Stack>
  );
}
