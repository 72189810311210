/* eslint-disable no-unused-vars */
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import getWorkspace from "common/client/teamManagementClient";
import store, { useSelector } from "common/store";
import PageNotFound from "components/Router/PageNotFound";
import { FileModel } from "models/fileModel";
import { WorkspaceModel } from "models/workspace";
import { MutableRefObject, useRef } from "react";
import { Outlet, Params, useLoaderData } from "react-router-dom";
import { getDashboard } from "services/dashboardManagerService";
import { getReportData } from "services/reportService";
import { selectRoleByTeam } from "slices/teamSlice";
import { setDashboardId } from "slices/reportSlice";

import DashboardAppBar from "./components/DashboardAppBar";
import DashboardNavBar from "./components/DashboardNavBar";
import { ReportRouteContext } from "./types";
import { DRAWER_WIDTH } from "./constants";

interface PageData {
  error?: boolean;
  dashboard?: FileModel;
  workspace?: WorkspaceModel;
}

export default function DashboardPage() {
  const role = useSelector(selectRoleByTeam);
  const { isLoading: isLoadingTeam } = useSelector((state) => state.team);
  const data = useLoaderData() as PageData;

  const toolbarRef = useRef<HTMLDivElement | null>(null);
  const sectionRefs: { [key: string]: MutableRefObject<HTMLElement | null> } = {
    SectionRollForward: useRef<HTMLElement | null>(null),
    SectionGrowthRate: useRef<HTMLElement | null>(null),
    SectionCountChanges: useRef<HTMLElement | null>(null),
    SectionTopCustomers: useRef<HTMLElement | null>(null),
    SectionCohorts: useRef<HTMLElement | null>(null),
    SectionContractRenewal: useRef<HTMLElement | null>(null),
    SectionARRChangesOverview: useRef<HTMLElement | null>(null),
  };

  const routeContext: ReportRouteContext = {
    sectionRefs,
    workspace: data.workspace,
  };

  function handleOnItemClick(key: string): void {
    const heightOffset =
      toolbarRef.current!.offsetTop + toolbarRef.current!.clientHeight;
    const elementOffset = sectionRefs[key]?.current?.offsetTop ?? 0;
    window.scroll({ behavior: "smooth", top: elementOffset - heightOffset });
  }

  if (isLoadingTeam) return null;
  if (!Number.isFinite(role)) {
    return <PageNotFound />;
  }
  return (
    <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
      <CssBaseline />
      <DashboardAppBar workspace={data.workspace} />
      <DashboardNavBar width={DRAWER_WIDTH} onItemClick={handleOnItemClick} />
      <Box
        component="main"
        display="flex"
        height="100vh"
        px={3}
        marginLeft={`${DRAWER_WIDTH}px`}
        flexDirection="column"
        justifyContent="stretch"
      >
        <Toolbar sx={{ marginTop: 2 }} ref={toolbarRef} />
        <Outlet context={routeContext} />
      </Box>
    </Box>
  );
}

export async function loader({
  params,
}: {
  params: Params;
}): Promise<PageData> {
  const currentDbId = store.getState().report.reportData.file?.dashboardId;
  const dashboardId = params.dashboardId ?? "";
  const workspaceId = params.wsId ?? "";

  store.dispatch(setDashboardId(dashboardId));

  const response = await Promise.all([
    getDashboard(workspaceId, dashboardId),
    getWorkspace(workspaceId),
  ]);

  if (dashboardId !== currentDbId) store.dispatch(getReportData(response[0]));

  return { dashboard: response[0], workspace: response[1] };
}
