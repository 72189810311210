import {
  Stack,
  Container,
  Box,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ExcelExportingView() {
  const { t } = useTranslation();

  return (
    <Stack justifyContent="center" alignItems="center" gap={5} maxWidth="451px">
      <Container
        sx={{
          width: "150px",
          height: "150px",
          background: "var(--loading-url)",
        }}
      />
      <Box sx={{ width: "100%" }}>
        <LinearProgress variant="determinate" color="primary" value={50} />
      </Box>
      <Typography variant="h5" fontSize={"23px"} textAlign="center">
        {t("Dashboard.ExcelExport.ExportingMsg")}
      </Typography>
    </Stack>
  );
}
