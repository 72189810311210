import {
  AssignmentIndOutlined,
  DeleteOutlined,
  GridViewOutlined,
} from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import i18n from "common/i18n";
import { useSelector } from "common/store";
import PatternCard from "components/Card/PatternCard";
import PageNotFound from "components/Router/PageNotFound";
import DataGrid from "components/Table/DataGrid";
import { ApiErrorModel } from "models/apiErrorModel";
import {
  Collection,
  DEFAULT_PAYLOAD,
  FilterCollectionPayload,
  SearchCollectionPayload,
} from "models/collection";
import { Role } from "models/team";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  SearchTeamMemberModel,
  getTeamMembersAsync,
  removeTeamMemberAsync,
  updateTeamMemberRoleAsync,
} from "services/teamService";
import { selectRoleByTeam } from "slices/teamSlice";
import { useAlert } from "utils/hook/useAlert";
import useFetch from "utils/hook/useFetch";
import { useToast } from "utils/hook/useNotification";
import { formatUserDisplayName } from "utils/format";

import InviteTeamMemberModal from "./InviteTeamMemberModal";
import WorkspacesAccessModal from "./WorkspacesAccessModal";

const getDefaultLogoDisplay = (user: SearchTeamMemberModel) => {
  const { fullName, firstName, lastName } = user;
  if (fullName === " " || fullName === "User Default") return "";
  return `${firstName[0]}${lastName[0]}`;
};

const columns: GridColDef<SearchTeamMemberModel>[] = [
  {
    field: "FullName",
    valueGetter: (_, row) => formatUserDisplayName(row),
    headerName: i18n.t("TeamUsers_DataGrid_ColHeader_Name"),
    minWidth: 400,
    flex: 2,
    renderCell: (params) => (
      <Stack direction="row" alignItems="center">
        <Avatar>{getDefaultLogoDisplay(params.row)}</Avatar>
        <Box
          component="section"
          title={params.value}
          sx={{
            marginLeft: 3,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          {params.value}
        </Box>
      </Stack>
    ),
  },
  {
    field: "TenantRole",
    valueGetter: (_, row) => {
      return row.tenantRole === Role.Admin ? "Admin" : "Member";
    },
    headerName: i18n.t("TeamUsers_DataGrid_ColHeader_Role"),
    minWidth: 150,
    flex: 1,
  },
  {
    field: "UpdatedAt",
    valueGetter: () => "N/A",
    headerName: i18n.t("TeamUsers_DataGrid_ColHeader_LastActivity"),
    minWidth: 150,
    flex: 1,
  },
  {
    field: "WorkspaceCount",
    valueGetter: (_, row) => row.workspaceCount,
    headerName: i18n.t("TeamUsers_DataGrid_ColHeader_WorkspaceAccess"),
    minWidth: 150,
    flex: 1,
  },
];

export default function TeamUserListPage() {
  const { t } = useTranslation();
  const { showAlert } = useAlert();
  const { showError, showSuccess } = useToast();
  const params = useParams();
  const currentTeamId = params.id;

  const {
    teams,
    isSystemAdmin,
    isLoading: isLoadingTeam,
  } = useSelector((state) => state.team);

  const teamName = useMemo(() => {
    return teams?.find((team) => team.id === currentTeamId)?.name || "";
  }, [teams, currentTeamId]);

  const [dataPayload, setDataPayload] = useState<SearchCollectionPayload>({
    ...DEFAULT_PAYLOAD,
    orderBy: "UpdatedAt",
    order: "desc",
  });
  const [selectedItem, setSelectedItem] = useState<SearchTeamMemberModel>();
  const [isInviteMemberModalOpen, setIsInviteMemberModalOpen] = useState(false);

  const {
    isLoading,
    data,
    error,
    execute: getTeamMembers,
  } = useFetch<
    SearchCollectionPayload,
    Collection<SearchTeamMemberModel>,
    ApiErrorModel
  >(
    {
      fn: (payload) => getTeamMembersAsync(params.id!, payload),
    },
    [params.id]
  );

  const {
    execute: executeRemoveFromTeam,
    error: removeFromTeamError,
    data: removeFromTeamSuccess,
    isLoading: isRemovingFromTeam,
  } = useFetch<{ userId: string; teamId: string }, string>({
    fn: ({ userId, teamId }) => removeTeamMemberAsync(teamId, userId),
  });

  const {
    execute: executeChangeToAdmin,
    error: changeToAdminError,
    data: changeToAdminSuccess,
    isLoading: isChangeToAdminLoading,
  } = useFetch<{ teamId: string; userId: string; role: string }, string>({
    fn: ({ teamId, userId, role }) =>
      updateTeamMemberRoleAsync(teamId, userId, role),
  });

  const role = useSelector(selectRoleByTeam);

  function handleOnRequestData(payload: FilterCollectionPayload): void {
    const textFilter = payload.filters as { text: string };
    delete payload.filters;
    getTeamMembers({ ...payload, text: textFilter?.text });
    setDataPayload({ ...payload, text: textFilter?.text });
  }

  function handleRemoveFromTeamClick(userId: string, teamId: string) {
    showAlert({
      title: t("TeamSettings.TeamUsers.Remove.Title"),
      message: t("TeamSettings.TeamUsers.Remove.Message"),
      confirmLabel: t("TeamSettings.TeamUsers.Remove.Confirm"),
      cancelLabel: t("Common.Cancel"),
      onConfirm: () => executeRemoveFromTeam({ userId, teamId }),
    });
  }

  function handleChangeToTeamAdminClick(teamId: string, userId: string) {
    showAlert({
      title: t("TeamSettings.TeamUsers.ChangeToAdmin.Title"),
      message: t("TeamSettings.TeamUsers.ChangeToAdmin.Message"),
      confirmLabel: t("Common.Confirm"),
      cancelLabel: t("Common.Cancel"),
      onConfirm: () =>
        executeChangeToAdmin({ teamId, userId, role: Role[Role.Admin] }),
    });
  }

  useEffect(() => {
    getTeamMembers(dataPayload);
  }, [params.id]);

  useEffect(() => {
    if (isRemovingFromTeam === true) return;
    if (removeFromTeamSuccess !== undefined) {
      showSuccess({ message: t("TeamSettings.TeamUsers.Remove.Success") });
      getTeamMembers(dataPayload);
    } else if (removeFromTeamError !== undefined) {
      showError({ message: t("TeamSettings.TeamUsers.Remove.Failed") });
    }
  }, [isRemovingFromTeam]);

  useEffect(() => {
    if (isChangeToAdminLoading === true) return;
    if (changeToAdminSuccess !== undefined) {
      showSuccess({
        message: t("TeamSettings.TeamUsers.ChangeToAdmin.Success"),
      });
      getTeamMembers(dataPayload);
    } else if (changeToAdminError !== undefined) {
      showError({
        message: t("TeamSettings.TeamUsers.ChangeToAdmin.Failed"),
      });
    }
  }, [isChangeToAdminLoading]);

  if (isLoadingTeam) return null;
  if (error?.status === 403) return <PageNotFound />;
  if (!isSystemAdmin && role !== Role.Admin) return <PageNotFound />;
  return (
    <>
      <PatternCard
        size="medium"
        title={t("TeamSettingLayout_CardTitle", { team: teamName })}
        actionTitle={t("TeamSettingLayout_CardAction")}
        onActionClick={() => setIsInviteMemberModalOpen(true)}
      >
        <DataGrid
          columns={columns}
          data={data}
          isLoading={isLoading || isRemovingFromTeam || isChangeToAdminLoading}
          payload={dataPayload}
          hasSearchBox={true}
          hasFilterButton={false}
          rowSelection={false}
          isRowSelectable={false}
          onRequestData={handleOnRequestData}
          rowContextMenuOptions={(row) => {
            const isDisableChangeToAdmin = row.tenantRole === Role.Admin;
            const items = [
              {
                id: "manage-access",
                icon: <GridViewOutlined />,
                title: t("TeamSettings.TeamUsers.RowContextMenu.ManageAccess"),
                onClick: () => setSelectedItem(row),
              },
              { id: "divider-1" },
              {
                id: "change-to-admin",
                icon: <AssignmentIndOutlined />,
                title: i18n.t(
                  "TeamSettings.TeamUsers.RowContextMenu.ChangeToAdmin"
                ),
                disabled: isDisableChangeToAdmin,
                onClick: () => handleChangeToTeamAdminClick(params.id!, row.id),
              },
              { id: "divider-2" },
              {
                id: "remove-from-team",
                icon: <DeleteOutlined />,
                title: i18n.t(
                  "TeamSettings.TeamUsers.RowContextMenu.RemoveFromTeam"
                ),
                color: "error.main",
                onClick: () =>
                  handleRemoveFromTeamClick(row.id, params.id || ""),
              },
            ];
            if (row.tenantRole === Role.Admin) {
              return items.filter(
                (item) => item.id !== "divider-1" && item.id !== "manage-access"
              );
            }
            return items;
          }}
        />
        <WorkspacesAccessModal
          user={selectedItem}
          onClose={() => {
            getTeamMembers(dataPayload);
            setSelectedItem(undefined);
          }}
        />
      </PatternCard>
      <InviteTeamMemberModal
        open={isInviteMemberModalOpen}
        teamName={teamName}
        onClose={() => {
          getTeamMembers(dataPayload);
          setIsInviteMemberModalOpen(false);
        }}
      />
    </>
  );
}
