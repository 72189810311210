import React, { useEffect, useRef } from "react";
import { Container } from "@mui/material";
import { upload, uploaded } from "services/dashboardManagerService";
import { parseCSVHeader } from "utils/parseCSVHeader";
import { BlockBlobClient } from "@azure/storage-blob";
import { FileStatus, ROUTE_PATHS } from "common/constants";
import { isFileUTF8 } from "utils/checkUTF8Incode";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Logo from "components/Logo";
import { useTranslation } from "react-i18next";
import { WORKSPACE_ROUTES } from "containers/Workspace/config";
import RevPulse from "assets/images/RevPulseLogo.png";

import { useImportDataContext } from "../ImportDataContext";

import ImportFailed from "./ImportFailed";
import Importing from "./Importing";
import ImportContent from "./ImportContent";

export default function Import() {
  const { t } = useTranslation();
  const { wsId } = useParams();
  const [searchParams] = useSearchParams();
  const abortController = useRef<AbortController | null>(null);
  const [isImporting, setIsImporting] = React.useState(false);
  const [isImportSuccess, setIsImportSuccess] = React.useState(false);
  const [importProgress, setImportProgress] = React.useState(0);
  const navigate = useNavigate();
  const [error, setError] = React.useState({
    title: "",
    description: "",
  });
  const context = useImportDataContext();
  const params = useParams();

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isImporting) {
        event.preventDefault();
        event.returnValue = t("workspace.import.cancelConfirm");
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isImporting]);

  useEffect(() => {
    const handleOffline = () => {
      setTimeout(() => {
        if (isImporting && !window.navigator.onLine) {
          setIsImporting(false);
          abortController.current?.abort();
          setError({
            title: t("workspace.import.errorTitle"),
            description: t("workspace.import.errorDescription"),
          });
        }
      }, 3000);
    };

    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("offline", handleOffline);
    };
  }, [isImporting]);

  async function importFile(workspaceId: string, file: File) {
    // Get CSV header
    const content = await parseCSVHeader(file);
    const header = content.split(",");
    let data: { sasUrl: string; blobFileName: string } = {
      sasUrl: "",
      blobFileName: "",
    };
    try {
      data = await upload(workspaceId, file.name);
      // Upload to azure storage
      const blobServiceClient = new BlockBlobClient(data.sasUrl);
      abortController.current = new AbortController();
      const { signal } = abortController.current;
      await blobServiceClient.uploadData(file, {
        onProgress: (progress) => {
          const percentCompleted = Math.round(
            (progress.loadedBytes / file.size) * 100
          );
          setImportProgress(percentCompleted);
        },
        abortSignal: signal,
      });
      // Mark file upload completed
      const { dashboardId: dbId } = await uploaded(
        params.wsId!,
        file.name,
        data.blobFileName,
        FileStatus.DataImported,
        header,
        file.size,
        searchParams.get("dashboardId") || undefined
      );
      setIsImportSuccess(true);
      navigate(
        `/${ROUTE_PATHS.TEAMS}/${params.id}/${ROUTE_PATHS.WORKSPACES}/${params.wsId}/${dbId}/${WORKSPACE_ROUTES.IMPORT_SUCCESS}`
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error_: any) {
      if (error_.name === "AbortError") {
        setIsImportSuccess(false);
        setIsImporting(false);
      } else {
        setError({
          title: t("workspace.import.errorTitle"),
          description: t("workspace.import.errorDescription"),
        });
      }
    } finally {
      setIsImporting(false);
      // eslint-disable-next-line unicorn/no-null
      abortController.current = null;
      setImportProgress(0);
    }
  }
  async function cancelUpload() {
    if (
      abortController.current &&
      confirm(t("workspace.import.cancelConfirmUpload"))
    ) {
      setImportProgress(0);
      abortController.current.abort();
      context.alert(t("workspace.import.cancelled"));
    }
  }

  function startOver() {
    setIsImportSuccess(false);
    setImportProgress(0);
    setError({
      title: "",
      description: "",
    });
  }
  async function handleImport(workspaceId: string, file: File | null) {
    if (!file) return;
    if (file.size < 1024) {
      setError({
        title: t("workspace.import.errorNoDataTitle"),
        description: t("workspace.import.errorNoDataDescription"),
      });
      return;
    }
    const isUTF8 = await isFileUTF8(file);
    if (!isUTF8) {
      setError({
        title: t("workspace.import.errorNotUTF8Title"),
        description: t("workspace.import.errorNotUTF8Description"),
      });
      return;
    }
    setIsImporting(true);
    await importFile(workspaceId, file);
  }
  return (
    <>
      <Logo
        bgColor="transparent"
        path={RevPulse}
        description="RevPulse Report"
      />
      <Container
        sx={{
          width: "650px",
          margin: "auto",
        }}
      >
        {!isImporting && !isImportSuccess && !error.title && (
          <ImportContent
            handleImport={(file: File | null) => {
              handleImport(wsId || "", file);
            }}
          />
        )}
        {isImporting && (
          <Importing cancelUpload={cancelUpload} progress={importProgress} />
        )}
        {error.title && <ImportFailed error={error} startOver={startOver} />}
      </Container>
    </>
  );
}
