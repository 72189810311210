import { GridSortDirection } from "@mui/x-data-grid/models";

export const PAGESIZE_OPTIONS = [10, 25, 50, 100];
export const DEFAULT_PAGESIZE = PAGESIZE_OPTIONS[0];

export interface Sorting {
  orderBy?: string | null;
  order?: GridSortDirection;
}

export interface Filter {
  filters?: object;
}

export interface Paging {
  page: number;
  pageSize: number;
}

export interface Collection<Data> {
  data: Data[];
  pagination?: {
    total: number;
    page: number;
    pageSize: number;
  };
}

export interface GetCollectionPayload extends Sorting, Paging {}

export interface SearchCollectionPayload extends Sorting, Paging {
  text?: string;
}

export interface FilterCollectionPayload extends Filter, Sorting, Paging {}

export const DEFAULT_PAYLOAD: Paging = {
  page: 0,
  pageSize: DEFAULT_PAGESIZE,
};
