import { createAppThunk } from "common/appThunk";
import { administrationClient } from "common/client/administrationClient";
import { teamServiceClient } from "common/client/teamManagementClient";
import { ApiErrorModel } from "models/apiErrorModel";
import {
  Collection,
  GetCollectionPayload,
  SearchCollectionPayload,
} from "models/collection";
import { Role, TeamModel } from "models/team";
import { buildQueryParams } from "utils/httpClientUtils";

export interface SearchTeamMemberModel {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  tenantRole: Role;
  workspaceCount: number;
}

export const getTeamsByUser = createAppThunk<{
  isSystemAdmin: boolean;
  teams: TeamModel[];
}>("teams/getTeamsByUser", async () => {
  const promises = [];
  promises.push(
    administrationClient.get("profile/teams-access"),
    administrationClient.get("profile/teams")
  );
  try {
    const [response, teamsResp] = await Promise.all(promises);
    const teams = teamsResp.data.data;
    return {
      isSystemAdmin: response.data.data.isSuperAdmin,
      teams,
    };
  } catch (error) {
    if ((error as ApiErrorModel).errorCode) {
      throw (error as ApiErrorModel).errorCode;
    }
    throw error;
  }
});

export async function getTeamMembersAsync(
  teamId: string,
  query: SearchCollectionPayload
): Promise<Collection<SearchTeamMemberModel>> {
  const queryUri = buildQueryParams(query);
  const { data: response } = await teamServiceClient.get(
    `teams/${teamId}/members?${queryUri}`
  );

  return response.data;
}

export interface SearchMemberWorkspacePayload {
  teamId: string;
  userId: string;
  query: GetCollectionPayload & { isAssigned: boolean };
}

export interface SearchMemberWorkspaceModel {
  userId: string;
  tenantId: string;
  workspaceId: string;
  createdAt: string;
  workspaceName: string;
  roleName: string;
}

export async function getMemberWorkspacesAsync(
  payload: SearchMemberWorkspacePayload
): Promise<Collection<SearchMemberWorkspaceModel>> {
  const queryUri = buildQueryParams(payload.query);
  const { data: response } = await teamServiceClient.get(
    `teams/${payload.teamId}/members/${payload.userId}/workspaces?${queryUri}`
  );

  return response.data;
}

export async function updateTeamMemberRoleAsync(
  teamId: string,
  userId: string,
  tenantRole: string
) {
  const { data: response } = await teamServiceClient.patch(
    `teams/${teamId}/members/${userId}/role`,
    { tenantRole }
  );

  return response;
}

export async function updateWorkspaceRolesAsync(
  teamId: string,
  userId: string,
  workspaces: { workspaceId: string; workspaceRole: string }[]
) {
  const { data: response } = await teamServiceClient.patch(
    `teams/${teamId}/members/${userId}/workspaces/roles`,
    { workspaces }
  );
  return response;
}

export async function inviteTeamMembersAsync(
  teamId: string,
  emails: string[],
  tenantRole: string
) {
  const { data: response } = await teamServiceClient.post(
    `teams/${teamId}/members/batch`,
    { emails, tenantRole }
  );
  return response;
}

export async function inviteWorkspaceMembersAsync(
  teamId: string,
  emails: string[],
  workspaces: { workspaceId: string; workspaceRole: string }[]
) {
  const { data: response } = await teamServiceClient.post(
    `teams/${teamId}/workspaces/members/batch`,
    { emails, workspaces }
  );

  return response;
}

export async function removeTeamMemberAsync(teamId: string, userId: string) {
  const { data: response } = await teamServiceClient.delete(
    `teams/${teamId}/members/${userId}`
  );

  return response;
}

export async function removeWorkspaceMemberAsync(
  teamId: string,
  workspaceId: string,
  userId: string
) {
  const { data: response } = await teamServiceClient.delete(
    `teams/${teamId}/workspaces/${workspaceId}/members/${userId}`
  );

  return response;
}
