import LineBarComposedChart from "components/Charts/LineBarComposedChart";
import { ChartDatum } from "components/Charts/model";
import VerticalAxisTick from "components/Charts/VerticalAxisTick";
import { SingleValueTooltip } from "components/ChartTooltip";
import { ContractRenewalCategoryModel } from "models/report";
import { formatCurrencyValue, getPeriodAxisLabel } from "utils/format";

import CustomYAxisTick from "./CustomYAxisTick";

const bars: ChartDatum<ContractRenewalCategoryModel>[] = [
  {
    key: "Expiring ACV",
    color: "var(--color-positive-02)",
  },
  {
    key: "Lost ACV",
    color: "var(--color-negative-05)",
  },
  {
    key: "Downsell ACV",
    color: "var(--color-negative-14)",
  },
  {
    key: "Upsell ACV",
    color: "var(--color-negative-03)",
  },
];

interface Props {
  height: string | number;
  data: ContractRenewalCategoryModel[];
}

export default function ContractRenewalDetailChart(props: Props) {
  return (
    <LineBarComposedChart
      xAxisKey="xValue"
      bars={bars}
      data={props.data}
      height={props.height}
      width="100%"
      xAxisProps={{
        interval: 0,
        tick: ({ payload, ...remains }) => (
          <VerticalAxisTick
            value={getPeriodAxisLabel(
              payload.value,
              props.data.map((x) => x.xValue)
            )}
            {...remains}
          />
        ),
      }}
      yAxisProps={{
        tick: ({ payload, ...remains }) => (
          <CustomYAxisTick
            value={formatCurrencyValue(payload.value)}
            isRoot={payload.value === 0}
            {...remains}
          />
        ),
      }}
      margin={{ top: 0, left: 24, right: 24, bottom: 16 }}
      renderTooltip={(payload) =>
        SingleValueTooltip("xValue", payload, (value) =>
          formatCurrencyValue(value as number)
        )
      }
    />
  );
}
