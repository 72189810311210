import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { KeyValue } from "models/common";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  title: string;
  value?: KeyValue[];
  options: KeyValue[];
  onSelect: (selected: KeyValue[]) => void;
}

export default function FilterMultipleSelect(props: Props) {
  const { t } = useTranslation();
  const allOption = { key: "all", value: t("Common.All") };

  const data = [allOption, ...props.options];
  const [selectedValues, setSelectedValues] = useState<
    { key: string | number; value: string }[]
  >([allOption]);

  const selectedAll = useMemo(() => {
    const length = selectedValues.filter((x) => x.key !== "all").length;
    if (length === 0) return "unchecked";
    if (length === props.options.length) return "checked";
    return "indeterminate";
  }, [selectedValues]);

  function handleOnAllOptionClick(): void {
    if (selectedAll === "checked") {
      setSelectedValues([allOption]);
      props.onSelect([]);
    } else {
      setSelectedValues(data);
      props.onSelect(data.filter((x) => x.key !== "all"));
    }
  }

  function handleOnChange(newValues: KeyValue[]) {
    if (newValues.length === 0) setSelectedValues([allOption]);
    else setSelectedValues(newValues);
    props.onSelect(newValues.filter((x) => x.key !== "all"));
  }

  useEffect(() => {
    const value = props.value ?? [];
    if (value.length === 0) setSelectedValues([allOption]);
    else if (value.length === props.options.length) setSelectedValues(data);
    else setSelectedValues(value);
  }, [props.value]);

  return (
    <Autocomplete
      multiple
      size="small"
      sx={{
        "& .Mui-focused #input-text-all": {
          display: "none", // to hide the all text when searching
        },
      }}
      options={data}
      disableCloseOnSelect
      getOptionLabel={(option) => option.value}
      value={selectedValues}
      isOptionEqualToValue={(option, value) => option.key === value.key}
      onChange={(_, newValues) => handleOnChange(newValues)}
      renderTags={(value, getTagProps) => {
        const tags = value.filter((x) => x.key !== "all");
        const numTags = tags.length;
        const limitTags = 1;

        if (selectedAll !== "indeterminate")
          return <div id="input-text-all">All</div>;

        return (
          <>
            {tags.slice(0, limitTags).map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                size="small"
                key={index}
                label={option.value}
                onDelete={undefined}
              />
            ))}

            {numTags > limitTags && ` +${numTags - limitTags}`}
          </>
        );
      }}
      renderOption={(xprops, option, { selected }) => {
        const { key, ...optionProps } = xprops;
        const style = {
          ...optionProps.style,
          backgroundColor:
            selectedAll === "checked"
              ? optionProps.style?.backgroundColor
              : "transparent",
        };

        return option.value === "All" ? (
          <li
            {...optionProps}
            key={key}
            style={style}
            onClick={handleOnAllOptionClick}
          >
            <Checkbox
              disableRipple
              checked={selectedAll === "checked"}
              indeterminate={selectedAll === "indeterminate"}
            />
            <span style={{ whiteSpace: "nowrap" }}>{option.value}</span>
          </li>
        ) : (
          <li key={key} {...optionProps}>
            <Checkbox disableRipple checked={selected} />
            <span style={{ whiteSpace: "nowrap" }}>{option.value}</span>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} size="medium" label={props.title} />
      )}
    />
  );
}
