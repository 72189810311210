import LineBarComposedChart from "components/Charts/LineBarComposedChart";
import { ChartDatum } from "components/Charts/model";
import VerticalAxisTick from "components/Charts/VerticalAxisTick";
import { SingleValueTooltip } from "components/ChartTooltip";
import { CustomerByChangeCategoryChartModel } from "models/report";
import { getCustomerCountValue, getPeriodAxisLabel } from "utils/format";
import { useTranslation } from "react-i18next";

import CustomYAxisTick from "./CustomYAxisTick";

const bars: ChartDatum<CustomerByChangeCategoryChartModel>[] = [
  {
    key: "Beginning Count",
    color: "var(--color-positive-02)",
  },
  {
    key: "New Customer",
    color: "var(--color-positive-09)",
  },
  {
    key: "Customer Reactivation",
    color: "var(--indigo-02)",
  },
  {
    key: "Lost Customer",
    color: "var(--color-negative-05)",
  },
];

interface Props {
  height: string | number;
  data: CustomerByChangeCategoryChartModel[];
}

export default function CountChangesChart(props: Props) {
  const { t } = useTranslation();

  return (
    <LineBarComposedChart
      xAxisKey="xValue"
      bars={bars}
      line={{ key: "Ending Count" }}
      data={props.data}
      height={props.height}
      width="100%"
      xAxisProps={{
        interval: 0,
        tick: ({ payload, ...remains }) => (
          <VerticalAxisTick
            value={getPeriodAxisLabel(
              payload.value,
              props.data.map((x) => x.xValue)
            )}
            {...remains}
          />
        ),
      }}
      yAxisProps={{
        tick: ({ payload, ...remains }) => (
          <CustomYAxisTick
            value={getCustomerCountValue(payload.value)}
            isRoot={payload.value === 0}
            {...remains}
          />
        ),
      }}
      margin={{ top: 0, left: 24, right: 24, bottom: 16 }}
      renderTooltip={(payload) =>
        SingleValueTooltip("xValue", payload, (value) =>
          t("ChartTooltip.CustomerCount", {
            value: getCustomerCountValue(value as number),
          })
        )
      }
    />
  );
}
