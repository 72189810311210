import { Box } from "@mui/material";
import { useOutletContext } from "react-router-dom";

import DashboardToolbar from "./components/DashboardToolbar";
import classes from "./index.module.css";
import SectionCohorts from "./sections/SectionCohorts";
import SectionCountChanges from "./sections/SectionCountChanges";
import SectionOverview from "./sections/SectionOverview";
import SectionRollForward from "./sections/SectionRollForward";
import SectionTopCustomers from "./sections/SectionTopCustomers";
import SectionContractRenewal from "./sections/SectionContractRenewal";
import SectionARRChangesOverview from "./sections/SectionARRChangesOverview";
import { ReportRouteContext } from "./types";

export default function ReportsContainer() {
  const { sectionRefs, workspace } = useOutletContext<ReportRouteContext>();

  return (
    <>
      <DashboardToolbar workspace={workspace} />
      <section ref={sectionRefs.SectionARRChangesOverview}>
        <Box marginBottom={3}>
          <SectionARRChangesOverview />
        </Box>
      </section>
      <section
        className={classes.sectionRollForward}
        ref={sectionRefs.SectionRollForward}
      >
        <Box marginBottom={3}>
          <SectionRollForward />
        </Box>
      </section>
      <section ref={sectionRefs.SectionGrowthRate}>
        <Box marginBottom={3}>
          <SectionOverview />
        </Box>
      </section>
      <section
        className={classes.sectionCountChanges}
        ref={sectionRefs.SectionCountChanges}
      >
        <Box marginBottom={3}>
          <SectionCountChanges />
        </Box>
      </section>
      <section ref={sectionRefs.SectionTopCustomers}>
        <Box marginBottom={3}>
          <SectionTopCustomers />
        </Box>
      </section>
      <section ref={sectionRefs.SectionCohorts}>
        <Box marginBottom={3}>
          <SectionCohorts />
        </Box>
      </section>
      <section ref={sectionRefs.SectionContractRenewal}>
        <Box marginBottom={3}>
          <SectionContractRenewal />
        </Box>
      </section>
    </>
  );
}
