import type { TooltipProps } from "recharts";
import type {
  ValueType,
  NameType,
  Payload,
} from "recharts/types/component/DefaultTooltipContent";
import { Paper, Box, Typography } from "@mui/material";
import { ReactNode } from "react";

interface ChartTooltipProps extends TooltipProps<ValueType, NameType> {
  hoveringDataKey?: string | null;
  children: (payload: Payload<ValueType, NameType>[]) => ReactNode;
  onHoveringChange?: (payload?: Payload<ValueType, NameType>[]) => void;
}

export default function ChartTooltip({
  active,
  hoveringDataKey,
  payload,
  children,
  onHoveringChange,
}: ChartTooltipProps) {
  const hoveringData =
    hoveringDataKey === null
      ? payload ?? []
      : payload?.filter((data) => data.dataKey === hoveringDataKey) ?? [];

  if (active && hoveringData.length > 0) {
    onHoveringChange && onHoveringChange(hoveringData);

    return (
      <Paper
        sx={(theme) => ({
          borderRadius: 0.5,
          padding: "4px 8px",
          backgroundColor: theme.palette.grey[700],
          color: theme.palette.primary.contrastText,
          position: "relative",
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {hoveringData && children(hoveringData)}
        </Box>
      </Paper>
    );
  } else {
    onHoveringChange && onHoveringChange();
  }
  return null;
}

export function SingleValueTooltip(
  nameKey: string,
  payload: Payload<ValueType, NameType>[],
  formatter: (value?: ValueType) => string
) {
  const item = payload.at(0);
  return (
    <Box>
      <Typography>{item?.name}</Typography>
      <Typography>{item?.payload[nameKey]}</Typography>
      <Typography>{formatter(item?.value)}</Typography>
    </Box>
  );
}

export function MultiValueTooltip(
  nameKey: string,
  payload: Payload<ValueType, NameType>[],
  formatter: (value?: ValueType) => string
) {
  const item = payload.at(0);
  return (
    <Box>
      <Typography>{item?.payload[nameKey]}</Typography>
      {payload.map((x) => (
        <Typography key={x.dataKey}>
          {x?.name}: {formatter(x?.value)}
        </Typography>
      ))}
    </Box>
  );
}
