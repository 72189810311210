import {
  Paper,
  Table as MuiTable,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useEffect, useMemo, useRef, useState } from "react";

import { TableProps } from "./models";

export default function Table<Data>({
  data,
  columns,
  rowKey,
  stickyHeader = false,
  sx,
  headerSx,
  bodySx,
  footerSx,
  subRows,
  alternateRowColors = true,
}: TableProps<Data>) {
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>(
    {}
  );
  const hasFooter = useMemo(() => {
    return columns.some((col) => col.footer);
  }, [columns]);

  const tableRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollLeft = tableRef.current.scrollWidth;
    }
  }, [tableRef.current]);

  return (
    <Paper sx={{ width: "100%", overflow: "auto", boxShadow: "none" }}>
      <TableContainer sx={sx} ref={tableRef}>
        <MuiTable stickyHeader={stickyHeader} size="small">
          <TableHead sx={headerSx}>
            <TableRow>
              {columns.map((col) => {
                const sxProps = { ...col.sx, ...col.headerSx };
                return (
                  <TableCell key={col.header} sx={sxProps}>
                    {col.header}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody sx={bodySx}>
            {data.map((item, index) => {
              const category = item[rowKey] as string;
              const hasSubRows =
                subRows && subRows[category] && subRows[category].length > 0;

              if (hasSubRows) {
                const expandable =
                  subRows[category] && subRows[category].length > 0;

                return (
                  <>
                    <TableRow
                      key={category}
                      onClick={() =>
                        setExpandedRows({
                          ...expandedRows,
                          [category]: !expandedRows[category],
                        })
                      }
                    >
                      {columns.map((col, colIndex) => (
                        <TableCell
                          key={`${col.header}-${item[rowKey]}`}
                          sx={[
                            (theme) => ({
                              backgroundColor:
                                alternateRowColors && index % 2 === 1
                                  ? theme.palette.grey[300]
                                  : theme.palette.background.paper,
                              display: colIndex === 0 ? "flex" : undefined,
                              justifyContent: "space-between",
                            }),
                            ...(Array.isArray(col.sx) ? col.sx : [col.sx]),
                          ]}
                        >
                          {col.render(item)}
                          {colIndex === 0 &&
                            expandable &&
                            (expandedRows[category] ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            ))}
                        </TableCell>
                      ))}
                    </TableRow>
                    {expandable &&
                      expandedRows[category] &&
                      subRows[category].map((row) => {
                        return (
                          <TableRow key={row[rowKey] as string}>
                            {columns.map((col, subColIndex) => (
                              <TableCell
                                key={`${col.header}-${row[rowKey]}`}
                                sx={[
                                  (theme) => ({
                                    backgroundColor:
                                      alternateRowColors && index % 2 === 1
                                        ? theme.palette.grey[300]
                                        : theme.palette.background.paper,
                                    paddingLeft:
                                      subColIndex === 0
                                        ? "40px !important"
                                        : undefined,
                                  }),
                                  ...(Array.isArray(col.sx)
                                    ? col.sx
                                    : [col.sx]),
                                ]}
                              >
                                {col.render(row)}
                              </TableCell>
                            ))}
                          </TableRow>
                        );
                      })}
                  </>
                );
              }
              return (
                <TableRow key={item[rowKey] as string}>
                  {columns.map((col) => (
                    <TableCell
                      key={`${col.header}-${item[rowKey]}`}
                      sx={[
                        (theme) => ({
                          backgroundColor:
                            alternateRowColors && index % 2 === 1
                              ? theme.palette.grey[300]
                              : theme.palette.background.paper,
                        }),
                        ...(Array.isArray(col.sx) ? col.sx : [col.sx]),
                      ]}
                    >
                      {col.render(item)}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
          {hasFooter && (
            <TableFooter sx={footerSx}>
              <TableRow>
                {columns.map((col) => (
                  <TableCell
                    variant="head"
                    key={col.header}
                    sx={[
                      (theme) => ({
                        background: theme.palette.background.paper,
                      }),
                      ...(Array.isArray(col.sx) ? col.sx : [col.sx]),
                      ...(Array.isArray(col.footerSx)
                        ? col.footerSx
                        : [col.footerSx]),
                    ]}
                  >
                    {col.footer}
                  </TableCell>
                ))}
              </TableRow>
            </TableFooter>
          )}
        </MuiTable>
      </TableContainer>
    </Paper>
  );
}
