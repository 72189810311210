import { ApiErrorModel } from "models/apiErrorModel";
import { WorkspaceModel } from "models/workspace";
import { ROUTE_PATHS } from "common/constants";
import extractIdFromPathname from "utils/extractIdFromPathname";

import HttpClient from "./httpClient";

const apiVersion = process.env.REACT_APP_API_VERSION;
const baseUrl = process.env.REACT_APP_API_TEAM_MANAGEMENT_BASEURL;

export const teamManagementClient = new HttpClient(
  `${baseUrl}${apiVersion}`,
  () => ({
    teamId: extractIdFromPathname(ROUTE_PATHS.TEAMS),
  })
);

export const teamServiceClient = new HttpClient(`${baseUrl}${apiVersion}`);

export default async function getWorkspace(workspaceId: string) {
  try {
    const { data: response } = await teamManagementClient.get(
      `workspaces/${workspaceId}`
    );
    return response.data as WorkspaceModel;
  } catch (error) {
    if ((error as ApiErrorModel).errorCode) {
      throw (error as ApiErrorModel).errorCode;
    }
    throw error;
  }
}
