import { Link, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridSortDirection } from "@mui/x-data-grid";
import {
  PAGE_SIZE_OPTIONS,
  ROUTE_PATHS,
  WorkspaceEventType,
} from "common/constants";
import { WorkspaceModel } from "models/workspace";
import { useMemo, useRef } from "react";
import { NavLink, useNavigation, useSearchParams } from "react-router-dom";
import { getRelativeTime } from "utils/getRelativeTime";
import { getLastWsActivity } from "utils/getLastWsActivity";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

import { DataType } from "../types";

import ContextMenu from "./ContextMenu";

export const getDashboardStatus = (
  status: WorkspaceEventType,
  t: TFunction
) => {
  if (!status) return "";
  return status === WorkspaceEventType.GenerateReport
    ? t("ReportStatus.Ready")
    : t("ReportStatus.InProgress");
};

export function ListView({
  data,
  onOptionSelect,
}: {
  data: DataType;
  onOptionSelect: (optionId: string, row: WorkspaceModel) => void;
}) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = useNavigation();

  const rowCountRef = useRef(data.pagination.total || 0);

  const rowCount = useMemo(() => {
    if (data.pagination.total !== undefined) {
      rowCountRef.current = data.pagination.total;
    }
    return rowCountRef.current;
  }, [data.pagination.total]);

  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: "name",
        headerName: "Name",
        renderCell: (params) => (
          <NavLink to={`${params.row.id}/${ROUTE_PATHS.WORKSPACES_HOME}`}>
            <Link underline="hover">{params.row.name}</Link>
          </NavLink>
        ),
      },
      {
        field: "lastProcessedFileStatus",
        headerName: "Status",
        renderCell: (params) => (
          <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
            {getDashboardStatus(params.row.dashboardEventType, t)}
          </Typography>
        ),
      },
      {
        field: "lastActivity",
        headerName: "Last Activity",
        renderCell: (params) => {
          return getLastWsActivity(params.row, t);
        },
      },
      {
        field: "createdAt",
        headerName: "Created",
        valueGetter: (value: string) => getRelativeTime(value),
      },
      {
        field: "members",
        headerName: "Members",
        valueGetter: (value: number) => value,
      },
      {
        field: "actions",
        type: "actions",
        resizable: false,
        renderCell: (params) => (
          <ContextMenu
            onOptionSelect={(id) => onOptionSelect(id, params.row)}
          />
        ),
      },
    ];
  }, []);

  return (
    <>
      <DataGrid
        sx={{ border: "none", width: "100%" }}
        rows={data.data}
        rowCount={rowCount}
        columns={columns}
        disableColumnMenu
        disableRowSelectionOnClick
        loading={state === "loading"}
        slotProps={{
          loadingOverlay: {
            variant: "linear-progress",
            noRowsVariant: "skeleton",
          },
        }}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        paginationMode="server"
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: searchParams.has("pageSize")
                ? Number.parseInt(searchParams.get("pageSize")!)
                : PAGE_SIZE_OPTIONS[0],
              page: searchParams.has("page")
                ? Number.parseInt(searchParams.get("page")!)
                : 0,
            },
          },
        }}
        onPaginationModelChange={({ pageSize, page }) => {
          setSearchParams((prev) => {
            return {
              ...Object.fromEntries(prev),
              page: page.toString(),
              pageSize: pageSize.toString(),
            };
          });
        }}
        sortingMode="server"
        sortModel={[
          {
            field: searchParams.get("orderBy") || "createdAt",
            sort: (searchParams.get("order") as GridSortDirection) || "desc",
          },
        ]}
        onSortModelChange={(model) => {
          setSearchParams((prev) => {
            const prevClone = Object.fromEntries(prev);
            if (prevClone.orderBy === "createdAt" && model.length === 0) {
              return {
                ...prevClone,
                page: "0",
                orderBy: "createdAt",
                order: "asc",
              };
            }
            if (model.length === 0 && prevClone.orderBy) {
              delete prevClone.orderBy;
              delete prevClone.order;
              return {
                ...prevClone,
                page: "0",
              };
            }
            return {
              ...prevClone,
              page: "0",
              orderBy: model[0]?.field || "createdAt",
              order: model[0]?.sort || "desc",
            };
          });
        }}
        autosizeOnMount
        autoHeight={true}
        autosizeOptions={{
          columns: [
            "name",
            "lastProcessedFileStatus",
            "lastActivity",
            "createdAt",
            "members",
          ],
          includeHeaders: true,
          expand: true,
        }}
      />
    </>
  );
}
