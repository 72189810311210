/* eslint-disable unicorn/no-empty-file */
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { CustomerLevel, ReportMeasurement } from "common/constants";
import { useDispatch, useReportSelector } from "common/store";
import GradientCard from "components/Card/GradientCard";
import lodash from "lodash";
import { CustomersByChangeCategoryResponse } from "models/report";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCountChangeChart } from "services/reportService";
import { setChartShouldFetch } from "slices/reportSlice";
import { computeReportData } from "utils/chartUtils/customersByChangeCategory";
import useDidUpdateEffect from "utils/hook/useDidUpdateEffect";

import CountChangesChart from "../charts/CountChangesChart";
import CountChangesTable from "../charts/CountChangesTable";
import {
  WrapperContainer,
  chartMinHeight,
} from "../components/CommonComponents";

export default function SectionCountChanges() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { reportData, reportSettings } = useReportSelector();
  const chartData = useReportSelector().chartData["count-change"];

  const [viewToggle, setViewToggle] = useState(["chart"]);
  const [cMeasure, setCMeasure] = useState<boolean>(false);

  useEffect(() => {
    if (reportData.isLoading || !chartData.shouldFetch) return;
    dispatch(getCountChangeChart());
  }, [reportData.isLoading, chartData.shouldFetch]);

  useDidUpdateEffect(() => {
    dispatch(setChartShouldFetch(["count-change"]));
  }, [
    cMeasure,
    reportSettings.filters.customers,
    reportSettings.filters.segmentCustomers,
    reportSettings.filters.segmentProducts,
  ]);

  useEffect(() => {
    const hasCMeasure =
      reportSettings.measurement === ReportMeasurement.CARR ||
      reportSettings.measurement === ReportMeasurement.CMRR;
    setCMeasure(hasCMeasure);
  }, [reportSettings.measurement]);

  // Report data with filters applied, ready to put into chart
  const displayableReportData = useMemo(() => {
    if (chartData.data === undefined) return [];
    if (!reportSettings.filters.minDate || !reportSettings.filters.maxDate)
      return [];

    const computedData = chartData.data as CustomersByChangeCategoryResponse[];

    const startIndex = lodash.findIndex(
      computedData,
      (x) => x.Month >= reportSettings.filters.minDate!.slice(0, -3)
    );
    const endIndex = lodash.findLastIndex(
      computedData,
      (x) => x.Month <= reportSettings.filters.maxDate!.slice(0, -3)
    );

    if (startIndex === -1 || endIndex === -1) return [];

    const filtered = computedData.slice(startIndex, endIndex + 1);

    return computeReportData(filtered, reportSettings);
  }, [
    chartData.isLoading,
    reportSettings.measurement,
    reportSettings.filters.minDate,
    reportSettings.filters.maxDate,
  ]);

  return (
    <>
      <Typography variant="h6" marginBottom={2} color="var(--text-secondary)">
        {t("Dashboard.Charts.SectionCountChanges")}
      </Typography>
      {reportSettings.params.customerLevel === CustomerLevel.CustomerProduct ? (
        <GradientCard
          title={t("Dashboard.Charts.CountChangesTitle")}
          onZoomClick={(x) => console.log(x)}
          onMoreClick={() => console.log("more")}
        >
          {t("Common.ComingSoon")}
        </GradientCard>
      ) : (
        <GradientCard
          title={t("Dashboard.Charts.CountChangesTitle")}
          onViewToggle={(x) => setViewToggle(x)}
          onMoreClick={() => console.log("more")}
        >
          <WrapperContainer
            isLoading={reportData.isLoading || chartData.isLoading}
            error={chartData.error || reportData.error}
            isNoData={displayableReportData.length === 0}
          >
            {viewToggle.includes("chart") && (
              <CountChangesChart
                height={chartMinHeight}
                data={displayableReportData}
              />
            )}
            {viewToggle.includes("data") && (
              <Box sx={{ marginTop: 3, width: "100%" }}>
                <CountChangesTable data={displayableReportData} />
              </Box>
            )}
          </WrapperContainer>
        </GradientCard>
      )}
    </>
  );
}
