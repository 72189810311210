import { isBoolean, isNumber } from "lodash";

export function buildQueryParams(params: object): string {
  const queryParams = new URLSearchParams();

  for (const [key, value] of Object.entries(params)) {
    if (isNumber(value) || isBoolean(value) || value) {
      queryParams.append(key, String(value));
    }
  }

  return queryParams.toString();
}
