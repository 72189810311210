import { ReportView, MEASUREMENT_PROPERTIES } from "common/constants";
import { RevenueByCustomer, RevenueByCustomerChartModel } from "models/report";
import lodashGroupBy from "lodash/groupBy";
import lodashSortBy from "lodash/sortBy";
import { ReportSettings } from "slices/models/reportSliceModel";

export function computeReportData(
  data: RevenueByCustomer[],
  reportSettings: ReportSettings,
  segView?: ReportView | null
): { [month: string]: RevenueByCustomerChartModel[] } {
  const nameKey =
    segView === ReportView.TopCustomerType ? "CustomerValue" : "ProductValue";

  const valueKey = MEASUREMENT_PROPERTIES[reportSettings.measurement];

  const result: { [month: string]: RevenueByCustomerChartModel[] } = {};
  const months = lodashGroupBy(data, (item) => item.Month);
  for (const [month, monthData] of Object.entries(months)) {
    const customers = lodashGroupBy(monthData, (item) => item.Customer);
    const models = Object.entries(customers).map(([key, values]) => {
      const model: RevenueByCustomerChartModel = {
        customer: key,
      } as RevenueByCustomerChartModel;

      for (const value of values) {
        const name = segView === null ? "Total" : value[nameKey]!;
        model[name] = (value[valueKey] as number) || 0;
      }
      return model;
    });
    result[month] = lodashSortBy(models, (model) => {
      const values = Object.entries(model).filter((x) => x[0] !== "customer");
      const totalValue = values.reduce(
        (total, x) => total + (x[1] as number),
        0
      );
      return -totalValue;
    });
  }
  return result;
}
