import { CheckCircleOutlined, Download } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import Modal from "components/Modal";
import { useTranslation } from "react-i18next";
import { useDashboardExportData } from "slices/excelExportSlice";

interface ExcelExportedModalProps {
  open: boolean;
  dashboardId: string;
  onClose: () => void;
}

export default function ExcelExportedModal({
  open,
  dashboardId,
  onClose,
}: ExcelExportedModalProps) {
  const { t } = useTranslation();
  const exportData = useDashboardExportData(dashboardId!);

  const handleDownloadClick = () => {
    if (exportData?.currentDownloadLink) {
      window.open(exportData.currentDownloadLink, "_blank")?.focus();
    }
  };
  const handleCloseClick = () => {
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Stack direction="column" alignItems="center">
        <CheckCircleOutlined sx={{ width: 120, height: 120 }} color="success" />
        <Typography variant="h3" textAlign="center">
          {t("Dashboard.ExcelExport.ExcelExportedModal.Message")}
        </Typography>
        <Stack
          direction="row"
          gap={1}
          width="100%"
          marginTop={3}
          marginBottom={2}
        >
          <Button
            variant="outlined"
            sx={{ borderRadius: "var(--capsuleRadius)" }}
            onClick={handleDownloadClick}
          >
            <Download />
            {t("Dashboard.ExcelExport.ExcelExportedModal.DownloadBtn")}
          </Button>
          <Button variant="text" onClick={handleCloseClick}>
            {t("Dashboard.ExcelExport.ExcelExportedModal.CloseBtn")}
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
}
