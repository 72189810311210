import fileUpload from "slices/fileUploadSlice";
import file from "slices/dashboardSlice";
import report from "slices/reportSlice";
import team from "slices/teamSlice";
import excelExport from "slices/excelExportSlice";

export const reducer = {
  fileUpload,
  file,
  report,
  team,
  excelExport,
};
