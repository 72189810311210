import moment from "moment";
import { Typography, Box } from "@mui/material";
import { MoreHorizOutlined, Add, DeleteOutlined } from "@mui/icons-material";
import { GridColDef } from "@mui/x-data-grid";
import i18n from "common/i18n";
import IconMenu from "components/Menu/IconMenu";
import RoleSelect from "components/RoleSelect";
import { SearchMemberWorkspaceModel } from "services/teamService";
import { Role } from "models/team";

export interface EditingRoles {
  [wsId: string]: Role;
}

function getRoleValue(
  workspace: SearchMemberWorkspaceModel,
  editingRoles: EditingRoles
): Role {
  return (
    editingRoles[workspace.workspaceId] ??
    Role[workspace.roleName as keyof typeof Role] ??
    Role.ReportViewer
  );
}

function getContextMenuOptions(
  viewingAssigned: boolean,
  editingRoles: EditingRoles,
  row: SearchMemberWorkspaceModel,
  onAddClick: (row: SearchMemberWorkspaceModel, role: Role) => void,
  onRemoveClick: (row: SearchMemberWorkspaceModel) => void
) {
  if (viewingAssigned) {
    return [
      {
        id: "remove",
        icon: <DeleteOutlined />,
        title: "Remove",
        color: "error.main",
        onClick: () => onRemoveClick(row),
      },
    ];
  }
  return [
    {
      id: "add",
      icon: <Add />,
      title: "Add",
      onClick: () => onAddClick(row, getRoleValue(row, editingRoles)),
    },
  ];
}

export function getColumns(
  editingRoles: EditingRoles,
  viewingAssigned: boolean,
  shouldDisableEditRole: boolean,
  onAddClick: (row: SearchMemberWorkspaceModel, role: Role) => void,
  onChangeRole: (row: SearchMemberWorkspaceModel, role: Role) => void,
  onRemoveClick: (row: SearchMemberWorkspaceModel) => void
): GridColDef<SearchMemberWorkspaceModel>[] {
  return [
    {
      field: "WorkspaceName",
      valueGetter: (_, row) => row.workspaceName,
      headerName: i18n.t("Common.Workspace"),
      renderCell: (params) => (
        <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
          <Typography
            variant="body2"
            color="primary"
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
      flex: 2,
    },
    {
      field: "CreatedAt",
      valueGetter: (_, row) => moment(row.createdAt).format("MM/DD/YY"),
      headerName: "Created",
      flex: 1,
    },
    {
      field: "RoleName",
      headerName: i18n.t("Common.Role"),
      renderCell: ({ row }) => (
        <RoleSelect
          value={getRoleValue(row, editingRoles)}
          onRoleChange={(role) => onChangeRole(row, role)}
          disabled={shouldDisableEditRole}
        />
      ),
      flex: 1,
    },
    {
      field: "WorkspaceId",
      width: 32,
      renderHeader: () => null,
      renderCell: ({ row }) => (
        <IconMenu
          icon={<MoreHorizOutlined />}
          options={getContextMenuOptions(
            viewingAssigned,
            editingRoles,
            row,
            onAddClick,
            onRemoveClick
          )}
        />
      ),
      align: "right",
      sortable: false,
    },
  ];
}
