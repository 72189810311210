import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { RevenueType } from "common/constants";
import { useDispatch, useReportSelector } from "common/store";
import PatternCard from "components/Card/PatternCard";
import DatePicker from "components/Inputs/DatePicker";
import TimeFrameSelect from "components/TimeFrameSelect";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext, useParams } from "react-router-dom";
import {
  getReportExportHistory,
  getReportExportProcess,
} from "services/reportService";
import { useDashboardExportData } from "slices/excelExportSlice";
import useFetch from "utils/hook/useFetch";
import { useToast } from "utils/hook/useNotification";

import ExcelExportingView from "./components/ExcelExportingView";
import ExportHistoryTable from "./components/ExportHistoryTable";
import { DATE_PICKER_FORMAT } from "./constants";
import { ReportRouteContext } from "./types";

export default function ExcelExportView() {
  const params = useParams();
  const { t } = useTranslation();
  const { showError } = useToast();
  const { reportData, reportSettings } = useReportSelector();
  const dispatch = useDispatch();
  const { workspace } = useOutletContext<ReportRouteContext>();
  const exportData = useDashboardExportData(params.dashboardId!);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [timeFrame, setTimeFrame] = useState<RevenueType | "ytd">(
    RevenueType.Yearly
  );

  const {
    data: exportHistory,
    isLoading: isLoadingHistory,
    execute: fetchExportHistory,
  } = useFetch(
    {
      fn: async () =>
        reportData.file?.id
          ? getReportExportHistory(
              reportData.file?.id,
              params.id!,
              params.wsId!
            )
          : null,
      payload: true,
    },
    [reportData.file?.id]
  );

  const handleOnExcelWorkbookClick = () => {
    dispatch(
      getReportExportProcess({
        fileId: reportData.file!.id,
        measurement: reportSettings.measurement,
        analysisType: reportSettings.params.analysisType,
        threshold: reportSettings.params.reactivationThreshold,
        dashboardId: params.dashboardId!,
      })
    );
  };

  const handleStartDateChange = (date: moment.Moment | null) => {
    if (!date) {
      return;
    }
    setStartDate(date.format(DATE_PICKER_FORMAT));
  };

  const handleEndDateChange = (date: moment.Moment | null) => {
    if (!date) {
      return;
    }
    setEndDate(date.format(DATE_PICKER_FORMAT));
  };

  const handleTimeFrameChange = (value: RevenueType | "ytd") => {
    setTimeFrame(value);
  };

  useEffect(() => {
    if (exportData.isExporting) return;

    if (exportData.error) {
      showError({ message: exportData.error });
    } else if (exportData.currentDownloadLink) {
      fetchExportHistory(true);
    }
  }, [exportData.isExporting]);

  useEffect(() => {
    if (!startDate && reportSettings.filters.defaultMinDate) {
      setStartDate(reportSettings.filters.defaultMinDate);
    }
  }, [reportSettings.filters.defaultMinDate]);

  useEffect(() => {
    if (!endDate && reportSettings.filters.defaultMaxDate) {
      setEndDate(reportSettings.filters.defaultMaxDate);
    }
  }, [reportSettings.filters.defaultMaxDate]);

  return (
    <Stack
      height="100%"
      direction="column"
      justifyContent="stretch"
      marginBottom={3}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <Typography variant="h5" color="var(--text-secondary)">
          {t("Dashboard.ToolBar.Title", {
            name: workspace?.name || "<Workspace Name>",
          })}
        </Typography>
      </Stack>
      <Divider sx={{ marginBottom: 3 }} />
      <PatternCard
        title={t("Dashboard.ExcelExport.ExportViewTitle")}
        sx={{
          flex: exportData.isExporting ? 1 : undefined,
          display: "flex",
          flexDirection: "column",
          // Select card content div
          "& > div:nth-of-type(2)": { flex: 1, overflow: "scroll" },
        }}
      >
        {reportData.isLoading ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
            paddingY={7}
          >
            <CircularProgress />
          </Stack>
        ) : (!exportHistory || exportHistory.length === 0) &&
          exportData.isExporting ? (
          <Stack
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
          >
            <ExcelExportingView />
          </Stack>
        ) : (
          <Box sx={{ marginX: 7 }}>
            <Typography variant="h6" sx={{ marginBottom: 3 }}>
              {t("Dashboard.ExcelExport.FilterLabel")}
            </Typography>
            <Stack direction="row" justifyContent="space-between">
              <TimeFrameSelect
                value={timeFrame}
                onChange={handleTimeFrameChange}
                disabled
              />
              <Stack direction="column">
                <Typography variant="body1" sx={{ marginBottom: 2 }}>
                  {t("Dashboard.ExcelExport.DateRangeLabel")}
                </Typography>
                <Stack direction="row" alignItems="center">
                  <DatePicker
                    disabled
                    type="month"
                    label={t("Dashboard.ExcelExport.StartDate")}
                    value={moment(startDate)}
                    onChange={handleStartDateChange}
                  />
                  <Typography variant="body1" sx={{ marginX: 2 }}>
                    —
                  </Typography>
                  <DatePicker
                    disabled
                    type="month"
                    label={t("Dashboard.ExcelExport.EndDate")}
                    value={moment(endDate)}
                    onChange={handleEndDateChange}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Button
              variant="contained"
              disabled={
                !reportData.file?.id ||
                (!!exportHistory &&
                  exportHistory.length > 0 &&
                  exportData.isExporting)
              }
              onClick={handleOnExcelWorkbookClick}
              sx={{ marginTop: 3 }}
            >
              {exportHistory &&
              exportHistory.length > 0 &&
              exportData.isExporting
                ? t("Dashboard.ExcelExport.ExportBtnExporting")
                : t("Dashboard.ExcelExport.ExportButton")}
              {(reportData.isLoading ||
                (exportHistory &&
                  exportHistory.length > 0 &&
                  exportData.isExporting)) && (
                <CircularProgress size={20} sx={{ marginLeft: 1 }} />
              )}
            </Button>
            {!isLoadingHistory && exportHistory && exportHistory.length > 0 && (
              <Box>
                <Divider sx={{ marginY: 3 }} />
                <Typography variant="h6">
                  {t("Dashboard.ExcelExport.ExportHistoryTable.Header")}
                </Typography>
                <ExportHistoryTable rows={exportHistory} />
              </Box>
            )}
          </Box>
        )}
      </PatternCard>
    </Stack>
  );
}
