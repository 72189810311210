import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "common/config/msalConfig";
import { AlertDialogProvider } from "common/providers/AlertDialogContext";
import { AuthUserProvider } from "common/providers/AuthUserProvider";
import { ToastProvider } from "common/providers/NotificationContext";
import { router } from "components/Router";
import React from "react";
import { RouterProvider } from "react-router-dom";

const App: React.FC = () => {
  return (
    <AlertDialogProvider>
      <ToastProvider>
        <MsalProvider instance={new PublicClientApplication(msalConfig)}>
          <AuthUserProvider>
            <RouterProvider router={router} />
          </AuthUserProvider>
        </MsalProvider>
      </ToastProvider>
    </AlertDialogProvider>
  );
};

export default App;
