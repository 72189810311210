import { Avatar, Stack, Typography } from "@mui/material";

export interface LogoProps {
  avatarText?: string;
  description?: string;
  bgColor?: string;
  descriptionColor?: string;
  path?: string;
}

export default function Logo({
  avatarText,
  description,
  bgColor,
  descriptionColor,
  path,
}: LogoProps) {
  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      borderRadius={"shape.borderRadius"}
    >
      <Avatar
        sx={{
          bgcolor: bgColor || "primary.light",
          width: "24px",
          height: "24px",
          color: "#FFF",
          textAlign: "center",
          fontFeatureSettings: "'liga' off, 'clig' off",
          fontFamily: "Work Sans",
          fontSize: "10px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "10px",
        }}
        src={path}
      >
        {avatarText}
      </Avatar>
      <Typography
        variant="subtitle2"
        color={descriptionColor || "primary.dark"}
        sx={{
          padding: "3px 6px",
        }}
      >
        {description}
      </Typography>
    </Stack>
  );
}
