import { createTheme } from "@mui/material/styles";

import type {} from "@mui/x-data-grid/themeAugmentation";

export const theme = createTheme({
  cssVariables: true,
  palette: {
    primary: {
      main: "#03A9F4",
      dark: "#039BE5",
      light: "#29B6F6",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#00838F",
      dark: "#006064",
      light: "#0097A7",
    },
    action: {
      disabled: "rgba(0, 0, 0, 0.38)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
      hover: "rgba(0, 0, 0, 0.04)",
      active: "rgba(0, 0, 0, 0.56)",
    },
    success: {
      main: "#2E7D32",
      dark: "#1B5E20",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.60)",
    },
    error: {
      main: "#D32F2F",
      dark: "#C62828",
    },
    warning: {
      main: "#EF6C00",
    },
    info: {
      main: "#0288D1",
      contrastText: "#FFF",
    },
  },
  typography: {
    fontFamily: "Work Sans",
    h3: {
      fontSize: "48px",
      fontWeight: 400,
      lineHeight: "116.7%",
      fontFeatureSettings: "'liga' off, 'clig' off",
      fontStyle: "normal",
    },
    h4: {
      fontFamily: '"Work Sans"',
      fontSize: "34px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "123.5%",
      letterSpacing: "0.25px",
    },
    h5: {
      fontSize: "24px",
      fontWeight: 400,
      lineHeight: "133.4%",
      fontFeatureSettings: "'liga' off, 'clig' off",
      fontStyle: "normal",
    },
    h6: {
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "160%",
      fontFeatureSettings: "'liga' off, 'clig' off",
      fontStyle: "normal",
    },
    body1: {
      fontfeatureSettings: "'liga' off, 'clig' off",
      fontStyle: "normal",
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "150%",
      letterSpacing: "0.15px",
    },
    body2: {
      fontfeatureSettings: "'liga' off, 'clig' off",
      fontStyle: "normal",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "143%",
      letterSpacing: "0.17px",
    },
    caption: {
      fontFeatureSettings: "'liga' off, 'clig' off",
      fontFamily: "Roboto",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "166%",
      letterSpacing: "0.4px",
    },
    subtitle2: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "157%;",
      letterSpacing: "0.1px",
    },
    overline: {
      fontFeatureSettings: "'liga' off, 'clig' off",
      fontFamily: "Roboto",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "266%",
      letterSpacing: "1px",
      textTransform: "uppercase",
    },
  },
  shape: {
    borderRadius: 4,
  },
  spacing: 8,
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "& .MuiDataGrid-columnHeader:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-cell": {
            padding: "6px 10px",
            height: "auto",
            display: "flex",
            alignItems: "center",
            minWidth: 0,
          },
          "& .MuiDataGrid-cell .MuiStack-root": {
            minWidth: 0,
          },
        },
        columnHeaderTitle: {
          fontSize: "14px",
          fontFamily: "Work Sans",
          fontWeight: 500,
          lineHeight: "171.429%",
          fontStyle: "normal",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        action: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => {
          if (ownerState.variant === "contained") {
            return {
              borderRadius: "var(--capsuleRadius, 4px)",
              background: ownerState.disabled
                ? "var(--btn-disabled, linear-gradient(270deg, rgba(0, 172, 193, 0.40) 0%, rgba(0, 87, 178, 0.40) 100%))"
                : "var(--btn-gradient, linear-gradient(270deg, #00ACC1 0%, #0057B2 100%))",
              boxShadow:
                "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
              color: "var(--primary-contrast, #FFF)",
              ":disabled": {
                color: "var(--primary-contrast, #FFF)",
              },
              fontFamily: '"Work Sans"',
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "24px",
              letterSpacing: "0.4px",
              padding: "8px 24px",
            };
          }
          if (ownerState.variant === "text") {
            return {
              padding: "8px 11px",
            };
          }
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          fontFamily: '"Work Sans"',
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "24px",
          letterSpacing: "0.17px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: '"Work Sans"',
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "24px",
          letterSpacing: "0.17px",
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          fontWeight: 700,
        },
      },
    },
    MuiAlert: {
      variants: [
        {
          props: { severity: "info" },
          style: {
            color: "#014361",
          },
        },
        {
          props: { severity: "error" },
          style: {
            backgroundColor: "#D32F2F",
            color: "#FFF",
          },
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 16,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "rgba(0, 0, 0, 0.60)",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          padding: 8,
        },
      },
    },
  },
});
