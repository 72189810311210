import { useReportSelector } from "common/store";
import Table from "components/Table";
import {
  CategoriesAndPeriodsTableModel,
  RevenueByChangeCategoryChartModel,
} from "models/report";
import {
  getRevenueByChangeCategoryTableColumns,
  getRevenueByChangeCategoryTableData,
} from "utils/chartUtils/revenueByChangeCategoryReport";

interface Props {
  data: RevenueByChangeCategoryChartModel[];
  subRows: { [categories: string]: CategoriesAndPeriodsTableModel[] };
}

export default function RollForwardTable(props: Props) {
  const { reportSettings } = useReportSelector();

  const columns = getRevenueByChangeCategoryTableColumns(props.data);

  const tableData = getRevenueByChangeCategoryTableData(
    props.data,
    reportSettings.params.customerLevel
  );

  return (
    <Table
      data={tableData}
      columns={columns}
      rowKey="category"
      bodySx={{ zIndex: 0, position: "relative" }}
      footerSx={{
        bottom: 0,
        position: "sticky",
        backgroundColor: "white",
      }}
      stickyHeader
      subRows={props.subRows}
    />
  );
}
