import { RollForwardCategory } from "models/report";
import { CustomerLevel } from "common/constants";

export const CUSTOMER_LEVEL_RF_CHART_BARS: RollForwardCategory[] = [
  "Beginning Balance",
  "New Customer",
  "Customer Reactivation",
  "Upsell",
  "Downsell",
  "Lost Customer",
  "Ending/Beginning Revenue Credits",
  "Cust Segment Migration",
];

export const CUSTOMER_PRODUCT_LEVEL_RF_CHART_BARS: RollForwardCategory[] = [
  "Beginning Balance",
  "New Customer",
  "Cross-Sell",
  "Lost Customer",
  "Lost Product",
  "Customer Reactivation",
  "Product Reactivation",
  "Upsell",
  "Downsell",
  "Ending/Beginning Revenue Credits",
  "Cust Segment Migration",
];

export const CATEGORIES_BY_CUSTOMER_LEVEL = {
  [CustomerLevel.Customer]: CUSTOMER_LEVEL_RF_CHART_BARS,
  [CustomerLevel.CustomerProduct]: CUSTOMER_PRODUCT_LEVEL_RF_CHART_BARS,
};

export const CATEGORIES_WITH_SUB_ROWS = new Set([
  "Cross-Sell",
  "Product Reactivation",
  "Lost Product",
]);
