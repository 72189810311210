import _lodashGet from "lodash/get";
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosResponseTransformer,
  AxiosError,
} from "axios";
import { ApiErrorModel } from "models/apiErrorModel";
import { getBearerToken } from "common/providers/AuthUserProvider";

export default class HttpClient {
  private readonly baseUrl: string;
  private readonly queryFn?: () => object;

  constructor(url: string, queryFn?: () => object) {
    this.baseUrl = url;
    this.queryFn = queryFn;
  }

  private transformResponse(
    input: string
  ): AxiosResponseTransformer | AxiosResponseTransformer[] {
    return JSON.parse(input);
  }

  private handleRequestError = (error: unknown): unknown => {
    if (
      error instanceof AxiosError &&
      error.response &&
      _lodashGet(error.response, "data.data")
    ) {
      return error.response.data.data as ApiErrorModel;
    }
    return error;
  };

  private client(header = {}): AxiosInstance {
    // cancelToken and source declaration
    const cancelTokenSource = axios.CancelToken.source();

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: getBearerToken(),
      ...header,
    };

    // axios client config
    const config: AxiosRequestConfig = {
      baseURL: this.baseUrl,
      cancelToken: cancelTokenSource.token,
      headers,
    };

    // axios client response transformer
    config.transformResponse = [
      (data: unknown) => {
        return data && typeof data === "string"
          ? this.transformResponse(data)
          : data;
      },
    ];

    // create axios client
    const client = axios.create(config);

    // interceptor to the error handler
    client.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        if (error.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        }
        if (error.status === 400) {
          throw error;
        }
      }
    );

    // interceptors to the tenancy ids
    if (this.queryFn) {
      client.interceptors.request.use((configReq) => {
        configReq.params = { ...configReq.params, ...this.queryFn?.() };
        for (const key in configReq.params) {
          if (configReq.url?.includes(key)) {
            delete configReq.params[key];
          }
        }
        return configReq;
      });

      client.interceptors.response.use((response) => {
        if (!response) return response;
        let teamId = response.config.params.teamId;
        if (!teamId) {
          try {
            const url = new URL(
              `${response.config.baseURL}${response.config.url}`
            );
            teamId = url.searchParams.get("teamId");
          } catch {
            console.error("Invalid URL");
          }
        }
        if (teamId && localStorage.getItem("lastTeamId") !== teamId) {
          const apiVersion = process.env.REACT_APP_API_VERSION;
          const baseUrl = process.env.REACT_APP_API_ADMIN_BASEURL;
          const administrationClient = new HttpClient(
            `${baseUrl}${apiVersion}`
          );
          try {
            administrationClient.put(`profile/teams/${teamId}/last-access`, {});
            localStorage.setItem("lastTeamId", teamId);
          } catch {
            console.error("Update last access failed");
          }
        }
        return response;
      });
    }
    return client;
  }

  /**
   *
   * @param url
   * @returns
   */
  public get(url: string): Promise<AxiosResponse> {
    return this.client().get(url);
  }

  /**
   *
   * @param url
   * @param payload
   * @returns
   */
  public async post<T>(
    url: string,
    payload?: T,
    headers = {}
  ): Promise<AxiosResponse> {
    try {
      const response = await this.client(headers).post(url, payload);
      return response;
    } catch (error) {
      throw this.handleRequestError(error);
    }
  }

  public async patch<T>(url: string, payload: T): Promise<AxiosResponse> {
    try {
      const response = await this.client().patch(url, payload);
      return response;
    } catch (error) {
      throw this.handleRequestError(error);
    }
  }

  public async put<T>(url: string, payload: T): Promise<AxiosResponse> {
    try {
      const response = await this.client().put(url, payload);
      return response;
    } catch (error) {
      throw this.handleRequestError(error);
    }
  }

  public async delete(url: string): Promise<AxiosResponse> {
    try {
      const response = await this.client().delete(url);
      return response;
    } catch (error) {
      throw this.handleRequestError(error);
    }
  }
}
