import Table from "components/Table";
import { ContractRenewalCategoryModel } from "models/report";
import {
  getContractRenewalDetailTableData,
  getContractRenewalDetailTableColumns,
} from "utils/chartUtils/contractRenewal";

interface Props {
  data: ContractRenewalCategoryModel[];
}

export default function ContractRenewalDetailTable(props: Props) {
  const columns = getContractRenewalDetailTableColumns(props.data);
  const tableData = getContractRenewalDetailTableData(props.data);

  return (
    <Table
      data={tableData}
      columns={columns}
      rowKey="category"
      bodySx={{ zIndex: 0, position: "relative" }}
      footerSx={{
        bottom: 0,
        position: "sticky",
        backgroundColor: "white",
      }}
      stickyHeader
    />
  );
}
