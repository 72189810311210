import {
  DISTINCT_COLORS,
  MEASUREMENT_PROPERTIES,
  PREDEFINED_COLORS,
  ReportMeasurement,
  ReportSegmentation,
  ReportView,
  RevenueType,
} from "common/constants";
import { t } from "i18next";
import lodashFind from "lodash/find";
import lodashFindLast from "lodash/findLast";
import lodashGroupBy from "lodash/groupBy";
import lodashSortBy from "lodash/sortBy";
import { KeyValue } from "models/common";
import { FileMappingObject } from "models/fileModel";
import {
  FiscalMonth,
  RevenueByCustomer,
  RevenueByCustomerChartModel,
  SegCustResponse,
  SegProdResponse,
} from "models/report";
import moment, { Moment } from "moment";
import { ReportData, ReportFilters } from "slices/models/reportSliceModel";

import { generateColors } from "./generateColors";

export function getSourceColumnName(
  mapping: Record<string, string | null> | null | undefined,
  displayKeyName: string
) {
  if (mapping == null) return "";
  return mapping[displayKeyName?.toLocaleLowerCase().replaceAll(" ", "")];
}

export function getFiscalMonth(
  mapping: Record<string, string | null> | null | undefined,
  revenueType: RevenueType = RevenueType.Monthly
): number | undefined {
  return revenueType === RevenueType.Monthly ||
    mapping == null ||
    !mapping.fiscalstartmonth ||
    mapping.fiscalstartmonth === "1"
    ? undefined
    : Number(mapping.fiscalstartmonth);
}

export function getLastMonthFromDataset(
  date: Moment,
  months: FiscalMonth[],
  revenueType: RevenueType = RevenueType.Monthly
) {
  if (revenueType === RevenueType.Monthly) return date.endOf("M");
  const month = lodashFind(months, (x) => x.Month >= date.format("YYYY-MM"));
  const key = revenueType === RevenueType.Yearly ? "FISCALYR" : "DISPLAYQTR";
  const value = month && month[key];
  const lastDate = lodashFindLast(months, (x) => x[key] === value);
  return lastDate ? moment(lastDate.Month).endOf("M") : undefined;
}

export function getCalendarStartDate(
  date: Moment,
  fiscalMonth: number = 1,
  revenueType: RevenueType = RevenueType.Monthly
) {
  const cloned = date.clone();
  const fiscalDate = cloned.add(fiscalMonth - 13, "month");
  if (revenueType === RevenueType.Monthly) {
    return fiscalDate.startOf("month");
  }
  if (revenueType === RevenueType.Quarterly) {
    return fiscalDate.startOf("quarter");
  }
  if (revenueType === RevenueType.Yearly) {
    return fiscalDate.startOf("year");
  }
  throw new Error("Function not implemented.");
}

export function getSegmentations(
  segmentationData: (SegCustResponse | SegProdResponse)[],
  mapping?: Record<string, string | null>
) {
  const groups = lodashGroupBy(segmentationData, (item) => item.TYPE);

  const segmentations = Object.entries(groups).map(([segmentName, data]) => ({
    segmentName,
    data,
  }));

  if (mapping) {
    return segmentations.sort((a, b) => {
      if (
        getSourceColumnName(mapping, a.segmentName)! <
        getSourceColumnName(mapping, b.segmentName)!
      ) {
        return -1;
      }
      return 1;
    });
  }

  return segmentations;
}

export function computeRevenueByCustomerReport(
  data: RevenueByCustomer[],
  measurement: ReportMeasurement,
  segmentationType: ReportSegmentation
): RevenueByCustomerChartModel[] {
  const customers = lodashGroupBy(data, (item) => item.Customer);
  const models = Object.entries(customers).map(([key, values]) => {
    const model: RevenueByCustomerChartModel = {
      customer: key,
    } as RevenueByCustomerChartModel;
    const nameKey =
      segmentationType === ReportSegmentation.Customer
        ? "CustomerValue"
        : "ProductValue";

    const valueKey = MEASUREMENT_PROPERTIES[measurement];

    for (const value of values) {
      model[value[nameKey]!] = (value[valueKey] as number) || 0;
    }
    return model;
  });

  return models;
}

export function computeRevenueByCustomerReportV2(
  data: RevenueByCustomer[],
  measurement: ReportMeasurement,
  segView: ReportView
): { [month: string]: RevenueByCustomerChartModel[] } {
  const nameKey =
    segView === ReportView.TopCustomerType ? "CustomerValue" : "ProductValue";

  const valueKey = MEASUREMENT_PROPERTIES[measurement];

  const result: { [month: string]: RevenueByCustomerChartModel[] } = {};
  const months = lodashGroupBy(data, (item) => item.Month);
  for (const [month, monthData] of Object.entries(months)) {
    const customers = lodashGroupBy(monthData, (item) => item.Customer);
    const models = Object.entries(customers).map(([key, values]) => {
      const model: RevenueByCustomerChartModel = {
        customer: key,
      } as RevenueByCustomerChartModel;

      for (const value of values) {
        model[value[nameKey]!] = (value[valueKey] as number) || 0;
      }
      return model;
    });
    result[month] = lodashSortBy(models, (model) => {
      const values = Object.entries(model).filter((x) => x[0] !== "customer");
      const totalValue = values.reduce(
        (total, x) => total + (x[1] as number),
        0
      );
      return -totalValue;
    });
  }
  return result;
}

export function getMappingRecord(mapping: FileMappingObject[]) {
  return Object.fromEntries(
    mapping.map((x) => [x.mdtName.toLowerCase(), x.sourceName])
  );
}

export function hasCustSegMapped(
  mapping: Record<string, string | null> | undefined
) {
  if (!mapping) return false;
  return Object.entries(mapping).some(
    (x) => x[0].startsWith("customerseg") && x[1] !== null
  );
}

export function hasProdSegMapped(
  mapping: Record<string, string | null> | undefined
) {
  if (!mapping) return false;
  return Object.entries(mapping).some(
    (x) => x[0].startsWith("product") && x[1] !== null
  );
}

export function hasFilteredOutAll(
  customerSegments: { [segment: string]: number[] },
  products: { [segment: string]: number[] },
  customers?: number[]
): boolean {
  if (customers && customers.length === 0) {
    return true;
  }
  if (Object.values(customerSegments).some((segment) => segment.length === 0)) {
    return true;
  }
  if (Object.values(products).some((segment) => segment.length === 0)) {
    return true;
  }
  return false;
}

export function filteredOtherSegments(
  currentSegment: string,
  segments: { [segment: string]: number[] },
  segmentsData: SegCustResponse[] | SegProdResponse[]
): boolean {
  const segmentations = getSegmentations(segmentsData);
  const filteredSegments = segmentations.filter(
    (s) => s.data.length !== segments[s.segmentName].length
  );

  return filteredSegments.some(
    (segment) => segment.segmentName !== currentSegment
  );
}

export function generateBarLegends(
  segView: "customer" | "product" | null,
  segment: string | null | undefined,
  options: ReportData,
  filter: ReportFilters
) {
  let segmentKeys: string[] = [];
  if (segView === null) {
    return [{ key: "Total", color: PREDEFINED_COLORS[0] }];
  }

  if (segView === "customer") {
    segmentKeys = options.segmentCustomers?.map((x) => x.segment) ?? [];
  }
  if (segView === "product") {
    segmentKeys = options.segmentProducts?.map((x) => x.segment) ?? [];
  }

  const segmentKey =
    segmentKeys.find(
      (x) => x.toLocaleLowerCase().replaceAll(" ", "") === segment
    ) ?? "";

  let filterValues: KeyValue[] | undefined = [];
  let optionValues: KeyValue[] | undefined = [];
  if (segView === "customer") {
    filterValues = filter.segmentCustomers[segmentKey];
    optionValues = options.segmentCustomers?.find(
      (x) => x.segment === segmentKey
    )?.data;
  }
  if (segView === "product") {
    filterValues = filter.segmentProducts[segmentKey];
    optionValues = options.segmentProducts?.find(
      (x) => x.segment === segmentKey
    )?.data;
  }

  let keyValues: KeyValue[] = [];
  keyValues =
    filterValues === undefined || filterValues.length === 0
      ? optionValues ?? []
      : filterValues ?? [];

  if (!keyValues) return [];

  const colors = DISTINCT_COLORS;

  if (keyValues.length > colors.length) {
    colors.push(...generateColors(keyValues.length - colors.length));
  }
  return keyValues.map((x, i) => ({ key: x.value, color: colors[i] }));
}

export function getDatePickerType(
  revenueType: RevenueType
): "month" | "quarter" | "year" {
  switch (revenueType) {
    case RevenueType.Monthly: {
      return "month";
    }
    case RevenueType.Quarterly: {
      return "quarter";
    }
    case RevenueType.Yearly: {
      return "year";
    }
    default: {
      throw new Error("Function not implemented.");
    }
  }
}

export function getLastItemOfEachGroup<T>(items: T[], groupBy: keyof T): T[] {
  const groupMap = new Map<unknown, T>();

  for (const item of items) {
    const key = item[groupBy];
    groupMap.set(key, item);
  }

  return [...groupMap.values()];
}

export function getDatePickerLabel(revenueType: RevenueType) {
  if (revenueType === RevenueType.Monthly) return t("Common.Month");
  if (revenueType === RevenueType.Quarterly) return t("Common.Quarter");
  if (revenueType === RevenueType.Yearly) return t("Common.Year");
  return "";
}

export function shouldRefetchRevenueData(
  measurement: ReportMeasurement,
  prevMeasurement: ReportMeasurement
) {
  if (
    prevMeasurement === ReportMeasurement.ARR ||
    prevMeasurement === ReportMeasurement.MRR
  ) {
    return (
      measurement !== ReportMeasurement.ARR &&
      measurement !== ReportMeasurement.MRR
    );
  }
  if (
    prevMeasurement === ReportMeasurement.CARR ||
    prevMeasurement === ReportMeasurement.CMRR
  ) {
    return (
      measurement !== ReportMeasurement.CARR &&
      measurement !== ReportMeasurement.CMRR
    );
  }
}
