import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { GridPagination } from "@mui/x-data-grid";
import type { GridColDef } from "@mui/x-data-grid/models/colDef/gridColDef";
import {
  AnalysisType,
  CustomerLevel,
  ReportMeasurement,
  RevenueType,
} from "common/constants";
import i18n from "common/i18n";
import { useReportSelector } from "common/store";
import DataGrid from "components/Table/DataGrid";
import { ApiErrorModel } from "models/apiErrorModel";
import {
  Collection,
  DEFAULT_PAYLOAD,
  FilterCollectionPayload,
  Paging,
} from "models/collection";
import {
  RevenueByChangeCategoryChartModel,
  RevenueChangeDrilldownResponse,
} from "models/report";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getRevenueChangeDrilldown } from "services/reportService";
import { formatCurrencyValue, formatPeriodText } from "utils/format";
import useFetch from "utils/hook/useFetch";

interface RevenueChangeDrillDownTableProps {
  period: string;
  monthOfPeriod: string;
  category: keyof RevenueByChangeCategoryChartModel;
  fileId: string;
  startMonth: string;
  endMonth: string;
  customerLevel: CustomerLevel;
  onNextPeriod: () => void;
  onPrevPeriod: () => void;
  onDone: () => void;
}

const analysisTypeMapping = {
  [AnalysisType.MoM]: "MOM",
  [AnalysisType.QoQ]: "QOQ",
  [AnalysisType.YoY]: "YOY",
};

const measurementMapping = {
  [ReportMeasurement.ARR]: "ARR",
  [ReportMeasurement.MRR]: "ARR",
  [ReportMeasurement.CARR]: "CARR",
  [ReportMeasurement.CMRR]: "CARR",
};

function getBeginningAmountKey(
  analysisType: AnalysisType,
  measurement: ReportMeasurement
) {
  return `${analysisTypeMapping[analysisType]}${measurementMapping[measurement]}AMOUNT` as keyof RevenueChangeDrilldownResponse;
}

function getEndingAmountKey(measurement: ReportMeasurement) {
  return `${measurementMapping[measurement]}_AMOUNT` as keyof RevenueChangeDrilldownResponse;
}

function getRevenueValue(
  value: number | "",
  measurement: ReportMeasurement,
  category: keyof RevenueByChangeCategoryChartModel
) {
  if (category !== "Cust Segment Migration" && !value) {
    return 0;
  }
  if (
    value &&
    (measurement === ReportMeasurement.MRR ||
      measurement === ReportMeasurement.CMRR)
  ) {
    return value / 12;
  }
  return value;
}

const getColumns = (
  category: keyof RevenueByChangeCategoryChartModel,
  analysisType: AnalysisType,
  measurement: ReportMeasurement
): GridColDef<RevenueChangeDrilldownResponse>[] => [
  {
    field: "CustomerName",
    valueGetter: (_, row) => row.Name,
    headerName: i18n.t("Dashboard.DrillDownTable.CustomerColumn"),
    flex: 1,
    sortable: false,
  },
  {
    field: "Category",
    valueGetter: () => category,
    headerName: i18n.t("Dashboard.DrillDownTable.CategoryColumn"),
    flex: 1,
    sortable: false,
  },
  {
    field: "BeginningAmount",
    valueGetter: (_, row) => {
      const value = row[
        getBeginningAmountKey(analysisType, measurement)
      ] as number;

      return formatCurrencyValue(getRevenueValue(value, measurement, category));
    },
    headerName: i18n.t("Dashboard.DrillDownTable.BeginningAmountColumn"),
    flex: 1,
    sortable: false,
  },
  {
    field: "EndingAmount",
    valueGetter: (_, row) => {
      const value = row[getEndingAmountKey(measurement)] as number;

      return formatCurrencyValue(getRevenueValue(value, measurement, category));
    },
    headerName: i18n.t("Dashboard.DrillDownTable.EndingAmountColumn"),
    flex: 1,
    sortable: false,
  },
  {
    field: "RevenueChange",
    headerName: i18n.t("Dashboard.DrillDownTable.RevenueChangeColumn"),
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => {
      let percentage = (row.Diff / row["Total Diff"]) * 100;

      if (Number.isNaN(percentage)) {
        percentage = 0;
      }
      return `(${Number(percentage.toFixed(2))}%) ${formatCurrencyValue(
        getRevenueValue(row.Diff, measurement, category) || 0
      )}`;
    },
  },
];

export default function RevenueChangeDrillDownTable({
  period,
  monthOfPeriod,
  category,
  fileId,
  startMonth,
  endMonth,
  customerLevel,
  onNextPeriod,
  onPrevPeriod,
  onDone,
}: RevenueChangeDrillDownTableProps) {
  const { t } = useTranslation();

  const { reportData, reportSettings } = useReportSelector();

  const [dataPayload, setDataPayload] = useState<FilterCollectionPayload>({
    ...DEFAULT_PAYLOAD,
  });
  const titleRef = useRef<HTMLDivElement>(null);

  const { data, isLoading, error, execute } = useFetch<
    {
      pagingFilter: Paging;
    },
    Collection<RevenueChangeDrilldownResponse>,
    ApiErrorModel
  >(
    {
      fn: (params) =>
        getRevenueChangeDrilldown(
          reportData.file!.id,
          monthOfPeriod,
          category,
          params.pagingFilter,
          reportSettings
        ),
      payload: { pagingFilter: DEFAULT_PAYLOAD },
    },
    [monthOfPeriod, category, fileId]
  );

  const handleOnRequestData = useCallback(
    (payload: FilterCollectionPayload) => {
      execute({ pagingFilter: payload });
      titleRef.current && titleRef.current.scrollIntoView();
    },
    [execute, titleRef.current]
  );

  const shouldDisablePrevBtn = useMemo(() => {
    return monthOfPeriod === startMonth;
  }, [startMonth, monthOfPeriod]);

  const shouldDisableNextBtn = useMemo(() => {
    return monthOfPeriod === endMonth;
  }, [endMonth, monthOfPeriod]);

  const periodTextKey = useMemo(() => {
    if (reportSettings.params.revenueType === RevenueType.Yearly) {
      return "Common.Year";
    }
    if (reportSettings.params.revenueType === RevenueType.Quarterly) {
      return "Common.Quarter";
    }
    return "Common.Month";
  }, [reportSettings.params.revenueType]);

  useEffect(() => {
    setDataPayload({ ...DEFAULT_PAYLOAD });
  }, [category, monthOfPeriod]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        ref={titleRef}
      >
        <Typography variant="h5" color="primary" sx={{ flex: 1 }}>
          {t(
            customerLevel === CustomerLevel.CustomerProduct
              ? "Dashboard.DrillDownTable.CustomerProductLevelTitle"
              : "Dashboard.DrillDownTable.CustomerLevelTitle",
            {
              category,
              period: formatPeriodText(period, "MMM. YYYY"),
            }
          )}
        </Typography>
        <Button
          variant="text"
          color="primary"
          sx={{ padding: 0.5, minWidth: 0 }}
          onClick={onDone}
        >
          {t("Common.Done")}
        </Button>
      </Box>
      {!isLoading && error ? (
        <Box
          data-testid="RevenueChangeDrillDownTable__error"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingX: 4,
            paddingY: 2,
          }}
        >
          {t("Common.Error")}
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              startIcon={<ChevronLeft />}
              sx={{ padding: 0.5 }}
              onClick={onPrevPeriod}
              disabled={shouldDisablePrevBtn}
            >
              {`${t("Common.Previous")} ${t(periodTextKey)}`}
            </Button>
            <Button
              endIcon={<ChevronRight />}
              sx={{ padding: 0.5 }}
              onClick={onNextPeriod}
              disabled={shouldDisableNextBtn}
            >
              {`${t("Common.Next")} ${t(periodTextKey)}`}
            </Button>
          </Box>
          <DataGrid
            columns={getColumns(
              category,
              reportSettings.params.analysisType,
              reportSettings.measurement
            )}
            data={isLoading ? { ...data, data: [] } : data}
            isLoading={isLoading}
            payload={dataPayload}
            hasSearchBox={false}
            hasFilterButton={false}
            rowSelection={false}
            onRequestData={handleOnRequestData}
            getRowId={(row) => row.CUSTOMERKEY}
            getRowHeight={() => "auto"}
            columnHeaderHeight={36}
            paginationModel={dataPayload}
            onPaginationModelChange={(model) => {
              setDataPayload(model);
              handleOnRequestData(model);
            }}
            FooterComponent={() => {
              if (isLoading || !data || !data.data || data.data.length === 0) {
                return null;
              }
              const row = data.data[0];

              return (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      paddingY: "6px",
                      borderTop: "1px solid var(--DataGrid-rowBorderColor)",
                      borderBottom: "1px solid var(--DataGrid-rowBorderColor)",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        flex: 2,
                        paddingLeft: "10px",
                        paddingRight: "30px",
                        fontWeight: "bold",
                      }}
                    >
                      {t("Common.Total")}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ flex: 1, paddingX: "10px", fontWeight: "bold" }}
                    >
                      {formatCurrencyValue(
                        getRevenueValue(
                          row["Beginning Balance SUM"],
                          reportSettings.measurement,
                          category
                        )
                      )}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ flex: 1, paddingX: "10px", fontWeight: "bold" }}
                    >
                      {formatCurrencyValue(
                        getRevenueValue(
                          row["Ending Balance SUM"],
                          reportSettings.measurement,
                          category
                        )
                      )}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ flex: 1, paddingX: "10px", fontWeight: "bold" }}
                    >
                      {`(100%) ${formatCurrencyValue(
                        getRevenueValue(
                          row["Total Diff"],
                          reportSettings.measurement,
                          category
                        )
                      )}`}
                    </Typography>
                  </Box>
                  <GridPagination />
                </Box>
              );
            }}
          />
        </>
      )}
    </Box>
  );
}
